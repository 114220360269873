
import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment';

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';

import PacientesService from "../../services/PacientesService";
import ColegaService from "../../services/ColegasService";
import CanalizarService from "../../services/CanalizarService";

import FormularioCanalizarPaciente from "../Canalizaciones/FormularioCanalizarPaciente";

import "../../styles/Canalizaciones.css";

import img_send from "../../img/read_more.svg";
import img_sendArchive from "../../img/send_and_archive.svg";
import img_search from "../../img/search_pax.svg";



const Canalizaciones = () => {

    console.log("---- Canalizaciones -----")

    const [pacientes, setPacientes] = useState([]);
    const [pacActivos, setPacientesActivos] = useState([]);
    const [colegas, setColegas] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [selectValue, setSelectedValue] = useState("");
    const [canalizados, setCanalizados] = useState([]);
    const [idPacienteF, setIdPacienteF] = useState("");
    const [idColegaF, setIdColegaF] = useState("");
    const [image, setImage] = useState(null);
    const [pacRel, setPacienteRelacionColega] = useState([]);
    const [colRel, setColegaRelacionPaciente] = useState([]);
    const [moPacCan, setMotivoCanalizacionPaciente] = useState([]);
    const [vistaCanalizados, setVistaCanalizados] = useState([]);
    const [colegaAtiende, setColegaAtiende] = useState([]);
    const [fotografia, setFotografia] = useState('');
    const [fotoPac, setFotoPac] = useState('');
    const [fotoCol, setFotoCol] = useState('');
    const [fotoPsi, setFotoPsi] = useState('');
    const [comentarioColega, setComentarioColega] = useState('');


    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);
    const routeParams = useParams();

    function refreshPage() {
        window.location.reload(false);
    };

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    });

    function mostrarError(mensaje) {
        Swal.fire({
            title: '¡Error!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    function mostrarNotificacion(mensaje) {
        Swal.fire({
            title: '¡Notificación!',
            text: mensaje,
            icon: 'info',
            confirmButtonText: 'Aceptar'
        });
    }

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }

    const navigate = useNavigate();

    const getData = async () => {
        const data = await PacientesService.getPacientes(usuario.id_psicologo);
        setPacientes(data);

        const data2 = await PacientesService.getPacientesActivos(usuario.id_psicologo);
        setPacientesActivos(data2);

        const data3 = await ColegaService.getColegas(usuario.id_psicologo);
        setColegas(data3);

        // Pacientes canalizados
        const data4 = await CanalizarService.getPacientesCanalizados(usuario.id_psicologo);
        setCanalizados(data4);

        // Colegas atienden paciente
        const data5 = await CanalizarService.getColegasAtiendenPaciente(usuario.id_psicologo);
        setColegaAtiende(data5);
    };


    useEffect(() => {
        getData();
    }, []);



    const canalizarPaciente = async () => {

        if (idPacienteF === "Selecciona una opción" || idPacienteF === undefined || idPacienteF === "") {
            mostrarError("Primero debes seleccionar un paciente.");
        } else if (idColegaF === "Selecciona una opción" || idColegaF === undefined || idColegaF === "") {
            mostrarError("Debes seleccionar un colega.");
        } else {
            const paciente = await PacientesService.getInfoPaciente(idPacienteF);
            const colega = await ColegaService.getInfoColega(idColegaF);
            const fecha_i = moment().format("YYYY-MM-DD");
            const formularioHtml = ReactDOMServer.renderToString(
                <FormularioCanalizarPaciente paciente={paciente} colega={colega} fecha={fecha_i} />
            );
            Swal.fire({
                title: "Motivo de canalización",
                html: formularioHtml,
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText: "Guardar",
                cancelButtonText: "Cancelar",
                preConfirm: () => {
                    const pac = document.getElementById("select-paciente").value;
                    const paciente = parseInt(pac);
                    const col = document.getElementById("select-colega").value;
                    const colega = parseInt(col);
                    const motivo = document.getElementById("input-motivo").value;
                    const comentario_psi = document.getElementById("input-comentario").value;
                    const fecha_i = document.getElementById("input-fecha").value;
                    const psicologo = usuario.id_psicologo;
                    const estatus = "A";
                    return { motivo, comentario_psi, fecha_i, estatus, paciente, psicologo, colega };
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    const datos = result.value;

                    putCanalizarPaciente(datos);
                } else if (result.dismiss === 'cancel') {
                    navigate('/canalizaciones');       // entorno web
                }
            });
        }
    }

    const putCanalizarPaciente = async (datos) => {

        // validar que el pac no tenga canalizaciones activas
        const verif = await CanalizarService.getCanalizar(datos.paciente);
        let cont=0;

        for(let i=0; i < verif.length; i++) {
            if(verif[i].estatus === "A") {
                cont++;
            }
        }

        // console.log("contador: ", cont)

        if (cont > 0) {

            mostrarError("El paciente tiene una canalización activa.");

        } else {

            let data = {
                motivo: datos.motivo,
                comentario_psi: datos.comentario_psi,
                comentario_colega: "",
                fecha_i: datos.fecha_i,
                fecha_f: "",
                estatus: datos.estatus,
                fk_paciente: datos.paciente,
                fk_psicologo: datos.psicologo,
                fk_colega: datos.colega
            }
    
            // console.log("obj dta")
            // console.log(data)
    
            if (data.motivo === "" || data.motivo === undefined) {
    
                mostrarError("Completa el campo: Motivo");
    
            } else {
                
                try {
    
                    await CanalizarService.createCanalizaciones(data);
    
                    Toast.fire("¡El paciente se canalizó correctamente!", "", "success").then((result) => {
                        if (result.isConfirmed) {
                            refreshPage();
                        }
                    }).then(function () {
                        refreshPage();
                    });
    
                } catch (err) {
                    mostrarError("Ocuarrió un error al canalizar al paciente.");
                    console.log(`Error canalización ${err}`);
                }
            }

        }
    }

    const handleIdPaciente = (event) => {
        setIdPacienteF(event.target.value);
    }

    const handleIdColega = (event) => {
        setIdColegaF(event.target.value);
    }


    const finCanalizarPaciente = async () => {

        if (moPacCan.fecha_f === "" || moPacCan.fecha_f === undefined) {

            const fecha_f = moment().format("YYYY-MM-DD");

            const formularioHtml = ReactDOMServer.renderToString(
                <div className="div_table_finCanalizacion">
                    <table className="table_finCanalizacion">
                        <tbody>
                            <tr id="tr_finCan">
                                <td id="tdIzq_finCan">Paciente: </td>
                                <td id="tdDer_finCan">{moPacCan.p_nombre} {moPacCan.p_ap1} {moPacCan.p_ap2}</td>
                            </tr>
                            <tr id="tr_finCan">
                                <td id="tdIzq_finCan"> Colega: </td>
                                <td id="tdDer_finCan">{moPacCan.titulo} {moPacCan.co_nombre} {moPacCan.co_ap1} {moPacCan.co_ap2}</td>
                            </tr>
                            <tr id="tr_finCan">
                                <td id="tdIzq_finCan">Inicio: </td>
                                <td id="tdDer_finCan">
                                    <input type="date" id="input-fecha_i" value={moment(moPacCan.fecha_i).format("YYYY-MM-DD")} disabled />
                                </td>
                            </tr>
                            <tr id="tr_finCan">
                                <td id="tdIzq_finCan">Finalización: </td>
                                <td id="tdDer_finCan">
                                    <input type="date" id="input-fecha_f" value={fecha_f} disabled required />
                                </td>
                            </tr>
                            <tr id="tr_finCan">
                                <td id="tdIzq_finCan">Comentarios: </td>
                                <td id="tdDer_finCan">
                                    <textarea className="textarea_canalizar" id="comentario_finCan" rows="4" cols="50"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );

            Swal.fire({
                title: "Finalizar canalización",
                html: formularioHtml,
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
                preConfirm: () => {
                    const comentario_col = document.getElementById("comentario_finCan").value;
                    const fecha_f = document.getElementById("input-fecha_f").value;
                    const estatus = "I";
                    const idCanalizar = parseInt(moPacCan.id_canalizar);
                    return { comentario_col, fecha_f, estatus, idCanalizar };
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    const datos = result.value;
                    putFinalizarCanalizacion(datos);
                    refreshPage();
                } else if (result.dismiss === 'cancel') {
                    refreshPage();
                }
            });
        } else {
            mostrarNotificacion("El paciente ya ha finalizado su canalización.");
        }
    }


    const putFinalizarCanalizacion = async (datos) => {

        let comentario = "";

        if (datos.comentario_col === null) {

            let data = {
                comentario_colega: comentario,
                fecha_f: datos.fecha_f,
                estatus: datos.estatus,
                id_canalizar: datos.idCanalizar
            }

            await CanalizarService.finalizarCanalizacionPaciente(data);
            Toast.fire("¡La canalización del paciente ha finalizado!", "", "success").then((result) => {
                if (result.isConfirmed) {
                    //navigate('/canalizaciones');    // local
                    navigate('/canalizaciones');   // web
                }
            }).then(function () {
                //navigate('/canalizaciones');    //local
                navigate('/canalizaciones');   //web
            });

        } if (datos.comentario_col !== null) {

            let data = {
                comentario_colega: datos.comentario_col,
                fecha_f: datos.fecha_f,
                estatus: datos.estatus,
                id_canalizar: datos.idCanalizar
            }

            await CanalizarService.finalizarCanalizacionPaciente(data);
            Toast.fire("¡La canalización del paciente ha finalizado!", "", "success").then((result) => {
                if (result.isConfirmed) {
                    //navigate('/canalizaciones');    // local
                    navigate('/canalizaciones');   // web
                }
            }).then(function () {
                //navigate('/canalizaciones');    //local
                navigate('/canalizaciones');   //web
            });
        }
    }



    // Display1 PACIENTE canalizado
    const DisplayData = canalizados.map((info) => {
        return (
            <div>
                <label>
                    <input type="radio" name="statusPac" id="radio_check_PxCan" onClick={() => ColegasRelacionPaciente(info.id_paciente, usuario.id_psicologo)} />
                    <span>
                        {info.p_nombre} {info.p_ap1} {info.p_ap2}
                    </span>
                </label>
            </div>
        )
    });

    // Colegas relacionados con UN paciente canalizado
    const ColegasRelacionPaciente = async (idPaciente, idPsicologo) => {
        const data = await CanalizarService.getColegasRelacionPaciente(idPaciente, idPsicologo);
        setColegaRelacionPaciente(data);    //coRel
    }


    // colega relacionado con el paciente canalizado
    const DisplayData2 = colRel.map((info) => {
        return (
            <div>
                <table id="table_displadata2_can">
                    <tbody>
                        <tr id="filas_diaplaydata2">
                            <td>
                                <label> {info.co_nombre} {info.co_ap1} {info.co_ap2} </label>
                            </td>
                            <td>
                                <label>
                                    <input type="radio" name="statusColPac1" id="radio_check_FechaPxCan" onClick={() => MotivoCanalizacionPaciente(info.id_canalizar)} />
                                    <span>
                                        {moment(info.fecha_i).format("DD-MM-YYYY")}
                                    </span>
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    });


    // Información de paciente canalizado por Id canalizar
    const MotivoCanalizacionPaciente = async (idCanalizar) => {

        const data = await CanalizarService.getMotivoCanalizacionPaciente(idCanalizar);
        setMotivoCanalizacionPaciente(data[0]);

        setFotoCol(data[0].co_fotografia.data);
        setFotoPac(data[0].p_fotografia.data);
        setFotoPsi(data[0].psi_fotografia.data);
    }


    /*************** DISPLAY COLEGAS **********************/

    // Display1 PACIENTE canalizado
    const DisplayColega = colegaAtiende.map((info) => {
        return (
            <div>
                <label>
                    <input type="radio" name="statusCol2" id="radio_check_PacCan" onClick={() => PacientesRelacionadosColega(info.id_colega, usuario.id_psicologo)} />
                    <span>
                        {info.co_nombre} {info.co_ap1} {info.co_ap2}
                    </span>
                </label>
            </div>
        )
    });


    // Pacientes canalizados relacionados con un Colega
    const PacientesRelacionadosColega = async (idColega, idPsicologo) => {
        const data = await CanalizarService.getPacientesRelacionadosColega(idColega, idPsicologo);
        setPacienteRelacionColega(data);
    }


    // Paciente canalizado relacionado con Colega 
    const DisplayColega2 = pacRel.map((info) => {
        return (
            <div>
                <table id="table_displadata2_can">
                    <tbody>
                        <tr id="filas_diaplaydata2">
                            <td>
                                <label> {info.p_nombre} {info.p_ap1} {info.p_ap2} </label>
                            </td>
                            <td>
                                <label>
                                    <input type="radio" name="statusColPac1" id="radio_check_FechaPxCan" onClick={() => MotivoCanalizacionPaciente(info.id_canalizar)} />
                                    <span>
                                        {moment(info.fecha_i).format("DD-MM-YYYY")}
                                    </span>
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    });



    /*************************** */


    const buscar = async (event) => {
        setInputValue(event.target.value);
        const data = await CanalizarService.getPacientesCanBusqueda(event.target.value, usuario.id_psicologo);
        setCanalizados(data);
    };


    const limpiarFiltro = async () => {
        setInputValue("");
        setSelectedValue("");
        const data = await PacientesService.getPacientes(usuario.id_psicologo);
        setPacientes(data);
    };


    const handleCanPacColega = (event) => {
        setVistaCanalizados(event.target.value);
    }


    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }
        return tipoUser;
    }


    return (
        <Layout>
            <Content>
                <div className="calizar_div_1">
                    <div className="calizar_div_2">
                        <div className="calizar_sesion">
                            <label id="lbl-enSesion">{tipoUsuario()} &nbsp;</label>
                            <label>{usuario.nombre}</label>
                        </div>
                        <div className="div_canalizar_label">
                            <label>Canalizaciones</label>
                        </div>
                    </div>

                    <div className="div-canalizar-admin">
                        <div className="div_titulo_canalizar">
                            <label id="lbl_px_canalizar">Canalizar un paciente</label>
                        </div>

                        <div className="div_select_canalizar">
                            <div className="div-buscar-px-colega">
                                <div id="div-buscar-px-canalizar">
                                    <label className="lbl_px_select">Paciente:</label>
                                    <select className="select_canalizar_px" id="select-paciente" defaultValue={""} onChange={handleIdPaciente}>
                                        <option value="" disabled >Selecciona una opción</option>
                                        {pacActivos.map((paciente) => (
                                            <option value={paciente.id_paciente}>
                                                {paciente.nombre} {paciente.ap1} {paciente.ap2}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div id="div-buscar-px-canalizar">
                                    <label className="lbl_colega_select">Colega:</label>
                                    <select className="select_canalizar_colega" id="select-colega" defaultValue={""} onChange={handleIdColega}>
                                        <option value="" disabled >Selecciona una opción</option>
                                        {colegas.map((colega) => (
                                            <option value={colega.id_colega}>
                                                {colega.nombre} {colega.ap1} {colega.ap2}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div id="div-canalizar">
                                    <button id="btn-canalizar" onClick={canalizarPaciente}>
                                        <img src={img_send} alt="img_canalizar"></img>
                                        Canalizar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="div_titulo_px_canalizados">
                        <label id="lbl_px_canalizar"> Consultar canalizaciones por: </label>
                    </div>

                    <div className="div-btn-pacCanalizados">
                        <div className="div_pax_canalizados">
                            <label>Pacientes canalizados</label>
                            <input type="radio" id="paciente_can" name="canalizar" value="pac" onChange={handleCanPacColega}></input>
                        </div>
                        <div className="div_colegas_atienden">
                            <label>Colegas que atienden a pacientes</label>
                            <input type="radio" id="colega_can" name="canalizar" value="col" onChange={handleCanPacColega}></input>
                        </div>
                    </div>

                    {
                        vistaCanalizados === "pac" ? (
                            <div className="calizar_div_3a">
                                <div className="input_buscar_canal">
                                    <input type="search" placeholder="Buscar paciente..." id="Buscador" value={inputValue} onChange={buscar} />
                                    <img src={img_search} alt="buscar" id="img_lupa"></img>
                                </div>
                                <div className="div_px_lista_canalizar_a">
                                    <div className="px_tabla_encabezados">
                                        <div className="div-table_encabezado_canalizar">
                                            <table id="table_encabezado_canalizar">
                                                <thead>
                                                    <tr className="tr-encabezado-canalizar">
                                                        <th>Paciente</th>
                                                        <th>Canalizado con</th>
                                                        <th>Fecha</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        {
                                            DisplayData.length !== 0 ? (
                                                <div className="div-filas_canalizar">
                                                    <table id="table_filas_canalizar">
                                                        <tbody>
                                                            <tr className="filas_canalizar">
                                                                <td>{DisplayData}</td>
                                                                <td>{DisplayData2}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            ) : (
                                                <div className="div-NoPax-can">
                                                    <label>No existen pacientes canalizados.</label>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="px_tabla_canalizar">
                                        {
                                            moPacCan.length === 0 ? (
                                                <div className="div-tabla-canalizar-sinDatos">
                                                    <div className="div_titulo_motivo_canalizar">
                                                        <label id="lbl_px_canalizar">Motivo de canalización</label>
                                                    </div>
                                                    <div className="div-label-sinDatos">
                                                        <label id="label-sinDatos"> Selecciona psicólogo y fecha relacionados con el paciente </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="tablasSelec_canalizar">
                                                    <div className="div_motivo_canalizar">
                                                        <div id="div_titulo_can">
                                                            <label id="lbl_px_canalizar">Motivo de canalización</label>
                                                        </div>
                                                        <div id="div_btn_finalizar_can">
                                                            <button id="btn-canalizar" onClick={finCanalizarPaciente}>
                                                                <img src={img_sendArchive} alt="img_canalizar"></img>
                                                                Finalizar
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="div_datosPxCol_canalizar">
                                                        <div id="div_datosCol_canalizar">
                                                            <div id="div_fotoPx_canalizar">
                                                                {
                                                                    fotoCol.length !== 0 ? (
                                                                        <img src={moPacCan.co_fotografia.data} alt="avatar" />
                                                                    ) : (<div></div>)
                                                                }
                                                            </div>
                                                            <div id="div_nomCol_canalizar">
                                                                <label id="lbl-psicologo-canalizar"> {moPacCan.titulo}: &nbsp;</label>
                                                                <label id="lbl-psicologo2-canalizar"> {moPacCan.co_nombre} {moPacCan.co_ap1} {moPacCan.co_ap2}</label>
                                                            </div>
                                                        </div>
                                                        <div id="div_datosPx_canalizar">
                                                            <div id="div_fotoPx_canalizar">
                                                                {
                                                                    fotoPac.length !== 0 ? (
                                                                        <img src={moPacCan.p_fotografia.data} alt="avatar" />
                                                                    ) : (<div></div>)
                                                                }
                                                            </div>
                                                            <div id="div_nomPx_canalizar">
                                                                <label id="lbl-pacCanalizar">Paciente:</label>
                                                                <label id="lbl-pacCanalizar2">{moPacCan.p_nombre} {moPacCan.p_ap1} {moPacCan.p_ap2}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="div-motivo-canalizar">
                                                        <table className="table_motivo_seleccionado">
                                                            <tr className="padding-row">
                                                                <td className="celda-motivo-canalizarleft">
                                                                    <label className="label-fecha-canalizar" htmlFor="lbl-fecha-canalizar">Se canalizó el día:</label>
                                                                </td>
                                                                <td className="celda-motivo-canalizar-right">
                                                                    <label id="lbl-fecha-canalizar"> {moment(moPacCan.fecha_i).format("DD-MM-YYYY")} </label>
                                                                </td>
                                                            </tr>
                                                            <tr className="padding-row">
                                                                <td className="celda-motivo-canalizar-left">
                                                                    <label className="label-motivo-canalizar" htmlFor="lbl-motivo-canalizar">Motivo:</label>
                                                                </td>
                                                                <td className="celda-motivo-canalizar-right">
                                                                    <label id="lbl-motivo-canalizar"> {moPacCan.motivo} </label>
                                                                </td>
                                                            </tr>
                                                            <tr className="padding-row">
                                                                <td className="celda-motivo-canalizar-left">
                                                                    <label className="label-comentario-canalizar" htmlFor="lbl-comentario-canalizar">Comentarios psicólogo:</label>
                                                                </td>
                                                                <td className="celda-motivo-canalizar-right">
                                                                    <label id="lbl-comentario-canalizar"> {moPacCan.comentario_psi} </label>
                                                                </td>
                                                            </tr>
                                                            <tr className="padding-row">
                                                                <td className="celda-motivo-canalizarleft">
                                                                    <label className="label-fecha-canalizar" htmlFor="lbl-fecha-canalizar">Finalización:</label>
                                                                </td>
                                                                <td className="celda-motivo-canalizar-right">
                                                                    {
                                                                        moPacCan.fecha_f === "" ? (
                                                                            moPacCan.estatus === "A" ? (
                                                                                <label id="lbl-fecha-canalizar"> En proceso </label>
                                                                            ) : (<></>)
                                                                        ) : (
                                                                            <label id="lbl-fecha-canalizar"> {moment(moPacCan.fecha_f).format("DD-MM-YYYY")} </label>
                                                                        )
                                                                    }

                                                                </td>
                                                            </tr>
                                                            <tr className="padding-row">
                                                                <td className="celda-motivo-canalizar-left">
                                                                    <label className="label-comentario-canalizar" htmlFor="lbl-comentario-canalizar">Comentarios colega:</label>
                                                                </td>
                                                                <td className="celda-motivo-canalizar-right">
                                                                    <label id="lbl-comentario-canalizar"> {moPacCan.comentario_colega} </label>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            vistaCanalizados === "col" ? (
                                <div className="calizar_div_3">
                                    <div className="div_px_lista_canalizar">
                                        <div className="div_EncabezadoCol_canalizar">
                                            <table id="table_encabezado_canalizar">
                                                <thead>
                                                    <tr className="tr-encabezado-canalizar">
                                                        <th>Colegas</th>
                                                        <th>Atiende al paciente:</th>
                                                        <th>Fecha</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div className="div_FilasCol2_canalizar">
                                            <table id="table_filas_canalizar">
                                                <tbody>
                                                    <tr className="filas_canalizar">
                                                        <td>{DisplayColega}</td>
                                                        <td>{DisplayColega2}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="px_tabla_canalizar">
                                        {
                                            moPacCan.length === 0 ? (
                                                <div className="div-tabla-canalizar-sinDatos">
                                                    <div className="div_titulo_motivo_canalizar">
                                                        <label id="lbl_px_canalizar">Motivo de canalización</label>
                                                    </div>
                                                    <div className="div-label-sinDatos">
                                                        <label id="label-sinDatos"> Selecciona psicólogo y fecha relacionados con el paciente </label>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="tablasSelec_canalizar">
                                                    <div id="div_datosCol_canalizar">
                                                        <div className="div_titulo_motivoCan_colega">
                                                            <label id="lbl_px_canalizar">Motivo de canalización</label>
                                                        </div>
                                                        <div className="div_btn_finCan_colega">
                                                            <button id="btn-canalizar" onClick={finCanalizarPaciente}>
                                                                <img src={img_sendArchive} alt="img_canalizar"></img>
                                                                Finalizar
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="div_datosPxCol_canalizar">
                                                        <div id="div_datosCol_canalizar">
                                                            <div id="div_fotoPx_canalizar">
                                                                {
                                                                    fotoCol.length !== 0 ? (
                                                                        <img src={moPacCan.co_fotografia.data} alt="avatar" />
                                                                    ) : (<div></div>)
                                                                }
                                                            </div>
                                                            <div id="div_nomCol_canalizar">
                                                                <label id="lbl-psicologo-canalizar"> {moPacCan.titulo}: &nbsp;</label>
                                                                <label id="lbl-psicologo2-canalizar"> {moPacCan.co_nombre} {moPacCan.co_ap1} {moPacCan.co_ap2}</label>
                                                            </div>
                                                        </div>
                                                        <div id="div_datosPx_canalizar">
                                                            <div id="div_fotoPx_canalizar">
                                                                {
                                                                    fotoPsi.length !== 0 ? (
                                                                        <img src={moPacCan.p_fotografia.data} alt="avatar" />
                                                                    ) : (<div></div>)
                                                                }
                                                            </div>
                                                            <div id="div_nomPx_canalizar">
                                                                <label id="lbl-paciente1-canalizar">Paciente: </label>
                                                                <label id="lbl-paciente-canalizar">{moPacCan.p_nombre} {moPacCan.p_ap1} {moPacCan.p_ap2}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="div-motivo-canalizar">
                                                        <table className="table_motivo_seleccionado">
                                                            <tbody>
                                                                <tr className="padding-row">
                                                                    <td className="celda-motivo-canalizarleft">
                                                                        <label className="label-fecha-canalizar" htmlFor="lbl-fecha-canalizar">Se canalizó el día:</label>
                                                                    </td>
                                                                    <td className="celda-motivo-canalizar-right">
                                                                        <label id="lbl-fecha-canalizar"> {moment(moPacCan.fecha_i).format("DD-MM-YYYY")} </label>
                                                                    </td>
                                                                </tr>
                                                                <tr className="padding-row">
                                                                    <td className="celda-motivo-canalizar-left">
                                                                        <label className="label-motivo-canalizar" htmlFor="lbl-motivo-canalizar">Motivo:</label>
                                                                    </td>
                                                                    <td className="celda-motivo-canalizar-right">
                                                                        <label id="lbl-motivo-canalizar"> {moPacCan.motivo} </label>
                                                                    </td>
                                                                </tr>
                                                                <tr className="padding-row">
                                                                    <td className="celda-motivo-canalizar-left">
                                                                        <label className="label-comentario-canalizar" htmlFor="lbl-comentario-canalizar">Comentario psicólogo:</label>
                                                                    </td>
                                                                    <td className="celda-motivo-canalizar-right">
                                                                        <label id="lbl-comentario-canalizar"> {moPacCan.comentario_psi} </label>
                                                                    </td>
                                                                </tr>
                                                                <tr className="padding-row">
                                                                    <td className="celda-motivo-canalizarleft">
                                                                        <label className="label-fecha-canalizar" htmlFor="lbl-fecha-canalizar">Finalización:</label>
                                                                    </td>
                                                                    <td className="celda-motivo-canalizar-right">
                                                                        {
                                                                            moPacCan.fecha_f === "" ? (
                                                                                moPacCan.estatus === "A" ? (
                                                                                    <label id="lbl-fecha-canalizar"> En proceso </label>
                                                                                ) : (<></>)
                                                                            ) : (
                                                                                <label id="lbl-fecha-canalizar"> {moment(moPacCan.fecha_f).format("DD-MM-YYYY")} </label>
                                                                            )
                                                                        }

                                                                    </td>
                                                                </tr>
                                                                <tr className="padding-row">
                                                                    <td className="celda-motivo-canalizar-left">
                                                                        <label className="label-comentario-canalizar" htmlFor="lbl-comentario-canalizar">Comentario colega:</label>
                                                                    </td>
                                                                    <td className="celda-motivo-canalizar-right">
                                                                        <label id="lbl-comentario-canalizar"> {moPacCan.comentario_colega.replace(/\[\[SALTO\]\]/g, '\n')} </label>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )
                        )
                    }
                </div>
            </Content>
        </Layout >
    );
};

export default Canalizaciones;
