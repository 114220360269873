
import React from "react";

import "../../styles/Canalizaciones.css";
import "../../styles/FormularioEditarCita.css";

import img_avatar from "../../img/avatar.png";



const FormularioCambiarContrasena = ( {informacion} ) => {

  console.log("---- Formulario Cambiar Contraseña Px ----")

  console.log(informacion)
  
  return (
    <div>
      <table className="table-cambiar-contrasena">
        <tr>
          <td id="td-img-contrasena">
            <img src={""} alt="avatar" id="img-agendado" />
          </td>
          <td id="td-psi-contrasena">
            <label className="lbl-px-contrasena" id="select-psicologo">Usuario:  </label>
          </td>
        </tr>
        <tr>
          <td><label htmlFor="input-motivo"> Contraseña actual: </label></td>
          <td id="td-input-contrasena">
            <input type="text" className="input-contrasena" id="input-contrasenaActual" required placeholder="Contraseña actual..."></input>
          </td>
        </tr>
        <tr>
          <td><label htmlFor="input-comentario"> Contraseña nueva: </label></td>
          <td id="td-input-contrasena">
            <input type="text" className="input-contrasena" id="input-contrasenaNueva" required placeholder="Contraseña nueva..."></input>
          </td>
        </tr>
      </table>
    </div>
  );
  
}

export default FormularioCambiarContrasena;
