
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';
import moment from 'moment';

import PacientesService from "../../services/PacientesService";
import SesionesService from "../../services/SesionesService";
import InformacionPacientes from "../../services/InformacionPacientes";

import RedactarSesion from "./RedactarSesion";

import "../../styles/Sesiones.css";
import "../../styles/MenuHamburguesa.css";

import img_search from "../../img/search_pax.svg";
import img_clear from "../../img/clear_all.svg";


const Sesiones = (id) => {

    console.log("---- Sesiones -----")

    const [pacientes, setPacientes] = useState([]);
    const [sesiones, setSesiones] = useState([]);

    const [edoCivil, setEdoCivil] = useState('');

    const [informacion, setInformacion] = useState([]);
    const [selectedValue, setSelectedValue] = useState(""); // Estado para el valor del select: estatus_sesion

    const [inputValue, setInputValue] = useState("");
    const [fotografia, setFotografia] = useState('');
    const [sinFoto, setSinFoto] = useState('');

    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);
    const routeParams = useParams();

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }

    function mostrarError(mensaje) {
        Swal.fire({
            title: '¡Error!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    const navigate = useNavigate();

    const getData = async () => {

        if (routeParams.id !== undefined) {     // el parámetro es del paciente que está en Sesión activa, cuando se llamada desde Modal Cita

            cargarInformacion(routeParams.id);

            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);

        } else {

            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);

        }
    };


    useEffect(() => {
        getData();
    }, []);


    function horaEnRango(hora_inicio, hora_fin) {
        const ahora = new Date();
        const horaActual = ahora.getHours();
        const minutosActuales = ahora.getMinutes();

        const [inicioHoras, inicioMinutos] = hora_inicio.split(":").map(Number);
        const [finHoras, finMinutos] = hora_fin.split(":").map(Number);

        const inicioEnMinutos = inicioHoras * 60 + inicioMinutos;
        const finEnMinutos = finHoras * 60 + finMinutos;
        const actualEnMinutos = horaActual * 60 + minutosActuales;

        return actualEnMinutos >= inicioEnMinutos && actualEnMinutos <= finEnMinutos;
    }

    const cargarInformacion = async (id_paciente) => {

        const data2 = await SesionesService.getSesionesHistorial(id_paciente);  // obtiene lista de sesiones del pax

        if (data2.length === 0) {
            Swal.fire({
                title: "Sesiones del paciente",
                text: "El paciente no tiene sesiones registradas. Para abrir una sesión, se requiere agendar una cita.",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: "Agendar",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(`/calendario`);
                }
            });
        } else {
            for (let i = 0; i < data2.length; i++) {

                data2[i].numeroSesion = (data2.length + 1) - (i + 1);   //.numeroSesion, se puede crear un parámetro temporal cuando no existe en la tabla DB

                const fechaActual = moment();   // Fecha actual
                const fechaSesion = moment(data2[i].fecha, 'YYYY-MM-DD');

                if (fechaActual.isSame(fechaSesion, 'day')) {
                    if (horaEnRango(data2[i].hora_i, data2[i].hora_f) === true) {
                        data2[i].editable = true;   // .editable, parametro creado, para poder redactar la sesión
                    } else {
                        data2[i].editable = false;
                    }
                }
            }
            setSesiones(data2);
            cargarInformacionPx(id_paciente);
        }
    };

    const cargarInformacionPx = async (id_paciente) => {

        const data = await PacientesService.getInfoPaciente(id_paciente);

        setEdoCivil(InformacionPacientes.estadoCivil(data[0].edo_civil));

        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64

        if (cont.length < 1) {
            setSinFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setSinFoto("1");
        }

        setFotografia(data[0].fotografia.data)  // get base64 de DB
        setInformacion(data[0]);
    };

    const DisplayPx = pacientes.map((info, index) => {
        return (
            <tr key={index}>
                <td className="td-border-button">
                    <button onClick={() => {
                        cargarInformacion(info.id_paciente);
                    }} id="liga_px">
                        {info.nombre} {info.ap1} {info.ap2}
                    </button>
                </td>
            </tr>
        )
    });


    const redactarSesion = (id_paciente, id_sesion, numeroSesion) => {

        if (routeParams.id !== undefined) {
            navigate(`/RedactarSesion/${routeParams.id},${id_sesion},${numeroSesion}`);
        } else {
            navigate(`/RedactarSesion/${id_paciente},${id_sesion},${numeroSesion}`);
        }
    }


    const consultarSesion = (id_paciente, id_sesion, numeroSesion) => {

        if (routeParams.id !== undefined) {
            navigate(`/ConsultarSesion/${routeParams.id},${id_sesion},${numeroSesion}`);
        } else {
            navigate(`/ConsultarSesion/${id_paciente},${id_sesion},${numeroSesion}`);
        }
    }



    const handleSesionActiva = async (sesion, index) => {

        const sesionActiva = sesiones[index];   // obtener objeto sesión activa
        sesionActiva.sesion = sesion;   // guradar el txt de la sesión

        if (selectedValue === "Sesión no iniciada") {

            mostrarError("Por favor, selecciona el estatus de la Cita.");

        } else {
            try {

                await SesionesService.guardarSesion(sesionActiva.sesion, selectedValue, sesionActiva.id_sesion);

            } catch (error) {
                mostrarError("Hubo un problema al guardar la sesión.");
                console.log('Error al realizar la inserción:', error.message);
            }
        }

    };


    /*
    const handleGuardarSesion = async (event, id_sesion, estatus_sesion, id_paciente) => {
        console.log("ccccccccc")
        try {
            const respuesta = await SesionesService.guardarSesion(event.target.value, estatus_sesion, id_sesion);
            if (respuesta.status === 200) {
                cargarInformacion(id_paciente)
            } else {
                Toast.fire("Hubo un problema al guardar la sesión", "", "error");
            }
            if (selectedValue !== "") {
                await CitasService.estatusCita(selectedValue, fk_cita_sesion, id_paciente);
            }
            else {
                Toast.fire("Selecciona el estatus de la cita: ", "", "error");
            }
        } catch (error) {
            Toast.fire("Hubo un problema al guardar la sesión", "", "error");
            console.log('Error al realizar la inserción:', error.message);
        }
    };
    */



    // Buscador de pacientes
    const buscar = async (event) => {
        setInputValue(event.target.value);
        const data = await PacientesService.getPacientesBuscador(event.target.value, usuario.id_psicologo);
        setPacientes(data);
    }

    const buscarFiltro = async (event) => {
        if (event.target.value === "") {
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        } else {
            setSelectedValue(event.target.value);
            const data = await PacientesService.getPacientesEstatus(event.target.value, usuario.id_psicologo);
            setPacientes(data);
        }
    };

    const limpiarFiltro = async () => {
        setInputValue("");
        setSelectedValue("");
        const data = await PacientesService.getPacientes(usuario.id_psicologo);
        setPacientes(data);
    };

    const DisplaySesiones = sesiones.map((sesion, index) => {
        return (
            <p>
                <div id="div-datos-sesiones">
                    <div id="div-FechaSelect-sesiones">
                        <div id="div-datos2-sesiones">
                            <h6>Sesión {sesion.numeroSesion}</h6>
                        </div>
                        <div id="div-datos3-sesiones">
                            <b>Horario:</b> &nbsp; {sesion.hora_i} - {sesion.hora_f} &nbsp; &nbsp; ---- &nbsp; &nbsp; <b>Fecha:</b> &nbsp; {moment(sesion.fecha).format('DD-MM-YYYY')}
                        </div>
                    </div>
                    <div key={index}>
                        <p style={{ border: "none" }}>
                            {
                                sesion.editable === true ? (
                                    <div>
                                        <label><h4> Sesión activa </h4></label>
                                        <textarea id="textarea-sesiones-activa"
                                            placeholder="La sesión está activa, comienza a redactar..." 
                                            rows="4" 
                                            cols="50"
                                            value={sesion.sesion.replace(/\[\[SALTO\]\]/g, '\n')} 
                                            onChange={(event) => handleSesionActiva(event.target.value, index)}>
                                        </textarea>
                                        <p style={{ border: "none" }} id="p-button-recom">
                                            <button id="btn-editar-sesion" onClick={() => redactarSesion(sesion.id_paciente, sesion.id_sesion, sesion.numeroSesion)}> Redactar </button>
                                        </p>
                                    </div>
                                ) : (
                                    <div>
                                        <textarea id="textarea-sesiones" rows="4" cols="50" value={sesion.sesion.replace(/\[\[SALTO\]\]/g, '\n')} disabled>
                                        </textarea>
                                        <p style={{ border: "none" }} id="p-button-recom">
                                            <button id="btn-editar-sesion" onClick={() => consultarSesion(sesion.id_paciente, sesion.id_sesion, sesion.numeroSesion)}> Consultar sesión </button>
                                        </p>
                                    </div>
                                )
                            }
                        </p>
                    </div>
                </div>
            </p>
        )
    });

    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }


    return (
        <Layout>
            <Content>
                <div className="div-1-sesiones">
                    <div className="div-2-sesiones">
                        <div className="px_sesion_citas">
                            <label id="lbl-enSesion">{tipoUsuario()} &nbsp;</label>
                            <label>{usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_citas">
                            <label>Historial de sesiones</label>
                        </div>
                    </div>
                    <div className="div_buscador_px_sesiones">
                        <div className="div_filtro_sesiones">
                            <select id="px_select_sesion" value={selectedValue} onChange={buscarFiltro}>
                                <option value="" disabled >Selecciona un filtro</option>
                                <option value="A">Pacientes activos</option>
                                <option value="I">Pacientes inactivos</option>
                            </select>
                            <button id="btn-limpiar-filtro-sesion" onClick={limpiarFiltro}>
                                <img src={img_clear} alt="img_clear" />
                                Limpiar
                            </button>
                        </div>
                        <div className="input_buscar_sesiones">
                            <input type="search" placeholder="Buscar paciente..." id="Buscador" value={inputValue} onChange={buscar} />
                            <img src={img_search} alt="buscar" id="img_lupa"></img>
                        </div>
                    </div>
                    <div className="div_DisplayPx_label">
                        <label>Pacientes</label>
                    </div>
                    <div className="div-3-sesiones">
                        <div className="div3_DisplayPx">
                            <div className="div_DisplayPx_sesion">
                                <table id="table-DisplayPx-sesion">
                                    <tbody>
                                        {DisplayPx}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="div-desplegable-sesion">
                            {informacion.length === 0 ? (
                                <div className="faq-container">
                                    <details open>
                                        <summary> Sesiones </summary>
                                    </details>
                                    <div className="div-selecPx-sesion">
                                        <label id="label-selecPx-sesion"> Selecciona un paciente de la lista... </label>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="div-p-nombre-sesion">
                                        <div className="div-avatar-sesion">
                                            {
                                                sinFoto === "1" ? (
                                                    fotografia === "" ? (
                                                        <div id="img_avatar_sesion">
                                                            <label> Cargando imagen...</label>
                                                        </div>
                                                    ) : (
                                                        <div id="img_avatar_sesion">
                                                            <img src={fotografia} alt="base64"></img>
                                                        </div>
                                                    )
                                                ) : (<div></div>)
                                            }
                                        </div>
                                        <table className="table-head-px-sesion">
                                            <tbody>
                                                <tr>
                                                    <td id="td-paciente-citas" colSpan={4}>
                                                        {/* local 
                                                    <a href={`/pacientes/${informacion.id_paciente}`}>
                                                        <label>{informacion.nombre} {informacion.ap1} {informacion.ap2}</label>
                                                    </a>
                                                    */}

                                                        {/* web */}
                                                        <a href={`#/pacientes/${informacion.id_paciente}`}>
                                                            <label>{informacion.nombre} {informacion.ap1} {informacion.ap2}</label>
                                                        </a>

                                                    </td>
                                                    <td id="td-menuHamb-citas">
                                                        <div id="div-menuHamb">
                                                            <nav className="nav-menuHamb">
                                                                <input type="checkbox" id="in-menuHamb"></input>
                                                                <label id="label-menuHamb" htmlFor="in-menuHamb"> ☰ </label>
                                                                <ul id="ul-menuHamb">
                                                                    {/*     entorno local
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/sesiones/${informacion.id_paciente}`}>Sesiones</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/expedientes/${informacion.id_paciente}`}>Expediente</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/familiares/${informacion.id_paciente}`}>Familiares</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/canalizaciones/${informacion.id_paciente}`}>Canalizaciones</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/notificaciones/${informacion.id_paciente}`}>Notificaciones</a>
                                                                        </li>
                                                                        */}

                                                                    {/*     entorno web     */}
                                                                    <li id="li-menuHamb-admin">
                                                                        <a href={`#/sesiones/${informacion.id_paciente}`}>Sesiones</a>
                                                                    </li>
                                                                    <li id="li-menuHamb-admin">
                                                                        <a href={`#/expedientes/${informacion.id_paciente}`}>Expediente</a>
                                                                    </li>
                                                                    <li id="li-menuHamb-admin">
                                                                        <a href={`#/familiares/${informacion.id_paciente}`}>Familiares</a>
                                                                    </li>
                                                                    <li id="li-menuHamb-admin">
                                                                        <a href={`#/canalizaciones/${informacion.id_paciente}`}>Canalizaciones</a>
                                                                    </li>
                                                                    <li id="li-menuHamb-admin">
                                                                        <a href={`#/notificaciones/${informacion.id_paciente}`}>Notificaciones</a>
                                                                    </li>
                                                                </ul>
                                                            </nav>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr id="encabezado_px_citas">
                                                    <th>Edad</th>
                                                    <th>Trabajo actual</th>
                                                    <th>Edo. civil</th>
                                                    <th>Ciudad</th>
                                                    <th>Tel</th>
                                                </tr>
                                                <tr id="filas_px_citas">
                                                    <td>
                                                        <label id="lbl-edad-citas">{informacion.edad}</label>
                                                    </td>
                                                    <td>
                                                        <label id="lbl-trabajo-citas">{informacion.trabajo_actual}</label>
                                                    </td>
                                                    <td>
                                                        <label id="lbl-edo-civil-citas">{edoCivil}</label>
                                                    </td>
                                                    <td>
                                                        <label id="lbl-ciudad-citas">{informacion.ciudad}</label>
                                                    </td>
                                                    <td>
                                                        <label id="lbl-tel-citas">{informacion.tel}</label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="faq-container">
                                        <details open>
                                            <summary> Sesiones </summary>
                                            <div className="summary-sesiones-parrafo">
                                                {DisplaySesiones}
                                            </div>
                                        </details>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Content>
        </Layout >
    );
};

export default Sesiones;