

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import moment from 'moment';

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import CanalizarService from "../../services/CanalizarService";
import CitasService from "../../services/CitasService";
import ColegasService from "../../services/ColegasService";
import ExpedientesService from "../../services/ExpedientesService";
import FamiliaresService from "../../services/FamiliaresService";
import PacientesService from "../../services/PacientesService";
import PsicologoService from "../../services/PsicologoService";
import SesionesService from "../../services/SesionesService";
import InformacionPacientes from "../../services/InformacionPacientes";

import "../../styles/Reporte.css";
import "../../styles/MenuHamburguesa.css";


const Reporte = (id) => {

    console.log("---- Reporte ----")

    const [famDelPac, setFamDelPaciente] = useState([]);
    const [informacion, setInformacion] = useState([]);
    const [citasPac, setCitasPaciente] = useState([]);
    const [fotografia, setFotografia] = useState('');
    const [sesionesPac, setSesionesPaciente] = useState([]);
    const [expedientePac, setExpedientePaciente] = useState([]);

    const [btnTexto, setBtnTexto] = useState([]);
    const [cajaTexto, setCajaTexto] = useState([]);

    const [pacientes, setPacientes] = useState([]);
    const [sinFoto, setSinFoto] = useState('');
    const [pacActivos, setPacientesActivos] = useState([]);
    const [canalizado, setCanalizado] = useState([]);
    const [colegaAtiende, setColegaAtiende] = useState([]);
    const [famEsPaciente, setFamEsPaciente] = useState([]);
    const [famNoEsPaciente, setFamNoEsPaciente] = useState([]);
    const [esPaciente, setEsPaciente] = useState("");   // evaluar si el Familiar tambien es paciente
    const [fotoFam, setFotoFam] = useState('');

    const [numBoton, setNumBoton] = useState("");
    const [show, setShow] = useState(false);

    const [hoy, setHoy] = useState('');

    const [sexo, setSexo] = useState('');
    const [edoCivil, setEdoCivil] = useState('');
    const [estado, setEstado] = useState('');
    const [pais, setPais] = useState('');

    const [psicologo, setPsicologo] = useState([]);

    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);
    const routeParams = useParams();

    const navigate = useNavigate();


    const MySwal = withReactContent(Swal);

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    });


    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }

    const fechaElaboracion = () => {
        let hoy = new Date().toString();     // fecha actual de la Pc
        let hoy_aux = hoy.split(" ");
        let fechaPDF = hoy_aux[0] + " " + hoy_aux[1] + " " + hoy_aux[2] + " " + hoy_aux[3];
        // console.log("fecha string -------")
        // console.log(hoy_aux[0]+""+hoy_aux[1]+""+hoy_aux[2]+""+hoy_aux[3])
        setHoy(fechaPDF);
    }

    const getData = async () => {

        // si existe el paciente
        if (routeParams.id !== undefined) {

            fechaElaboracion();

            cargarInformacion(routeParams.id);

            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);

            const data2 = await PacientesService.getPacientesActivos(usuario.id_psicologo);
            setPacientesActivos(data2);

            // Pacientes canalizados
            const data4 = await CanalizarService.getPacienteCanalizado(routeParams.id);
            setCanalizado(data4);

            // Colegas que atienden a uno o varios pacientes
            const data5 = await CanalizarService.getColegasAtiendenPaciente(usuario.id_psicologo);
            setColegaAtiende(data5);
            // console.log("setColegaAtiende")
            // console.log(data5)

            // Familiares del paciente: famNoEsPac
            const data6 = await FamiliaresService.getFamiliarNoEsPaciente(routeParams.id);
            setFamNoEsPaciente(data6);

            // Familiares del paciente: famEsPac
            const data3 = await FamiliaresService.getFamiliarEsPaciente(routeParams.id);
            setFamEsPaciente(data3);

            // Citas del paciente
            const data7 = await CitasService.getCitasHistorial(routeParams.id);     // vCitas
            let arrCitas = [];
            let citas;
            for (let i = 0; i < data7.length; i++) {
                citas = {
                    numCita: data7[i].numeroCita = (data7.length + 1) - (i + 1),   //.numeroSesion, se puede crear un parámetro temporal cuando no existe en la tabla DB
                    fecha: moment(data7[i].fecha).format("DD-MM-YYYY"),
                    horario: data7[i].hora_i + " - " + data7[i].hora_f,
                    estatus_cita: data7[i].estatus_cita
                }
                arrCitas.push(citas);   // reducir todos los datos de la vCitas a un arreglo de objs simplificado
            }
            setCitasPaciente(arrCitas);
            // console.log("setCitasPaciente")
            // console.log(arrCitas)

            // Sesiones del paciente
            const data8 = await SesionesService.getSesionesHistorial(routeParams.id);
            let arrSes = [];
            let ses;
            for (let i = 0; i < data8.length; i++) {
                ses = {
                    numSesion: data8[i].numeroSesion = (data8.length + 1) - (i + 1),
                    fecha: data8[i].fecha,
                    horario: data8[i].hora_i + " - " + data8[i].hora_f,
                    estatus_sesion: data8[i].estatus_sesion,
                    sesion: data8[i].sesion
                }
                arrSes.push(ses);   // reducir todos los datos de la vSesion a un arreglo de objs simplificado
            }
            setSesionesPaciente(arrSes);
            // console.log("setSesionesPaciente")
            // console.log(arrSes)

            // Expediente 
            const data9 = await ExpedientesService.getExpediente(routeParams.id);
            setExpedientePaciente(data9[0]);

            const data10 = await PsicologoService.getPsicologoIndividual(usuario.id_psicologo);
            setPsicologo(data10[0]);

            // mostrar texto

            var boton = document.querySelectorAll(".btnVerTexto");
            setBtnTexto(boton);
            var cajaTxt = document.querySelectorAll(".noMostrar");
            setCajaTexto(cajaTxt);


        } else {
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        }
    }


    useEffect(() => {
        getData();
    }, []);


    // Información del paciente
    const cargarInformacion = async (id_paciente) => {

        const data = await PacientesService.getInfoPaciente(id_paciente);

        setSexo(InformacionPacientes.sexoPaciente(data[0].sexo));
        setEdoCivil(InformacionPacientes.estadoCivil(data[0].edo_civil));
        setEstado(InformacionPacientes.estadosRepublica(data[0].edo));
        setPais(InformacionPacientes.paisesPaciente(data[0].pais));

        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64

        if (cont.length < 1) {
            setSinFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setSinFoto("1");
        }

        setFotografia(data[0].fotografia.data)  // get base64 de DB
        setInformacion(data[0]);

    }


    const verTextoSesion = () => {

        console.log("verTextoSesion -------")

        btnTexto.forEach(function (elemento, i) {

            console.log("forEach: ")

            elemento.addEventListener('click', (e) => {

                //console.log(this.className)
                console.log(e.currentTarget === this);

                console.log("I: ", i)
                //cajaTexto[i].classList.toggle("mostrarTexto");

            });

        });

    }


    const verMasTexto = (numSes, pageY) => {

        console.log("btn Ver mas ---")
        console.log("numSes: ", numSes)

        let btn = document.querySelectorAll(".btnVerTexto");     // SelectroAll, crea una Lista de Nodos con todos los btn de la misma clase ( .nombreClase ), presedidia por el punto.
        console.log("Nodos: ")
        console.log(btn)

        //setVerTexto(true);

    }


    const verMenosTexto = (numSes, pageY) => {

        console.log("btn Ver menos ---")
        console.log("numSes: ", numSes)

        let btn = document.querySelectorAll(".btnVerTexto");
        console.log("Nodos: ")
        console.log(btn)

        //setVerTexto(false);
    }


    // cargar datos familiares: El familiar no es paciente
    const cargarInformacionFam = async (idPaciente) => {
        //tabla familiar
        const data = await FamiliaresService.getFamDelPaciente(idPaciente);
        setFamDelPaciente(data);    //famDelPac

        //tabla vparentesco -- El familiar es paciente
        const data2 = await FamiliaresService.getFamiliarEsPaciente(idPaciente);
        setFamEsPaciente(data2);    //famEsPaciente
    }

    // cargar información del familiar
    const cargarInformacionPorFamiliar = async (id_familiar, esPac) => {
        // DisplayData2: El familiar no es paciente
        if (esPac === 'no') {

            const data = await FamiliaresService.getFamiliarIndividual(id_familiar);

            data[0].sexo = InformacionPacientes.sexoPaciente(data[0].sexo)
            data[0].edo_civil = InformacionPacientes.estadoCivil(data[0].edo_civil)
            data[0].edo = InformacionPacientes.estadosRepublica(data[0].edo)
            data[0].pais = InformacionPacientes.paisesPaciente(data[0].pais)

            const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64

            if (cont.length < 1) {
                setSinFoto("0");
            } else if (cont.length > 1 && cont.length < 50) {
                mostrarAdvertencia("Error al obtener fotografía");
            } else if (cont.length > 50) {
                setSinFoto("1");
            }

            setEsPaciente("no");
            setInformacion(data[0]);
            setFotoFam(data[0].fotografia.data)

        } else if (esPac === 'si') {

            // Display3: El familiar sí es paciente
            const data = await FamiliaresService.getFamiliarP(id_familiar);

            data[0].sexo = InformacionPacientes.sexoPaciente(data[0].sexo)
            data[0].edo_civil = InformacionPacientes.estadoCivil(data[0].edo_civil)
            data[0].edo = InformacionPacientes.estadosRepublica(data[0].edo)
            data[0].pais = InformacionPacientes.paisesPaciente(data[0].pais)

            const cont = data[0].pf_fotografia.data;

            if (cont.length < 1) {
                setSinFoto("0");
            } else if (cont.length > 1 && cont.length < 50) {
                mostrarAdvertencia("Error al obtener fotografía");
            } else if (cont.length > 50) {
                setSinFoto("1");
            }

            setEsPaciente("si");
            setInformacion(data[0]);
            setFotoFam(data[0].pf_fotografia.data)

        }
    }

    const handleSalir = (event) => {
        // navigate('/pacientes');  //local
        navigate('/expedientes');  //web
    }



    const handlePDF = async () => {

        MySwal.fire({
            title: 'Generando reporte',
            text: 'Esperar mientras se general el PDF',
            allowOutsideClick: false,
            didOpen: () => {
                MySwal.showLoading();
            }
        });

        // si existen pacientes canalizados
        let can;

        if (canalizado.length === 0) {
            can = {
                colega: "",
                especialidad: "",
                f_inicio_can: "",
                f_fin_can: "",
                motivo: "",
                comentario_psi: "",
                comentario_colega: ""
            }
        } else if (canalizado.length > 0) {
            can = {
                colega: canalizado[0].co_nombre + " " + canalizado[0].co_ap1 + " " + canalizado[0].co_ap2,
                especialidad: canalizado[0].especialidad,
                f_inicio_can: canalizado[0].fecha_i,
                f_fin_can: canalizado[0].fecha_f,
                motivo: canalizado[0].motivo,
                comentario_psi: canalizado[0].comentario_psi,
                comentario_colega: canalizado[0].comentario_colega,
            }
        }

        const data = {
            psicologo: usuario.nombre,
            cedula: psicologo.cedula,
            fechaPdf: hoy,
            paciente: (informacion.nombre + " " + informacion.ap1 + " " + informacion.ap2),
            ingreso: moment(informacion.fecha_ingreso).format("DD-MM-YYYY"),
            edad: informacion.edad,
            sexo: sexo,
            edo_civi: edoCivil,
            domicilio: informacion.domicilio,
            ciudad: informacion.ciudad,
            estado: estado,
            cod_pos: informacion.cod_pos,
            escolaridad: informacion.escolaridad,
            profesion: informacion.profesion,
            trabajo_actual: informacion.trabajo_actual,
            colega: can.colega,
            especialidad: can.especialidad,
            f_inicio_can: can.f_inicio_can,
            f_fin_can: can.f_fin_can,
            motivo: can.motivo,
            comentario_psi: can.comentario_psi,
            comentario_colega: can.comentario_colega,
            prueba: expedientePac.pruebas,
            sugerencias: expedientePac.sugerencias,
            hist_medico: expedientePac.hist_medico,
            exp_significativas: expedientePac.exp_significativa,
            diagnostico: expedientePac.diagnostico,
            citas: citasPac,    // se envía obj citas, citas es un arreglo de todas las citas del pac
        }

        // console.log("Obj data -------")
        // console.log(data)


        try {
            
            //const response = await fetch('http://127.0.0.1:3006/reportePDF', {
                const response = await fetch('/psiserver_py/reportePDF', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });
            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                window.open(url);
                MySwal.close();
            } else {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error al general el PDF',
                });
            }
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al general el PDF',
            });
        }

    }

    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }

    // se requiere para el componente Card
    const bull = (
        <Box
            component="span"
            sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
        >
            •
        </Box>
    )


    const card_datosPersonales = (
        <React.Fragment>
            <CardContent>
                <div id="div_headPac_1_reporte">
                    <div id="div_photo_reporte">
                        {
                            fotografia.length !== 0 ? (
                                <img alt="photo_reporte" src={fotografia}></img>
                            ) : (<div></div>)
                        }
                    </div>
                    <div id="div_headPac_2_reporte">
                        <table id="table_headPac_1_reporte">
                            <thead>
                                <tr id="tr_headPac_reporte">
                                    <th id="th_nombre_reporte">Nombre</th>
                                    <th id="th_edad_reporte">Edad</th>
                                    <th id="th_sexo_reporte">Sexo</th>
                                    <th id="th_edoCivil_reporte">Estado civil</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr id="tr_datosPac_reporte">
                                    <td>
                                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                            {informacion.nombre} {informacion.ap1} {informacion.ap2}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                            {informacion.edad}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                            {sexo}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                            {edoCivil}
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table id="table_headPac_2_reporte">
                            <thead>
                                <tr id="tr_headPac2_reporte">
                                    <th id="th_dom_reporte">Domicilio</th>
                                    <th id="th_ciudad_reporte">Ciudad</th>
                                    <th id="th_cp_reporte">CP</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr id="tr_datosPac2_reporte">
                                    <td>
                                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                            {informacion.domicilio}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                            {informacion.ciudad}, &nbsp; &nbsp; {estado}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                            {informacion.cod_pos}
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table id="table_headPac_3_reporte">
                            <thead>
                                <tr id="tr_headPac3_reporte">
                                    <th id="th_tel_reporte">Teléfono</th>
                                    <th id="th_correo_reporte">Correo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr id="tr_datosPac3_reporte">
                                    <td>
                                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                            {informacion.tel}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                            {informacion.correo}
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </CardContent>
        </React.Fragment>
    );

    const card_ingresoSalidaSistema = (
        <React.Fragment>
            <CardContent>
                <div id="div_headPac_1_reporte">
                    <table id="table_headPac_1_reporte">
                        <thead>
                            <tr id="tr_headPac_reporte">
                                <th id="th_nom_reporte">Ingreso al sistema</th>
                                <th id="th_dom_reporte">Salida del sistema</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="tr_datosPac_reporte">
                                <td>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        {moment(informacion.fecha_ingreso).format("DD-MM-YYYY")}
                                    </Typography>
                                </td>
                                <td>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        {informacion.fecha_inactivo}
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </CardContent>
        </React.Fragment>
    );

    const card_InfoPaciente = (
        <React.Fragment>
            <CardContent>
                <div id="div_headPac_1_reporte">
                    <table id="table_headPac_1_reporte">
                        <thead>
                            <tr id="tr_infoPac_reporte">
                                <th id="th_esc_reporte">Escolaridad</th>
                                <th id="th_prof_reporte">Profesión</th>
                                <th id="th_trabajo_reporte">Trabajo actual</th>
                                <th id="th_canalizar_reporte">Canalización</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="tr_datosPac_reporte">
                                <td>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        {informacion.escolaridad}
                                    </Typography>
                                </td>
                                <td>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        {informacion.profesion}
                                    </Typography>
                                </td>
                                <td>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        {informacion.trabajo_actual}
                                    </Typography>
                                </td>
                                <td>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        {informacion.edo_canalizar}
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </CardContent>
        </React.Fragment>
    );

    const card_fam = (
        <React.Fragment>
            <CardContent>
                <div id="div_headPac_1_reporte">
                    <table id="table_headPac_1_reporte">
                        <thead>
                            <tr id="tr_famPac_reporte">
                                <th id="th_nom_reporte">Nombre</th>
                                <th id="th_edad_reporte">Edad</th>
                                <th id="th_edoCivil_reporte">Estado civil</th>
                                <th id="th_perent_reporte">Parentesco</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                famNoEsPaciente.map((fam, index) => {
                                    return (
                                        <tr key={index} id="tr_datosPac_reporte">
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {fam.f_nom} {fam.f_ap1} {fam.f_ap2}
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {fam.f_edad}
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {InformacionPacientes.estadoCivil(fam.f_edo_civil)}
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {fam.parentesco}
                                                </Typography>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        <tbody>
                            {
                                famEsPaciente.map((fam) => {
                                    return (
                                        <tr id="tr_datosPac_reporte">
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {fam.pf_nom} {fam.pf_ap1} {fam.pf_ap2}
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {fam.pf_edad}
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {InformacionPacientes.estadoCivil(fam.pf_edo_civil)}
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {fam.parentesco}
                                                </Typography>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </CardContent>
        </React.Fragment>
    );

    const card_citas = (
        <React.Fragment>
            <CardContent>
                <div id="div_headPac_1_reporte">
                    <table id="table_headPac_1_reporte">
                        <thead>
                            <tr id="tr_famPac_reporte">
                                <th id="th_noCita_cita">No. cita</th>
                                <th id="th_fecha_cita">Fecha</th>
                                <th id="th_hora_cita">Horario</th>
                                <th id="th_estatus_cita">Estatus</th>
                            </tr>
                        </thead>
                        {
                            citasPac.map((cita, index) => {
                                return (
                                    <tbody key={index}>
                                        <tr id="tr_datosPac_reporte">
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {cita.numCita}
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {cita.fecha}
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {cita.horario}
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                    {cita.estatus_cita}
                                                </Typography>
                                            </td>
                                        </tr>
                                    </tbody>
                                )
                            })
                        }

                    </table>
                </div>
            </CardContent>
        </React.Fragment>
    );

    const card_sesiones = (
        <React.Fragment>
            <CardContent>
                <div id="div_headPac_2_reporte">

                    {
                        sesionesPac.map((sesion, index) => {
                            return (
                                <div key={index}>
                                    <table id="table_headPac_1_reporte">
                                        <thead>
                                            <tr id="tr_sesionPac_reporte">
                                                <th id="th_noSesion_reporte">No. de sesión</th>
                                                <th id="th_fechaSe_reporte">Fecha</th>
                                                <th id="th_horaSe_reporte">Horario</th>
                                                <th id="th_estatusSe_reporte">Estatus</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr id="tr_datosPac_reporte">
                                                <td>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        {sesion.numSesion}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        {moment(sesion.fecha).format("DD-MM-YYYY")}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        {sesion.horario}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        {sesion.estatus_sesion}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="noMostrar">
                                        <div>
                                            <label> Contenido de la sesión </label>
                                        </div>
                                        <div className="div-textSesion-reporte">
                                            <Typography gutterBottom sx={{
                                                color: 'text.secondary',
                                                fontSize: 14,
                                                lineHeight: 2
                                            }}
                                            >
                                                <textarea
                                                    className="txtA_sesion_reporte"
                                                    disabled
                                                    rows="4"
                                                    cols="50"
                                                    value={sesion.sesion.replace(/\[\[SALTO\]\]/g, '\n')}
                                                />

                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="mostrarTexto">
                                        <div>
                                            <label> Contenido de la sesión </label>
                                        </div>
                                        <div>
                                            <Typography gutterBottom sx={{
                                                color: 'text.secondary',
                                                fontSize: 14,
                                                lineHeight: 2
                                            }}
                                            >
                                                <textarea
                                                    className="txtA_sesion_reporte"
                                                    disabled
                                                    rows="4"
                                                    cols="50"
                                                    value={sesion.sesion.replace(/\[\[SALTO\]\]/g, '\n')}
                                                />

                                            </Typography>
                                        </div>
                                    </div>

                                    <div className="div-btn-verMas-reporte">
                                        <button
                                            className="btnVerTexto"
                                            id="btn-verMas-reporte"
                                            onClick={verTextoSesion}
                                        >
                                            Ver más...
                                        </button>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </CardContent>
        </React.Fragment >
    );

    const card_canalizaciones = (
        <React.Fragment>
            <CardContent>
                <div id="div_headPac_1_reporte">
                    <table id="table_headPac_1_reporte">
                        {
                            canalizado.map((canalizar) => {
                                return (
                                    <div>
                                        <thead>
                                            <tr id="tr_canalizacion_can">
                                                <th id="th_Colega_can">Colega</th>
                                                <th id="th_Especialidad_can">Especialidad</th>
                                                <th id="th_fechaIn_can">Fecha inicio</th>
                                                <th id="th_fechaOut_can">Fecha finalización</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr id="tr_datosPac_reporte">
                                                <td>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        {canalizar.co_nombre + " " + canalizar.co_ap1 + " " + canalizar.co_ap2}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        {canalizar.especialidad}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        {canalizar.fecha_i}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        {canalizar.fecha_f}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr id="tr_sesionPac_reporte">
                                                <th id="th_noSesion_reporte" colSpan={2}>Motivo de la canalización:</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr id="tr_sesionPac_reporte">
                                                <td id="td_sesionPac_reporte" colSpan={4}>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        <textarea
                                                            className="txtA_sesion_reporte"
                                                            disabled
                                                            rows="4"
                                                            cols="50"
                                                            value={canalizar.motivo.replace(/\[\[SALTO\]\]/g, '\n')}
                                                        />
                                                    </Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr id="tr_sesionPac_reporte">
                                                <th id="th_noSesion_reporte" colSpan={2}>Comentarios del psicólogo:</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr id="tr_sesionPac_reporte">
                                                <td id="td_sesionPac_reporte" colSpan={4}>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        <textarea
                                                            className="txtA_sesion_reporte"
                                                            disabled
                                                            rows="4"
                                                            cols="50"
                                                            value={canalizar.comentario_psi.replace(/\[\[SALTO\]\]/g, '\n')}
                                                        />
                                                    </Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr id="tr_sesionPac_reporte">
                                                <th id="th_noSesion_reporte" colSpan={2}>Comentarios del colega:</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr id="tr_sesionPac_reporte">
                                                <td id="td_sesionPac_reporte" colSpan={4}>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                                        <textarea
                                                            className="txtA_sesion_reporte"
                                                            disabled
                                                            rows="4"
                                                            cols="50"
                                                            value={canalizar.comentario_colega.replace(/\[\[SALTO\]\]/g, '\n')}
                                                        />
                                                    </Typography>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </div>
                                )
                            })
                        }
                    </table>
                </div>
            </CardContent>
        </React.Fragment>
    );

    const card_expediente = (
        <React.Fragment>
            <CardContent>
                <div id="div_headPac_1_reporte">
                    <table id="table_headPac_1_reporte">
                        <thead>
                            <tr id="tr_sesionPac_reporte">
                                <th id="th_sugerencias_reporte">Pruebas</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="tr_sesionPac_reporte">
                                <td id="td_sesionPac_reporte" colSpan={3}>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        <textarea
                                            className="txtA_sesion_reporte"
                                            disabled
                                            rows="4"
                                            cols="50"
                                            value={expedientePac.pruebas}
                                        />
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                        <thead>
                            <tr id="tr_sesionPac_reporte">
                                <th id="th_sugerencias_reporte">Sugerencias</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="tr_sesionPac_reporte">
                                <td id="td_sesionPac_reporte" colSpan={3}>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        <textarea
                                            className="txtA_sesion_reporte"
                                            disabled
                                            rows="4"
                                            cols="50"
                                            value={expedientePac.sugerencias}
                                        />
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                        <thead>
                            <tr id="tr_sesionPac_reporte">
                                <th id="th_hisMedic_reporte">Historial médico</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="tr_sesionPac_reporte">
                                <td id="td_sesionPac_reporte" colSpan={3}>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        <textarea
                                            className="txtA_sesion_reporte"
                                            disabled
                                            rows="4"
                                            cols="50"
                                            value={expedientePac.hist_medico}
                                        />
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                        <thead>
                            <tr id="tr_sesionPac_reporte">
                                <th id="th_expSig_reporte">Experiencias significativas</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="tr_sesionPac_reporte">
                                <td id="td_sesionPac_reporte" colSpan={3}>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        <textarea
                                            className="txtA_sesion_reporte"
                                            disabled
                                            rows="4"
                                            cols="50"
                                            value={expedientePac.exp_significativa}
                                        />
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                        <thead>
                            <tr id="tr_sesionPac_reporte">
                                <th id="th_familiograma_reporte">Mapa de relaciones familiares</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="tr_sesionPac_reporte">
                                <td id="td_sesionPac_reporte" colSpan={3}>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        {expedientePac.familiograma}
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                        <thead>
                            <tr id="tr_sesionPac_reporte">
                                <th id="th_familiograma_reporte">Diagnóstico</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="tr_sesionPac_reporte">
                                <td id="td_sesionPac_reporte" colSpan={3}>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 12 }}>
                                        <textarea
                                            className="txtA_sesion_reporte"
                                            disabled
                                            rows="4"
                                            cols="50"
                                            value={expedientePac.diagnostico}
                                        />
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </CardContent>
        </React.Fragment>
    );


    return (
        <Layout>
            <Content>
                <div className="div-1-reporte">
                    <div className="px_div_2_reporte">
                        <div className="px_sesion_reporte">
                            <label id="lbl-enSesion">{tipoUsuario()} &nbsp; </label>
                            <label id="lbl-enSesion_psic">{usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_reporte">
                            <label>Reporte general</label>
                        </div>
                    </div>
                    <div className="div-btnSalirTop-reporte">
                        <div className="div-btnSalirTop2-reporte">
                            <button value="cancelar" id="btn-cancelar-edit" onClick={handleSalir}>Regresar</button>
                        </div>
                        <div className="div-btnSalirTop3-reporte">
                            <button value="descargar" id="btn-cancelar-edit" onClick={handlePDF}>Descargar PDF</button>
                        </div>
                    </div>
                    <div className="px_div_3_reporte">
                        <div className="div-3-card-reporte">
                            <div className="card">
                                <div className="card-body">
                                    <label>Datos personal</label>
                                </div>
                            </div>
                            <div>
                                <Box sx={{ minWidth: 275 }}>
                                    <Card variant="outlined">{card_datosPersonales}</Card>
                                </Box>
                            </div>
                        </div>
                        <div className="div-3-card-reporte">
                            <div className="card">
                                <div className="card-body">
                                    <label>Ingreso al sistema</label>
                                </div>
                            </div>
                            <div>
                                <Box sx={{ minWidth: 275 }}>
                                    <Card variant="outlined">{card_ingresoSalidaSistema}</Card>
                                </Box>
                            </div>
                        </div>
                        <div className="div-3-card2-reporte">
                            <div className="card">
                                <div className="card-body">
                                    <label>Información personal</label>
                                </div>
                            </div>
                            <div>
                                <Box sx={{ minWidth: 275 }}>
                                    <Card variant="outlined">{card_InfoPaciente}</Card>
                                </Box>
                            </div>
                        </div>
                        <div className="div-3-cardFam-reporte">
                            <div className="card">
                                <div className="card-body">
                                    <label>Familiares del paciente</label>
                                </div>
                            </div>
                            <div>
                                <Box sx={{ minWidth: 275 }}>
                                    <Card variant="outlined">{card_fam}</Card>
                                </Box>
                            </div>
                        </div>
                        <div className="div-3-cardCitas-reporte">
                            <div className="card">
                                <div className="card-body">
                                    <label>Citas del paciente</label>
                                </div>
                            </div>
                            <div>
                                <Box sx={{ minWidth: 275 }}>
                                    <Card variant="outlined">{card_citas}</Card>
                                </Box>
                            </div>
                        </div>
                        <div className="div-3-cardSesiones-reporte">
                            <div className="card">
                                <div className="card-body">
                                    <label>Sesiones del paciente</label>
                                </div>
                            </div>
                            <div>
                                <Box sx={{ minWidth: 275 }}>
                                    <Card variant="outlined">{card_sesiones}</Card>
                                </Box>
                            </div>
                        </div>
                        <div className="div-3-cardSesiones-reporte">
                            <div className="card">
                                <div className="card-body">
                                    <label>Canalizaciones del paciente</label>
                                </div>
                            </div>
                            <div>
                                <Box sx={{ minWidth: 275 }}>
                                    <Card variant="outlined">{card_canalizaciones}</Card>
                                </Box>
                            </div>
                        </div>
                        <div className="div-3-cardExpediente-reporte">
                            <div className="card">
                                <div className="card-body">
                                    <label>Expediente del paciente</label>
                                </div>
                            </div>
                            <div>
                                <Box sx={{ minWidth: 275 }}>
                                    <Card variant="outlined">{card_expediente}</Card>
                                </Box>
                            </div>
                        </div>
                    </div>

                    <div className="div-btnSalir1-reporte">
                        <div className="div-btnSalir2-reporte">
                            <button value="cancelar" id="btn-cancelar-edit" onClick={handleSalir}>Regresar</button>
                        </div>
                        <div className="div-btnSalir3-reporte">
                            <button value="descargar" id="btn-cancelar-edit" onClick={handlePDF}>Descargar PDF</button>
                        </div>
                    </div>
                </div>
            </Content>
        </Layout >
    );
}


export default Reporte;





