
import axios from "axios";

const GLOSARIO_API_BASE_URL = "server/glosario";
const GLOSARIO_ADD_API_BASE_URL = "server/glosarioAdd";
const GLOSARIO_DELETE_API_BASE_URL = "server/glosarioDelete";
const GLOSARIO_EDIT_API_BASE_URL = "server/glosarioEdit";
const GLOSARIO_BUSCAR_API_BASE_URL = "server/glosarioBuscar";


// const GLOSARIO_API_BASE_URL = "http://localhost:3002/glosario";
// const GLOSARIO_ADD_API_BASE_URL = "http://localhost:3002/glosarioAdd";
// const GLOSARIO_DELETE_API_BASE_URL = "http://localhost:3002/glosarioDelete";
// const GLOSARIO_EDIT_API_BASE_URL = "http://localhost:3002/glosarioEdit";
// const GLOSARIO_BUSCAR_API_BASE_URL = "http://localhost:3002/glosarioBuscar";

class GlosarioService {

    async createConcepto(data) {
        const response = await axios.post(GLOSARIO_ADD_API_BASE_URL, data)
        .then( response => {
            return response;
        })
        .catch(error => {
            console.error("GS createConcepto " + error);
        })
        return response;
    }

    async getConceptos() {
        const response = await axios.get(GLOSARIO_API_BASE_URL)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error("GS getConceptos " + error);
        })
        const concepto = response.data;
        return concepto;
    }

    async getDescripcionIndividual(id_glosario) {
        const response = await axios.get(`${GLOSARIO_API_BASE_URL}Descripcion?id_glosario=${id_glosario}`)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error("GS getDescripcionIndividual " + error);
        })
        const concepto = response.data;
        return concepto;
    }

    async editarConceptos(data) {
        const response = await axios.put(GLOSARIO_EDIT_API_BASE_URL, data)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error("GS putConceptos " + error);
        })
        const concepto = response.data;
        return concepto;
    }

    async eliminarConcepto(id_glosario) {
        return await axios.delete(`${GLOSARIO_DELETE_API_BASE_URL}?id_glosario=${id_glosario}`);
    }

    async buscarConcepto(concepto) {
        return await axios.get(`${GLOSARIO_BUSCAR_API_BASE_URL}?concepto=${concepto}`);
    }


}

export default new GlosarioService();