
import React, { useEffect, useState } from "react";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';

import PacientesService from "../../services/PacientesService";
import TransferirService from "../../services/TransferirService";

import "../../styles/Citas.css";
import "../../styles/Transferir.css";
import "../../styles/MenuHamburguesa.css";


const Transferir = (id) => {

    const [filtroPacRecibe, setFiltroPacRecibe] = useState([]);     // filtrar pac recibe duplicados
    const [filtroPacEnvia, setFiltroPacEnvia] = useState([]);     // filtrar pac envia duplicados

    const [transferIndivRecibe, setTransferIndivRecibe] = useState([]);
    const [transferIndivEnvia, setTransferIndivEnvia] = useState([]);
    const [fotoEnvia, setFotoEnvia] = useState('');
    const [fotoRecibe, setFotoRecibe] = useState('');
    const [sinFotoRx, setSinFotoRx] = useState('');
    const [sinFotoTx, setSinFotoTx] = useState('');

    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }

    const getData = async () => {
        filtrarPacRecibidos();
        filtrarPacEnviados();
    };


    useEffect(() => {
        getData();
    }, []);


    // cargar pacientes transferidos que Recibe
    const cargarTransferRecibe = async (id_paciente, psic_envia, psic_recibe) => {

        const data2 = await TransferirService.getTransferirRecibeIndividual(id_paciente, psic_envia, psic_recibe);
        setTransferIndivRecibe(data2);      // info transferencia

        // metodo: select * from vtransferir where id_paciente = 1 AND psic_recibe = 1;

        const data = await PacientesService.getInfoPaciente(id_paciente);

        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64

        if (cont.length < 1) {
            setSinFotoRx("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setSinFotoRx("1");
        }

        setFotoRecibe(data[0].fotografia.data)  // get base64 de DB

    };


    // // cargar pacientes transferidos que Envía
    const cargarTransferEnvia = async (id_paciente, psic_envia, psic_recibe) => {

        const data2 = await TransferirService.getTransferirEnviaIndividual(id_paciente, psic_envia, psic_recibe);
        setTransferIndivEnvia(data2);

        const data = await PacientesService.getInfoPaciente(id_paciente);

        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64

        if (cont.length < 1) {
            setSinFotoTx("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setSinFotoTx("1");
        }

        setFotoEnvia(data[0].fotografia.data)  // get base64 de DB
    };



    // filtrar duplicado Pacientes recibidos

    async function filtrarPacRecibidos() {

        let arreObj1 = [];
        let cont1 = 0;

        const transferRecibe = await TransferirService.getTransferirRecibe(usuario.id_psicologo);  // pacientes que recibe: vtransferir => psic_recibe

        arreObj1 = transferRecibe;

        for (let i = 0; i < arreObj1.length; i++) {

            if (arreObj1[i].psic_recibe === parseInt(usuario.id_psicologo)) {

                for(let j=0; j<transferRecibe.length; j++) {

                    if(arreObj1[i].id_paciente === transferRecibe[j].id_paciente) {

                        cont1++;

                        if(cont1 > 1) {     // si hay un elemento duplicado
                            arreObj1.splice(i, 1);    // Empezando desde el índice i removió 1 elemento
                        }
                    }
                    cont1 = 0;
                }
            }
        }

        setFiltroPacRecibe(arreObj1);

    }



    const DisplayDataRecibe = filtroPacRecibe.map((info) => {
        return (
            <tr>
                <td className="td-border-button">
                    <button onClick={() => cargarTransferRecibe(info.id_paciente, info.psic_envia, info.psic_recibe)} id="liga_px">
                        {info.p_nom} {info.p_ap1} {info.p_ap2}
                    </button>
                </td>
            </tr>
        )
    });





    // filtrar duplicado Pacientes enviados

    async function filtrarPacEnviados() {

        let arreObj1 = [];
        let cont1 = 0;

        const transferEnvia = await TransferirService.getTransferirEnvia(usuario.id_psicologo);  // pacientes que envia: vtransferir => psic_envia

        arreObj1 = transferEnvia;

        for (let i = 0; i < arreObj1.length; i++) {

            if (arreObj1[i].psic_envia === parseInt(usuario.id_psicologo)) {

                for(let j=0; j<transferEnvia.length; j++) {

                    if(arreObj1[i].id_paciente === transferEnvia[j].id_paciente) {

                        cont1++;

                        if(cont1 > 1) {     // si hay un elemento duplicado

                            arreObj1.splice(i, 1);    // Empezando desde el índice i removió 1 elemento
                        }

                        cont1 = 0;
                    }
                }
            }

        }

        setFiltroPacEnvia(arreObj1);

    }



    const DisplayDataEnvia = filtroPacEnvia.map((info) => {
        return (
            <tr>
                <td className="td-border-button">
                    <button onClick={() => cargarTransferEnvia(info.id_paciente, info.psic_envia, info.psic_recibe)} id="liga_px">
                        {info.p_nom} {info.p_ap1} {info.p_ap2}
                    </button>
                </td>
            </tr>
        )
    });


    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }


    return (
        <Layout>
            <Content>
                <div className="div-1-citas">
                    <div className="px_div_2_citas">
                        <div className="px_sesion_citas">
                            <label id="lbl-enSesion">{tipoUsuario()} &nbsp; </label>
                            <label id="lbl-enSesion_psic">{usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_citas">
                            <label>Historial de transferencias de pacientes</label>
                        </div>
                    </div>

                    <div className="px_labelPx_citas">
                        <label>Pacientes recibidos</label>
                    </div>
                    <div className="px_div_3_citas">
                        <div className="div-displayPx-cita">
                            <table id="table_filas_px-citas">
                                <tbody>
                                    {DisplayDataRecibe}
                                </tbody>
                            </table>
                        </div>
                        <div className="div-3-Transfer-menu">
                            {
                                transferIndivRecibe.length !== 0 ? (
                                    transferIndivRecibe.map((transInd) => {
                                        return (
                                            <div>
                                                <div className="div-p-nombre-cita">
                                                    <div className="div-avatar-Cita">
                                                        {
                                                            sinFotoRx === "1" ? (
                                                                fotoRecibe === "" ? (
                                                                    <div id="img_avatar_Exp">
                                                                        <label> Cargando imagen...</label>
                                                                    </div>
                                                                ) : (
                                                                    <div id="img_avatar_Exp">
                                                                        <img src={fotoRecibe} alt="base64"></img>
                                                                    </div>
                                                                )
                                                            ) : (<div></div>)
                                                        }
                                                    </div>
                                                    <div className="div-table-head-cita">
                                                        <table className="table-head-px-cita">
                                                            <tbody>
                                                                <tr>
                                                                    <td id="td-paciente-citas">
                                                                        <label>{transInd.p_nom} {transInd.p_ap1} {transInd.p_ap2}</label>
                                                                    </td>
                                                                    <td id="td-menuHamb-citas">
                                                                        <div id="div-menuHamb">
                                                                            <nav className="nav-menuHamb">
                                                                                <input type="checkbox" id="in-menuHamb"></input>
                                                                                <label id="label-menuHamb" for="in-menuHamb"> ☰ </label>
                                                                                <ul id="ul-menuHamb">

                                                                                    {/*     entorno web  #   */}
                                                                                    <li id="li-menuHamb-admin">
                                                                                        <a href={`#/sesiones/${transInd.id_paciente}`}>Sesiones</a>
                                                                                    </li>
                                                                                    <li id="li-menuHamb-admin">
                                                                                        <a href={`#/expedientes/${transInd.id_paciente}`}>Expediente</a>
                                                                                    </li>
                                                                                    <li id="li-menuHamb-admin">
                                                                                        <a href={`#/familiares/${transInd.id_paciente}`}>Familiares</a>
                                                                                    </li>
                                                                                    <li id="li-menuHamb-admin">
                                                                                        <a href={`#/canalizaciones/${transInd.id_paciente}`}>Canalizaciones</a>
                                                                                    </li>
                                                                                    <li id="li-menuHamb-admin">
                                                                                        <a href={`#/notificaciones/${transInd.id_paciente}`}>Notificaciones</a>
                                                                                    </li>

                                                                                </ul>
                                                                            </nav>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="div_table_display_citas">
                                                    <div className="div_tableEncabezado_citas">
                                                        <table id="table_head_datos_cita">
                                                            <thead>
                                                                <tr id="encabezado_datos_transfer">
                                                                    <th>Recibido de:</th>
                                                                    <th>Fecha</th>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>
                                                    <div className="div_tableFilas_citas">
                                                        <table id="table_datos_cita">
                                                            <tbody>
                                                                <tr id="filas_px_citas">
                                                                    <td>
                                                                        <label id="lbl-trabajo-citas">{transInd.usu_nom} {transInd.usu_ap1} {transInd.usu_ap2}</label>
                                                                    </td>
                                                                    <td>
                                                                        <label id="lbl-trabajo-citas">{transInd.fecha}</label>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="div-headMotivo-transfer">
                                                        <div className="div_tableEncabezado_citas">
                                                            <table id="table_head_datos_cita">
                                                                <thead>
                                                                    <tr id="encabezado_datos_citas">
                                                                        <th>Motivo</th>
                                                                    </tr>
                                                                </thead>
                                                            </table>
                                                        </div>
                                                        <div className="div_tableFilas_citas">
                                                            <table id="table_datos_cita">
                                                                <tbody>
                                                                    <tr id="filas_px_transfer">
                                                                        <td>
                                                                            <label id="lbl-motivo-transfer">{transInd.motivo}</label>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : (
                                    <label>
                                        Selecciona un paciente de la lista.
                                    </label>
                                )
                            }
                        </div>
                    </div>

                    <div className="px_labelPx_citas">
                        <label>Pacientes enviados</label>
                    </div>
                    <div className="px_div_3_citas">
                        <div className="div-displayPx-cita">
                            <table id="table_filas_px-citas">
                                <tbody>
                                    {DisplayDataEnvia}
                                </tbody>
                            </table>
                        </div>
                        <div className="div-3-Transfer-menu">
                            {
                                transferIndivEnvia.length !== 0 ? (
                                    transferIndivEnvia.map((transfEnv) => {
                                        return (
                                            <div>
                                                <div className="div-p-nombre-cita">
                                                    <div className="div-avatar-Cita">
                                                        {
                                                            sinFotoTx === "1" ? (
                                                                fotoEnvia === "" ? (
                                                                    <div id="img_avatar_Exp">
                                                                        <label> Cargando imagen...</label>
                                                                    </div>
                                                                ) : (
                                                                    <div id="img_avatar_Exp">
                                                                        <img src={fotoEnvia} alt="base64"></img>
                                                                    </div>
                                                                )
                                                            ) : (<div></div>)
                                                        }
                                                    </div>
                                                    <div className="div-table-head-cita">
                                                        <table className="table-head-px-cita">
                                                            <tbody>
                                                                <tr>
                                                                    <td id="td-paciente-citas">
                                                                        <label>{transfEnv.p_nom} {transfEnv.p_ap1} {transfEnv.p_ap2}</label>
                                                                    </td>
                                                                    <td id="td-menuHamb-citas">
                                                                        <div id="div-menuHamb">
                                                                            <nav className="nav-menuHamb">
                                                                                <input type="checkbox" id="in-menuHamb"></input>
                                                                                <label id="label-menuHamb" for="in-menuHamb"> ☰ </label>
                                                                                <ul id="ul-menuHamb">

                                                                                    {/*     entorno web     */}
                                                                                    <li id="li-menuHamb-admin">
                                                                                        <a href={`#/sesiones/${transfEnv.id_paciente}`}>Sesiones</a>
                                                                                    </li>
                                                                                    <li id="li-menuHamb-admin">
                                                                                        <a href={`#/expedientes/${transfEnv.id_paciente}`}>Expediente</a>
                                                                                    </li>
                                                                                    <li id="li-menuHamb-admin">
                                                                                        <a href={`#/familiares/${transfEnv.id_paciente}`}>Familiares</a>
                                                                                    </li>
                                                                                    <li id="li-menuHamb-admin">
                                                                                        <a href={`#/canalizaciones/${transfEnv.id_paciente}`}>Canalizaciones</a>
                                                                                    </li>
                                                                                    <li id="li-menuHamb-admin">
                                                                                        <a href={`#/notificaciones/${transfEnv.id_paciente}`}>Notificaciones</a>
                                                                                    </li>
                                                                                </ul>
                                                                            </nav>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="div_table_display_citas">
                                                    <div className="div_tableEncabezado_citas">
                                                        <table id="table_head_datos_cita">
                                                            <thead>
                                                                <tr id="encabezado_datos_transfer">
                                                                    <th>Transferido a:</th>
                                                                    <th>Fecha</th>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>
                                                    <div className="div_tableFilas_citas">
                                                        <table id="table_datos_cita">
                                                            <tbody>
                                                                <tr id="filas_px_citas">
                                                                    <td>
                                                                        <label id="lbl-trabajo-citas">{transfEnv.usua_nom} {transfEnv.usua_ap1} {transfEnv.usua_ap2}</label>
                                                                    </td>
                                                                    <td>
                                                                        <label id="lbl-trabajo-citas">{transfEnv.fecha}</label>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="div-headMotivo-transfer">
                                                        <div className="div_tableEncabezado_citas">
                                                            <table id="table_head_datos_citas">
                                                                <thead>
                                                                    <tr id="encabezado_datos_citas">
                                                                        <th>Motivo</th>
                                                                    </tr>
                                                                </thead>
                                                            </table>
                                                        </div>
                                                        <div className="div_tableFilas_citas">
                                                            <table id="table_datos_cita">
                                                                <tbody>
                                                                <tr id="filas_px_transfer">
                                                                        <td>
                                                                            <label id="lbl-motivo-transfer">{transfEnv.motivo}</label>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : (
                                    <label>Selecciona un paciente de la lista.</label>
                                )
                            }
                        </div>
                    </div>
                </div>
            </Content>
        </Layout>
    );
};

export default Transferir;