

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';
import moment from 'moment';

import PacientesService from "../../services/PacientesService";
import InformacionPacientes from "../../services/InformacionPacientes";
import RecomendacionService from "../../services/RecomendacionService";

import "../../styles/FormularioAddFamiliar.css";
import "../../styles/Recomendacion.css";
import "../../styles/RecomendacionAdd.css";
import "../../styles/FormularioEditarRecomendacion.css";



const AddRecomendaciones = (id) => {

    const [informacion, setInformacion] = useState([]);
    const [formatoFoto, setFormatoFoto] = useState('');
    const [image, setImage] = useState('');   // obtiene la img base64String del btn Elegir Archivo
    const [imageData, setImageData] = useState('');   // bandera, si la img es cargada para actualizar o viene de la DB
    const [foto, setFoto] = useState('');  // bandera, hay o no foto en la DB
    const [fotografia, setFotografia] = useState('');
    const [fecha, setFecha] = useState('');
    const [hora, setHora] = useState('');
    const [textComentario, setTextComentario] = useState('');
    const [sinFoto, setSinFoto] = useState('');

    const navigate = useNavigate();
    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);
    const routeParams = useParams();

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    })

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }

    function mostrarError(mensaje) {
        Swal.fire({
            title: '¡Error!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }


    const getData = async () => {

        if (routeParams.id !== undefined) {
            cargarInformacion(routeParams.id);
        } else {
            alert("Error al cargar la página.")
        }
    }

    useEffect(() => {
        getData();
        getFecha();
        getHora();
    }, [])


    const cargarInformacion = async (id_paciente) => {

        const data = await PacientesService.getInfoPaciente(id_paciente);

        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64

        if (cont.length < 1) {
            setSinFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setSinFoto("1");
        }

        setFotografia(data[0].fotografia.data)  // get base64 de DB

        setInformacion(data[0]);
    }

    const getFecha = () => {
        let anio = new Date().getFullYear();
        let mes = new Date().getMonth() + 1;
        let dia = new Date().getDate();
        let hoy = anio + "/" + mes + "/" + dia;
        let fecha = moment(hoy).format('YYYY-MM-DD');

        setFecha(fecha)
    }

    const getHora = () => {
        let hr = new Date().getHours();
        let min = new Date().getMinutes();
        let sec = new Date().getSeconds();

        if (hr < 10) hr = "0" + hr;
        if (min < 10) min = "0" + min;  // el formato es mm, 05, 08, etc
        if (sec < 10) sec = "0" + sec;

        setHora(hr + ":" + min);
    }


    const agregarRecomendacion = async () => {

        const nuevaRecomendacion = {
            fecha: fecha,
            hora: hora,
            recomendacion: textComentario,
            fk_psicologo: usuario.id_psicologo,
            fk_paciente: parseInt(routeParams.id)
        }
        try {
            const respuesta = await RecomendacionService.nuevaRecomendacion(nuevaRecomendacion);
            console.log(nuevaRecomendacion)
            if (respuesta.status === 200) {
                Toast.fire("¡La recomendación se creó correctamente!", "", "success").then((result) => {
                    if (result.isConfirmed) {
                        // navigate('/recomendaciones');  //local
                        navigate('/recomendaciones');  //web
                    }
                }).then(function () {
                    // navigate('/recomendaciones');  //local
                    navigate('/recomendaciones');  //web
                });
            } else {
                mostrarError("Hubo un problema al guardar la recomendación.");
            }
        } catch (error) {
            mostrarError("Hubo un problema al guardar la recomendación.");
            console.log('Error al realizar la inserción:', error.message);
        }
    };

    const TextComentario = (event) => {
        setTextComentario(event.target.value);
    }

    const handleSalir = () => {
        navigate("/recomendaciones")
    }

    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }

    return (
        <Layout>
            <Content>
                <div className="div-1-addRecom">
                    <div className="div-2-addRecom">
                        <div className="px_sesion_addRec">
                            <label id="lbl-enSesion">{tipoUsuario()} &nbsp; </label>
                            <label>{usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_addRecom">
                            <label>Añade una recomendación</label>
                        </div>
                    </div>
                    <div className="div-datos-addRecom">
                        <div id="div_image_addRecom">
                            <div className="div-avatar-addRec">
                                {
                                    sinFoto === "1" ? (
                                        fotografia === "" ? (
                                            <div id="img_avatar_Exp">
                                                <label> Cargando imagen...</label>
                                            </div>
                                        ) : (
                                            <div id="img_avatar_Exp">
                                                <img src={fotografia} alt="base64"></img>
                                            </div>
                                        )
                                    ) : (<div></div>)
                                }
                            </div>
                        </div>
                        <div id="div_label_addRecom">
                            <label>{informacion.nombre} {informacion.ap1} {informacion.ap2}</label>
                        </div>
                    </div>

                    <div className="div-3-addRecom">
                        <div className="table-border-addRecom">
                            <table className="table-addRecom">
                                <tbody>
                                    <tr>
                                        <td id="td-addRecom-izq">
                                            <label> Fecha: </label>
                                        </td>
                                        <td id="td-addRecom-der">
                                            <input type="date" value={fecha} disabled></input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td id="td-addRecom-izq">
                                            <label> Hora: </label>
                                        </td>
                                        <td id="td-addRecom-der">
                                            <input type="time" id="hora-addRecom" value={hora} disabled></input>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="div-addRec-txtA">
                            <textarea id="addRecomend" placeholder="Deja tu recomendación..." onChange={TextComentario}></textarea>
                        </div>
                    </div>

                    <div className="div-autoregistro-botones-addRecom">
                        <div className="div-addRec-enviar">
                            <button value="enviar" id="btn-enviar-addPsi" onClick={agregarRecomendacion}>Enviar</button>
                        </div>
                        <div className="div-addRec-cancelar">
                            <button value="cancelar" id="btn-cancelar-addPsi" onClick={handleSalir}>Cancelar</button>
                        </div>
                    </div>
                </div>
            </Content>
        </Layout>
    )
}


export default AddRecomendaciones;


