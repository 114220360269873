
// FormularioEditarCita.js  --- Psicologo

import React from "react";
import "../../styles/FormularioEditarCita.css";

const FormularioEditarCita = ({ paciente, fechaF, horaI, horaF, modalidad, comentario_px, comentario_psi }) => {

  return (
    <div className="div-editar-cita-modal">
      <div className="div-border-modal">
        <table className="tabla-editar-cita-modal">
          <tbody>
            <tr className="table-line-bottom">
              <td className="editar-cita-td-izq">
                <label className="lbl-izq-editar"> Paciente: </label>
              </td>
              <td className="editar-cita-td-der">
                <select id="select-px-editar" disabled>
                  <option className="selected-option" value={paciente.id_paciente}>
                    {paciente.nombre} {paciente.ap1} {paciente.ap2}
                  </option>
                </select>
              </td>
            </tr>
            <tr className="table-line-bottom">
              <td className="editar-cita-td-izq">
                <label className="lbl-izq-editar"> Fecha: </label>
              </td>
              <td className="editar-cita-td-der">
                <input type="date" id="input-fecha-editar" defaultValue={fechaF} />
              </td>
            </tr>
            <tr className="table-line-bottom">
              <td className="editar-cita-td-izq">
                <label className="lbl-izq-editar"> Hora Inicio: </label>
              </td>
              <td className="editar-cita-td-der">
                <input type="time" id="input-horaI-editar" defaultValue={horaI} />
              </td>
            </tr>
            <tr className="table-line-bottom">
              <td className="editar-cita-td-izq">
                <label className="lbl-izq-editar"> Hora Final: </label>
              </td>
              <td className="editar-cita-td-der">
                <input type="time" id="input-horaF-editar" defaultValue={horaF} />
              </td>
            </tr>
            <tr>
              <td className="editar-cita-td-izq">
                <label className="lbl-izq-editar"> Modalidad: </label>
              </td>
              <td className="registrar-cita-td-der">
                <select id="select-modalidad-editar" value={modalidad}>
                  <option className="selected-option" value="elegir" disabled> Selecciona una opción </option>
                  <option className="selected-option" value="presencial">Presencial</option>
                  <option className="selected-option" value="videollamada">Videollamada</option>
                  <option className="selected-option" value="telefono">Teléfono</option>
                  <option className="selected-option" value="mensajes">Mensajes: voz, texto</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="div-txtArea-coment">
        <div className="div-txtArea2-coment">
          <label> Comentario del paciente: </label>
        </div>
        <div className="div-txtParrafo3-coment">
          <p> {comentario_px} </p>
        </div>
      </div>
      <div className="div-txtArea-coment">
        <div className="div-txtArea2-coment">
          <label> Comentario del psicólogo: </label>
        </div>
        <div className="div-txtArea3-coment">
          <textarea id="textarea-comentario-editar" defaultValue={comentario_psi} placeholder="Deja tu comentario..." rows="4" cols="50" />
        </div>
      </div>
    </div>
  );

}

export default FormularioEditarCita;
