

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';
import moment from 'moment';

import PacientesService from "../../services/PacientesService";
import InformacionPacientes from "../../services/InformacionPacientes";
import RecomendacionService from "../../services/RecomendacionService";
import SesionesService from "../../services/SesionesService";

import Sesiones from "../Sesiones/Sesiones";

import "../../styles/FormularioAddFamiliar.css";
import "../../styles/Recomendacion.css";
import "../../styles/RecomendacionAdd.css";
import "../../styles/FormularioEditarRecomendacion.css";

import "../../styles/RedactarSesion.css";



const RedactarSesion = (id) => {


    console.log("---- RedactarSesion -----")


    const [informacion, setInformacion] = useState([]);
    const [formatoFoto, setFormatoFoto] = useState('');
    const [image, setImage] = useState('');   // obtiene la img base64String del btn Elegir Archivo
    const [imageData, setImageData] = useState('');   // bandera, si la img es cargada para actualizar o viene de la DB
    const [foto, setFoto] = useState('');  // bandera, hay o no foto en la DB
    const [fotografia, setFotografia] = useState('');
    const [sinFoto, setSinFoto] = useState('');

    const [sexo, setSexo] = useState('');
    const [edoCivil, setEdoCivil] = useState('');
    const [edo, setEstado] = useState('');
    const [pais, setPais] = useState('');

    const [fecha, setFecha] = useState('');
    const [hora_i, setHora_i] = useState('');
    const [hora_f, setHora_f] = useState('');
    const [textComentario, setTextComentario] = useState('');

    const [txtSesion, setTxtSesion] = useState("");

    const [idSesion, setIdSesion] = useState("");
    const [idPaciente, setIdPaciente] = useState("");
    const [estatusSesion, setEstatusSesion] = useState("Sesión no iniciada");
    const [numSesion, setNumSesion] = useState("");

    const navigate = useNavigate();

    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);
    const routeParams = useParams();


    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    })

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }

    function mostrarError(mensaje) {
        Swal.fire({
            title: '¡Error!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }


    const getData = async () => {
        if (routeParams.id !== undefined) {

            let valores = routeParams.id;
            let val = valores.split(',')

            // Id Paciente: val[0]
            setIdPaciente(val[0]);

            // id Sesión activa
            setIdSesion(parseInt(val[1]));
            consultarSesionActiva(parseInt(val[1]));

            // no. de Sesion
            setNumSesion(parseInt(val[2]));

            cargarInformacion(parseInt(val[0]), parseInt(val[1]));

        } else {

            alert("Error al obtner parámetro ID.");

        }
    }

    useEffect(() => {

        getData();

    }, [])


    const cargarInformacion = async (id_paciente, id_ses) => {

        const data = await PacientesService.getInfoPaciente(id_paciente);

        setSexo(InformacionPacientes.sexoPaciente(data[0].sexo));
        setEdoCivil(InformacionPacientes.estadoCivil(data[0].edo_civil));
        setEstado(InformacionPacientes.estadosRepublica(data[0].edo));
        setPais(InformacionPacientes.paisesPaciente(data[0].pais));

        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64

        if (cont.length < 1) {
            setSinFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setSinFoto("1");
        }

        setFotografia(data[0].fotografia.data)  // get base64 de DB
        setInformacion(data[0]);

        const data2 = await SesionesService.getSesionesIndividualWeb(id_ses);
        setFecha(data2[0].fecha);
        setHora_i(data2[0].hora_i);
        setHora_f(data2[0].hora_f);

    }


    const consultarSesionActiva = async (id_sesion) => {

        const data = await SesionesService.getSesionesIndividualWeb(id_sesion);
        setTxtSesion(data[0].sesion);
    }

    const guardarSesion = async () => {

        if (textComentario === '') {

            console.log("g aaaaaaaaaa")

            if (txtSesion === '') {

                Swal.fire({
                    title: "Quieres Guardar, pero el campo de texto está vacío. ¿Deseas continuar?",
                    // showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Sí",
                    //denyButtonText: `Don't save`
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate(`/sesiones/${idPaciente}`);
                    }
                });

            } else if (txtSesion !== '') {

                navigate(`/sesiones/${idPaciente}`);

            }

        } else if (textComentario !== '') {
            try {

                // console.log("g cccccccc")

                await SesionesService.guardarSesion(textComentario, estatusSesion, idSesion);

                Toast.fire("¡La Redacción se guradó correctamente!", "", "success")
                    .then((result) => {
                        if (result.isConfirmed) {
                            // navigate('/sesiones');  //web
                        }
                    }).then(function () {
                        // navigate('/sesiones');  //web
                    });

            } catch (error) {
                mostrarError("Hubo un problema al guardar la Redacción.");
                console.log('Error al realizar la inserción:', error.message);
            }
        }
    };

    const TextComentario = (event) => {
        if (txtSesion !== '') {
            const textoFormateado = event.target.value.replace(/\n/g, '[[SALTO]]'); // Reemplazamos saltos de línea con "\\n".            
            setTxtSesion(textoFormateado);
            setTextComentario(textoFormateado);

        } else if (txtSesion === '') {

            console.log(" txt nuevo ")
            const textoFormateado = event.target.value.replace(/\n/g, '[[SALTO]]');
            setTextComentario(textoFormateado);
        }
    }

    const handleSelect = async (event) => {
        setEstatusSesion(event.target.value);
    };

    const handleSalir = () => {
        navigate("/sesiones")
    }

    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }


    return (
        <Layout>
            <Content>
                <div className="div-1-RedSesion">
                    <div className="div-2-RedSesion">
                        <div className="px_sesion_addRec">
                            <label id="lbl-enSesion">{tipoUsuario()} &nbsp; </label>
                            <label>{usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_addRecom">
                            <label>Redacatar sesión</label>
                        </div>
                    </div>
                    <div className="div-datos-RedSesion">
                        <div id="div-image-RedSesion">
                            <div className="div-avatar-addRec">
                                {
                                    sinFoto === "1" ? (
                                        fotografia === "" ? (
                                            <div id="img_avatar_Exp">
                                                <label> Cargando imagen...</label>
                                            </div>
                                        ) : (
                                            <div id="img_avatar_Exp">
                                                <img src={fotografia} alt="base64"></img>
                                            </div>
                                        )
                                    ) : (<div></div>)
                                }
                            </div>
                        </div>
                        <table className="table-head-px-sesion">
                            <tr>
                                <td id="td-paciente-citas" colSpan={4}>
                                    <a href={`#/pacientes/${informacion.id_paciente}`}>
                                        <label>{informacion.nombre} {informacion.ap1} {informacion.ap2}</label>
                                    </a>

                                </td>
                                <td id="td-menuHamb-citas">
                                    <div id="div-menuHamb">
                                        <nav className="nav-menuHamb">
                                            <input type="checkbox" id="in-menuHamb"></input>
                                            <label id="label-menuHamb" for="in-menuHamb"> ☰ </label>
                                            <ul id="ul-menuHamb">
                                                <li id="li-menuHamb-admin">
                                                    <a href={`#/sesiones/${informacion.id_paciente}`}>Sesiones</a>
                                                </li>
                                                <li id="li-menuHamb-admin">
                                                    <a href={`#/expedientes/${informacion.id_paciente}`}>Expediente</a>
                                                </li>
                                                <li id="li-menuHamb-admin">
                                                    <a href={`#/familiares/${informacion.id_paciente}`}>Familiares</a>
                                                </li>
                                                <li id="li-menuHamb-admin">
                                                    <a href={`#/canalizaciones/${informacion.id_paciente}`}>Canalizaciones</a>
                                                </li>
                                                <li id="li-menuHamb-admin">
                                                    <a href={`#/notificaciones/${informacion.id_paciente}`}>Notificaciones</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </td>
                            </tr>
                            <tr id="encabezado_px_citas">
                                <th>Edad</th>
                                <th>Trabajo actual</th>
                                <th>Edo. civil</th>
                                <th>Ciudad</th>
                                <th>Tel</th>
                            </tr>
                            <tr id="filas_px_citas">
                                <td>
                                    <label id="lbl-edad-citas">{informacion.edad}</label>
                                </td>
                                <td>
                                    <label id="lbl-trabajo-citas">{informacion.trabajo_actual}</label>
                                </td>
                                <td>
                                    <label id="lbl-edo-civil-citas">{edoCivil}</label>
                                </td>
                                <td>
                                    <label id="lbl-ciudad-citas">{informacion.ciudad}</label>
                                </td>
                                <td>
                                    <label id="lbl-tel-citas">{informacion.tel}</label>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div className="table-border-RedSesion">
                        <table className="table-RedSesion">
                            <tbody>
                                <tr>
                                    <td id="td-RedSesion1-izq">
                                        <label> Número de sesión: </label>
                                    </td>
                                    <td id="td-RedSesion1-der">
                                        <label>{numSesion}</label>
                                    </td>
                                    <td id="td-RedSesion1-izq">
                                        <label>Datos de la cita:</label>
                                    </td>
                                    <td id="td-RedSesion-izq">
                                        <label> Fecha: </label>
                                    </td>
                                    <td id="td-RedSesion-der">
                                        <label> {fecha} </label>
                                    </td>
                                    <td id="td-RedSesion-izq">
                                        <label> Horario: </label>
                                    </td>
                                    <td id="td-RedSesion-der">
                                        <label> {hora_i} - {hora_f}</label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div id="div-select-Redsesion">
                        <label>Estatus de la <b>sesión</b>:</label>
                        <select className="select-estatus-RedSesion" value={estatusSesion} onChange={handleSelect}>
                            <option >Sesión no iniciada</option>
                            <option>Sesión atendida</option>
                            <option>Sesión con retardo</option>
                            <option>Sesión cancelada por el paciente</option>
                            <option>Sesión cancelada por el psicólogo</option>
                            <option>El paciente no se presentó</option>
                        </select>
                    </div>

                    <div className="div-3-RedSesion">
                        {
                            txtSesion === '' ? (
                                estatusSesion === "Sesión no iniciada" ? (
                                    <div className="div-RedSesion-txtA">
                                        <textarea id="RedSesion_txtA"
                                            disabled
                                            placeholder="Para redactar, cambia el estatus a Sesión iniciada o según corresponda."
                                            rows="4"
                                            cols="50" />
                                    </div>
                                ) : (
                                    estatusSesion !== "Sesión no iniciada" ? (
                                        <div className="div-RedSesion-txtA">
                                            <textarea id="RedSesion_txtA"
                                                placeholder="Puedes iniciar la captura de la sesión..."
                                                rows="4"
                                                cols="50"
                                                onChange={TextComentario} />
                                        </div>
                                    ) : (<></>)
                                )
                            ) : (
                                txtSesion !== '' ? (
                                    estatusSesion === "Sesión no iniciada" ? (
                                        <div className="div-RedSesion-txtA">
                                            <div className="div-RedSesion2-txtA">
                                                <label id="label-RedSesion3-txtA">
                                                    ¡Para <b>continuar</b>, cambia el estatus de la Sesión!
                                                </label>
                                            </div>
                                            <div className="div-RedSesion3-txtA">
                                                <textarea
                                                    id="RedSesion_txtA_continuar"
                                                    disabled
                                                    placeholder="Para redactar, cambia el estatus a Sesión iniciada o según corresponda."
                                                    value={txtSesion.replace(/\[\[SALTO\]\]/g, '\n')} rows="4" cols="50" onChange={TextComentario} />
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="div-RedSesion3-txtA">
                                                <textarea id="RedSesion_txtA"
                                                    value={txtSesion.replace(/\[\[SALTO\]\]/g, '\n')}
                                                    rows="4"
                                                    cols="50"
                                                    onChange={TextComentario} />
                                            </div>
                                            <div className="div-RedSesion-enviar">
                                                <button value="enviar" id="btn-enviar-addPsi" onClick={guardarSesion}>Guardar</button>
                                            </div>
                                        </div>
                                    )
                                ) : (<></>)
                            )
                        }
                    </div>

                    <div className="div-autoregistro-botones-RedSesion">
                        <div className="div-RedSesion-cancelar">
                            <button value="cancelar" id="btn-cancelar-addPsi" onClick={handleSalir}>Salir</button>
                        </div>
                    </div>
                </div>
            </Content>
        </Layout>
    )
}


export default RedactarSesion;


