
import axios from "axios";

const SESIONES_API_BASE_URL = "/server/sesiones";
const VSESIONES_API_BASE_URL = "/server/vsesiones";
const VSESIONES_IND_API_BASE_URL = "server/vsesionesIndividual";
const VSESIONES_IND_WEB_API_BASE_URL = "server/vsesionesIndividualWeb";

// const SESIONES_API_BASE_URL = "http://localhost:3002/sesiones";
// const VSESIONES_API_BASE_URL = "http://localhost:3002/vsesiones";
// const VSESIONES_IND_API_BASE_URL = "http://localhost:3002/vsesionesIndividual";
// const VSESIONES_IND_WEB_API_BASE_URL = "http://localhost:3002/vsesionesIndividualWeb";


class SesionesService {

    async crearSesion(sesion) {
        const response = await axios.post(SESIONES_API_BASE_URL, sesion)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error('Error crearSesion ', error);
        });
        // const crearsesion = response.data;
        return response;    // para obtener el status=200 del objeto que se regresa
    }

    
    async guardarSesion(sesion, estatus_sesion, id_sesion) {
        const response = await axios.put(`${SESIONES_API_BASE_URL}?sesion=${sesion}&estatus_sesion=${estatus_sesion}&id_sesion=${parseInt(id_sesion)}`)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.log("guardarSesion: ", error)
        });
        // const guardarsesion = response.data;
        return response;
    }


    async eliminarSesion(idCita) {
        const response = await axios.delete(`${SESIONES_API_BASE_URL}?id_cita=${idCita}`)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.log("eliminarSesion: ", error);
        });
        // const eliminarcita = response.data;
        return response;
    }


    async getSesionesHistorial(id_paciente) {
        const response = await axios.get(`${VSESIONES_API_BASE_URL}Historial?id_paciente=${id_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error getSesionesHistorial ', error);
            });
        const sesiones = response.data;
        return sesiones;
    }


    // Se usa en versión móvil

    async getSesionesIndividual(data) {
        const response = await axios.get(VSESIONES_IND_API_BASE_URL, data)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error getSesionesIndividual ', error);
            });
        const sesion = response.data;
        return sesion;
    }


    // Se usa en versión Web

    async getSesionesIndividualWeb(id_sesion) {
        const response = await axios.get(`${VSESIONES_IND_WEB_API_BASE_URL}?id_sesion=${id_sesion}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error getSesionesIndividualWeb ', error);
            });
        const sesion = response.data;
        return sesion;
    }


    // Administración

    async getSesionesTodas() {
        const response = await axios.get(`${VSESIONES_API_BASE_URL}Todas?`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error getSesionesTodas ', error);
            });
        const sesiones = response.data;
        return sesiones;
    }

}

export default new SesionesService();