
import axios from "axios";

const RESPALDO_API_BASE_URL = "server/backup";
const RESPALDO_FRECC_API_BASE_URL = "server/configurar-frecuencia";
const RESPALDO_LISTA_API_BASE_URL = "server/obtenerListaRespaldo";
const RESTAURAR_DB_API_BASE_URL = "server/restaurarDB";


// const RESPALDO_API_BASE_URL = "http://localhost:3002/backup";
// const RESPALDO_FRECC_API_BASE_URL = "http://localhost:3002/configurar-frecuencia";
// const RESPALDO_LISTA_API_BASE_URL = "http://localhost:3002/obtenerListaRespaldo";
// const RESTAURAR_DB_API_BASE_URL = "http://localhost:3002/restaurarDB";


class RespaldoService {

    async RespaldoManual() {

        return await axios.post(RESPALDO_API_BASE_URL);

    }

    async RespaldoAutomatico(valor) {

        console.log("valor: ", valor)

        return await axios.post(`${RESPALDO_FRECC_API_BASE_URL}?frecuencia=${valor}`);

    }

    async obtenerListaRespaldos() {
        const response = await axios.get(RESPALDO_LISTA_API_BASE_URL)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('PS getREspaldos: ', error);
            });
        const listarespaldos = response.data;
        return listarespaldos;
    }

    async restaurarDB(valor) {

        return await axios.post(`${RESTAURAR_DB_API_BASE_URL}?nombreArchivo=${valor}`);

    }

}


export default new RespaldoService();



