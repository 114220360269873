
/* REACT ROUTER APP */

//import { BrowserRouter, Routes, Route } from "react-router-dom";      /* Entorno Local */

import { HashRouter as Router, Routes, Route } from "react-router-dom";     /* Entorno Web */
import { AuthProvider } from '../services/AuthContext';
import PrivateRoute from '../services/PrivateRoute';

import Login from "../view/Login/Login";

import Calendario from "../view/Agenda/Calendario";

import AddUsuarios from "../view/Usuario/AddUsuarioLoginPx";
import AddUsuariosExpress from "../view/Usuario/AddUsuarioExpressPx";

import Pacientes from "../view/Paciente/Pacientes"
import AddPaciente from "../view/Paciente/AddPaciente";
import AddPacienteExpress from "../view/Paciente/AddPacienteExpress";
import AddPacienteExpressAdmin from "../view/Paciente/AddPacienteExpressAdmin";
import EditPaciente from "../view/Paciente/EditPaciente";
import EditPacienteAdmin from "../view/Paciente/EditPacienteAdmin";

import Transferir from "../view/Transferir/Transferir";

import RolPaciente from "../view/Rol_Paciente/RolPaciente";
import EditRolPaciente from "../view/Rol_Paciente/EditRolPaciente";
import FormularioCambiarContrasena from "../view/Rol_Paciente/FormularioCambiarContrasena";

import CanalizarPaciente from "../view/Canalizaciones/FormularioCanalizarPaciente"

import Psicologo from "../view/Psicologo/Psicologo";
import AddPsicologo from "../view/Psicologo/AddPsicologo";
import EditPsicologo from "../view/Psicologo/EditPsicologo";
import EditPsicologoAdmin from "../view/Psicologo/EditPsicologoAdmin";

import Expedientes from "../view/Expediente/Expedientes";

import Reporte from "../view/Reporte/Reporte";

import Colegas from "../view/Colegas/Colegas";
import AddColega from "../view/Colegas/AddColega";
import EditColega from "../view/Colegas/EditColega";
import AddColegaAdmin from "../view/Colegas/AddColegaAdmin";
import EditColegaAdmin from "../view/Colegas/EditColegaAdmin";

import Familiares from "../view/Familiar/Familiares";
import AddFamiliar from "../view/Familiar/AddFamiliar";
import EditFamiliar from "../view/Familiar/EditFamiliar";

import Recomendacion from "../view/Recomendaciones/Recomendacion";
import AddRecomendacion from "../view/Recomendaciones/AddRecomendacion";

import Sesiones from "../view/Sesiones/Sesiones";
import RedacatarSesion from "../view/Sesiones/RedactarSesion";
import ConsultarSesion from "../view/Sesiones/ConsultarSesion";

import Glosario from "../view/Glosario/Glosario";
import GlosarioAdd from "../view/Glosario/GlosarioAdd";
import GlosarioEditar from "../view/Glosario/GlosarioEditar";

import Canalizaciones from "../view/Canalizaciones/Canalizaciones";
import Citas from "../view/Citas/Citas";
import Notificaciones from "../view/Notificaciones/Notificaciones";
import Pendientes from "../view/Pendientes/Pendientes";
import Administracion from "../view/Administracion/Administracion";
import ConsultarExpediente from "../view/Expediente/ConsultarExpediente";


function App() {

  // Entorno Local
  /*
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />}></Route>

        <Route exact path="/calendario" element={<Calendario />}></Route>

        <Route exact path="/AddUsuarioLoginPx" element={<Usuarios />}></Route>

        <Route exact path="/pacientes" element={<Pacientes />}></Route>
        <Route exact path="/addpaciente" element={<AddPaciente />}></Route>
        <Route exact path="/addpacienteexpress" element={<AddPacienteExpress />}></Route>
        <Route exact path="/pacientes/:id" element={<Pacientes />}></Route>
        <Route exact path="/editpaciente/:id" element={<EditPaciente />}></Route>

        <Route exact path="/psicologo/:id" element={<Psicologo />}></Route>
        <Route exact path="/addpsicologo" element={<AddPsicologo />}></Route>
        <Route exact path="/editpsicologo/:id" element={<EditPsicologo />}></Route>

        <Route exact path="/familiares" element={<Familiares />}></Route>
        <Route exact path="/familiares/:id" element={<Familiares />}></Route>
        <Route exact path="/addfamiliar" element={<AddFamiliar />}></Route>
        <Route exact path="/editfamiliar/:id" element={<EditFamiliar />}></Route>

        <Route exact path="/expedientes" element={<Expedientes />}></Route>
        <Route exact path="/expedientes/:id" element={<Expedientes />}></Route>

        <Route exact path="/reporte/:id" element={<Reporte />}></Route>

        <Route exact path="/sesiones" element={<Sesiones />}></Route>
        <Route exact path="/sesiones/:id" element={<Sesiones />}></Route>

        <Route exact path="/canalizaciones" element={<Canalizaciones />}></Route>
        <Route exact path="/FormularioCanalizarPaciente" element={<CanalizarPaciente />}></Route>
        <Route exact path="/canalizaciones/:id" element={<Canalizaciones />}></Route>

        <Route exact path="/colegas" element={<Colegas />}></Route>
        <Route exact path="/addcolega" element={<AddColega />}></Route>
        <Route exact path="/editcolega/:id" element={<EditColega />}></Route>

        <Route exact path="/citas" element={<Citas />}></Route>
        <Route exact path="/citas/:id" element={<Citas />}></Route>

        <Route exact path="/notificaciones" element={<Notificaciones />}></Route>

        <Route exact path="/pendientes" element={<Pendientes />}></Route>

        <Route exact path="/administracion" element={<Administracion />}></Route>

      </Routes>
    </BrowserRouter>
  );
  */


  // Entorno Web

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />}></Route>
          <Route exact path="/AddUsuarioExpressPx" element={<AddUsuariosExpress />}></Route>

          <Route exact path="/calendario" element={
            <PrivateRoute>
              <Calendario />
            </PrivateRoute>
          }></Route>

          <Route exact path="/AddUsuarioLoginPx" element={
            <PrivateRoute>
              <AddUsuarios />
            </PrivateRoute>
          }></Route>

          <Route exact path="/pacientes" element={
            <PrivateRoute>
              <Pacientes />
            </PrivateRoute>
          }></Route>
          <Route exact path="/addpaciente" element={
            <PrivateRoute>
              <AddPaciente />
            </PrivateRoute>
          }></Route>
          <Route exact path="/addpacienteexpress" element={
            <PrivateRoute>
              <AddPacienteExpress />
            </PrivateRoute>
          }></Route>
          <Route exact path="/addpacienteexpressadmin" element={
            <PrivateRoute>
              <AddPacienteExpressAdmin />
            </PrivateRoute>
          }></Route>
          <Route exact path="/pacientes/:id" element={
            <PrivateRoute>
              <Pacientes />
            </PrivateRoute>
          }></Route>
          <Route exact path="/editpaciente/:id" element={
            <PrivateRoute>
              <EditPaciente />
            </PrivateRoute>
          }></Route>
          <Route exact path="/editpacienteadmin/:id" element={
            <PrivateRoute>
              <EditPacienteAdmin />
            </PrivateRoute>
          }></Route>

          <Route exact path="/rolpaciente/:id" element={
            <PrivateRoute>
              <RolPaciente />
            </PrivateRoute>
          }></Route>
          <Route exact path="/editrolpaciente/:id" element={
            <PrivateRoute>
              <EditRolPaciente />
            </PrivateRoute>
          }></Route>
          <Route exact path="/formularioCambiarContrasena" element={
            <PrivateRoute>
              <FormularioCambiarContrasena />
            </PrivateRoute>
          }>

          </Route>

          <Route exact path="/psicologo/:id" element={
            <PrivateRoute>
              <Psicologo />
            </PrivateRoute>
          }></Route>
          <Route exact path="/addpsicologo" element={
            <PrivateRoute>
              <AddPsicologo />
            </PrivateRoute>
          }></Route>
          <Route exact path="/editpsicologo/:id" element={
            <PrivateRoute>
              <EditPsicologo />
            </PrivateRoute>
          }></Route>
          <Route exact path="/editpsicologoadmin/:id" element={
            <PrivateRoute>
              <EditPsicologoAdmin />
            </PrivateRoute>
          }></Route>

          <Route exact path="/transferencias" element={
            <PrivateRoute>
              <Transferir />
            </PrivateRoute>
          }></Route>

          <Route exact path="/familiares" element={
            <PrivateRoute>
              <Familiares />
            </PrivateRoute>
          }></Route>
          <Route exact path="/familiares/:id" element={
            <PrivateRoute>
              <Familiares />
            </PrivateRoute>
          }></Route>
          <Route exact path="/addfamiliar" element={
            <PrivateRoute>
              <AddFamiliar />
            </PrivateRoute>
          }></Route>
          <Route exact path="/editfamiliar/:id" element={
            <PrivateRoute>
              <EditFamiliar />
            </PrivateRoute>
          }></Route>

          <Route exact path="/expedientes" element={
            <PrivateRoute>
              <Expedientes />
            </PrivateRoute>
          }></Route>
          <Route exact path="/expedientes/:id" element={
            <PrivateRoute>
              <Expedientes />
            </PrivateRoute>
          }></Route>
          <Route exact path="/consultarExpediente/:id/:txt" element={
            <PrivateRoute>
              <ConsultarExpediente />
            </PrivateRoute>
          }></Route>

          <Route exact path="/reporte/:id" element={
            <PrivateRoute>
              <Reporte />
            </PrivateRoute>
          }></Route>

          <Route exact path="/sesiones" element={
            <PrivateRoute>
              <Sesiones />
            </PrivateRoute>
          }></Route>
          <Route exact path="/sesiones/:id" element={
            <PrivateRoute>
              <Sesiones />
            </PrivateRoute>
          }></Route>
          <Route exact path="/redactarSesion/:id" element={
            <PrivateRoute>
              <RedacatarSesion />
            </PrivateRoute>
          }>
          </Route>
          <Route exact path="/consultarSesion/:id" element={
            <PrivateRoute>
              <ConsultarSesion />
            </PrivateRoute>
          }></Route>

          <Route exact path="/Glosario" element={
            <PrivateRoute>
              <Glosario />
            </PrivateRoute>
          }></Route>
          <Route exact path="/GlosarioAdd" element={
            <PrivateRoute>
              <GlosarioAdd />
            </PrivateRoute>
          }></Route>
          <Route excet path="/GlosarioEditar/:id" element={
            <PrivateRoute>
              <GlosarioEditar />
            </PrivateRoute>
          }></Route>

          <Route exact path="/canalizaciones" element={
            <PrivateRoute>
              <Canalizaciones />
            </PrivateRoute>
          }></Route>
          <Route exact path="/FormularioCanalizarPaciente" element={
            <PrivateRoute>
              <CanalizarPaciente />
            </PrivateRoute>
          }></Route>
          <Route exact path="/canalizaciones/:id" element={
            <PrivateRoute>
              <Canalizaciones />
            </PrivateRoute>
          }></Route>

          <Route exact path="/colegas" element={
            <PrivateRoute>
              <Colegas />
            </PrivateRoute>
          }></Route>
          <Route exact path="/addcolega" element={
            <PrivateRoute>
              <AddColega />
            </PrivateRoute>
          }></Route>
          <Route exact path="/editcolega/:id" element={
            <PrivateRoute>
              <EditColega />
            </PrivateRoute>
          }></Route>
          <Route exact path="/addcolegaadmin" element={
            <PrivateRoute>
              <AddColegaAdmin />
            </PrivateRoute>
          }></Route>
          <Route exact path="/editcolegaadmin/:id" element={
            <PrivateRoute>
              <EditColegaAdmin />
            </PrivateRoute>
          }></Route>

          <Route exact path="/citas" element={
            <PrivateRoute>
              <Citas />
            </PrivateRoute>
          }></Route>
          <Route exact path="/citas/:id" element={
            <PrivateRoute>
              <Citas />
            </PrivateRoute>
          }></Route>

          <Route exact path="/notificaciones" element={
            <PrivateRoute>
              <Notificaciones />
            </PrivateRoute>
          }></Route>

          <Route exact path="/recomendaciones" element={
            <PrivateRoute>
              <Recomendacion />
            </PrivateRoute>
          }></Route>
          <Route exact path="/addRecomendaciones/:id" element={
            <PrivateRoute>
              <AddRecomendacion />
            </PrivateRoute>
          }></Route>

          <Route exact path="/pendientes" element={
            <PrivateRoute>
              <Pendientes />
            </PrivateRoute>
          }></Route>

          <Route exact path="/administracion" element={
            <PrivateRoute>
              <Administracion />
            </PrivateRoute>
          }></Route>
        </Routes>
      </Router>
    </AuthProvider>
  );

}

export default App;



