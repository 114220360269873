
import axios from "axios";

const USU_ID_API_BASE_URL = "/server/usuario";
const USUARIO_API_BASE_URL = "/server/usuarios";
const EDIT_USUARIO_API_BASE_URL = "/server/usuariosEdit";
const DELETE_USUARIO_API_BASE_URL = "server/eliminarUsuario";

// const USU_ID_API_BASE_URL = "http://localhost:3002/usuario";
// const USUARIO_API_BASE_URL = "http://localhost:3002/usuarios";
// const EDIT_USUARIO_API_BASE_URL = "http://localhost:3002/usuariosEdit";
// const DELETE_USUARIO_API_BASE_URL = "http://localhost:3002/eliminarUsuario";


class UsuariosService {

    /*Regresa usuario que tiene correo y contras que recibe por parametro*/
    async getLogin(correo, contrasena) {
        const response = await axios.get(`${USUARIO_API_BASE_URL}Login?correo=${correo}&contrasena=${contrasena}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('US getLogin: ', error);
            });
        const usuarios = response.data;
        return usuarios;
    }

    // obtener usuario por ID (individual)
    async getUsuario(id_usuario) {
        const response = await axios.get(`${USU_ID_API_BASE_URL}?id_usuario=${id_usuario}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('US getUsuario: ', error);
            })
        const usuario = response.data;
        return usuario;
    }

    async createUsuario(usuario) {

        return await axios.post(USUARIO_API_BASE_URL, usuario);
        
    }


    // Usar ruta fija (EDIT_USUARIO_API_BASE_URL) cuando se envía un obj de varios parámetros, como 'user'
    async editarUsuario(user) {

        return await axios.put(EDIT_USUARIO_API_BASE_URL, user);

    }



    async eliminarUsuario(id_usuario) {

        return await axios.delete(`${DELETE_USUARIO_API_BASE_URL}?id_usuario=${id_usuario}`);

    }

    

    async getUltimoUsuario() {
        const response = await axios.get(USUARIO_API_BASE_URL);
        const usuarios = response.data;
        const ultimoUsuario = usuarios.slice(-1)[0];
        return ultimoUsuario;
    }


}

export default new UsuariosService();