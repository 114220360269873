
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactDOMServer from "react-dom/server";
import moment from 'moment';

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';

import PacientesService from "../../services/PacientesService";
import CanalizarService from "../../services/CanalizarService";
import InformacionPacientes from "../../services/InformacionPacientes";
import CitasService from "../../services/CitasService";
import PsicologoService from "../../services/PsicologoService";
import TransferirService from "../../services/TransferirService";
import FormularioTransferirPaciente from "../Transferir/FormularioTransferirPaciente"

import "../../styles/Pacientes.css";

import img_psy from "../../img/psy_engrane.svg";
import img_exped from "../../img/folder_shared.svg";
import img_groups from "../../img/groups.svg";
import img_edit from "../../img/edit_note.svg";
import img_delete from "../../img/delete.svg";
import img_done from "../../img/done.svg";
import img_search from "../../img/search_pax.svg";
import img_person from "../../img/person_add.svg";
import img_send from "../../img/read_more_black.svg";
import img_activar from "../../img/interactive_space.svg";
import img_clear from "../../img/clear_all.svg";



const Pacientes = () => {

    const [pacientes, setPacientes] = useState([]);
    const [psicologos, setPsicologos] = useState([]);
    const [informacion, setInformacion] = useState([]);
    const [selectedValue, setSelectedValue] = useState(''); // Estado para el valor del select
    const [inputValue, setInputValue] = useState('');
    const [fotografia, setFotografia] = useState('');
    const [sexo, setSexo] = useState('');
    const [edoCivil, setEdoCivil] = useState('');
    const [edo, setEstado] = useState('');
    const [pais, setPais] = useState('');
    const [foto, setFoto] = useState('');  // bandera, hay o no foto en la DB
    const [formatoFechaPersonal, setFormatoFechaPersonal] = useState('');
    const [verPacInactivo, setVerPacInactivo] = useState('');
    const [filtroPsic, setFiltroPsic] = useState([]);
    const [pacEstatusAct, setPacEstatusAct] = useState('activo');
    const [pacEstatusIn, setPacEstatusIn] = useState('inactivo');


    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);
    const routeParams = useParams();

    function refreshPage() {
        window.location.reload(false);
    }

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    })

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }

    function mostrarError(mensaje) {
        Swal.fire({
            title: '¡Error!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    const navigate = useNavigate();

    const getData = async () => {

        let cont1 = 0;
        let cont2 = 0;

        const data = await PacientesService.getPacientes(usuario.id_psicologo);

        for (let i = 0; i < data.length; i++) {
            if (data[i].estatus === "A") {
                cont1++;
            } else if (data[i].estatus === "I") {
                cont2++;
            }
        }

        if (cont1 === 0) setPacEstatusAct("noActivo");   // no existen pacientes activos
        if (cont2 === 0) setPacEstatusIn("NoInactivo");  // no existen pacientes inactivos

        // estatus de canalización, canalización Activa o Inactiva del paciente, recorre arreglo de pacientes
        for (let i = 0; i < data.length; i++) {
            const estatusCan = await CanalizarService.getEstatusCanalizacionesPaciente(data[i].id_paciente);    // obtener estatusCan del pac

            let cont = 0;

            //regresa: A || I || ""
            if (estatusCan.length > 0) { // existe canalización
                if (estatusCan.length > 1) { // tiene varias canalizaciones
                    cont = estatusCan.length;
                    for (let x = 0; x < cont; x++) {

                        if (estatusCan[x].estatus === "A") {
                            data[i].estatusCanalizacion = estatusCan[x].estatus;
                        }
                    }

                } else {    //solo tiene una canalización

                    if (estatusCan[0].estatus === "A") {
                        data[i].estatusCanalizacion = estatusCan[0].estatus;
                    }
                }

            } else { //no tiene canalización
                data[i].estatusCanalizacion = "";
            }
        }

        setPacientes(data);

        const data2 = await PsicologoService.getPsicologos();
        setPsicologos(data2);
        filtroPsicologo(data2);

    }


    useEffect(() => {
        getData();
    }, [])


    // formato personal fecha para presentar

    const cargarInformacion = async (id_paciente) => {

        const data = await PacientesService.getInfoPaciente(id_paciente);

        setSexo(InformacionPacientes.sexoPaciente(data[0].sexo));
        setEdoCivil(InformacionPacientes.estadoCivil(data[0].edo_civil));
        setEstado(InformacionPacientes.estadosRepublica(data[0].edo));
        setPais(InformacionPacientes.paisesPaciente(data[0].pais));

        // obtener foto convertida
        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64
        if (cont.length < 1) {
            setFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setFoto("1");
        }

        setInformacion(data[0]);
        setFotografia(data[0].fotografia.data);  // get base64 de DB
        setInputValue(data[0].nombre + ' ' + data[0].ap1 + ' ' + data[0].ap2);

        // formato personal fecha para presentar
        let fe_in = data[0].fecha_ingreso;
        fe_in = fe_in.split("-");
        let fe_ingreso = fe_in[2] + "-" + fe_in[1] + "-" + fe_in[0];
        setFormatoFechaPersonal(fe_ingreso);

    }


    // filtro psicologos, omitir en select psicologo en sesión
    function filtroPsicologo(data2) {

        let arr = [];

        for (let i = 0; i < data2.length; i++) {
            if (data2[i].id_psicologo !== usuario.id_psicologo) {
                arr.push(data2[i]);
            }
        }

        setFiltroPsic(arr)
    }


    const transferirPaciente = async (id_paciente) => {

        const paciente = await PacientesService.getInfoPaciente(id_paciente);
        const fecha = moment().format("YYYY-MM-DD");
        const formularioHtml = ReactDOMServer.renderToString(
            <FormularioTransferirPaciente paciente={paciente} psicologos={filtroPsic} fecha={fecha} />
        );
        Swal.fire({
            title: "Transferir paciente",
            html: formularioHtml,
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: "Guardar",
            cancelButtonText: "Cancelar",
            preConfirm: () => {
                const id_pac = id_paciente;
                const id_psic = document.getElementById("select-idPsic-transfer").value;  // psic al que se transfiere el pac
                const motivo = document.getElementById("input-motivo").value;
                const fecha = document.getElementById("input-fecha").value;
                return { motivo, fecha, id_pac, id_psic };
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const datos = result.value;
                putTransferirPaciente(datos);
            } else if (result.dismiss === 'cancel') {
                navigate('/pacientes');       // entorno web
            }
        });
    }


    const putTransferirPaciente = async (datos) => {

        const hoy = new Date();     // fecha actual de la Pc
        var anioHoy = hoy.getFullYear();    // Returns the year
        var mesHoy = hoy.getMonth() + 1;  // Returns the month (from 0-11)
        var diaHoy = hoy.getDate();     // Returns the day of the month (from 1-31)

        const fecha = anioHoy + "-" + mesHoy + "-" + diaHoy;

        let data = {
            motivo: datos.motivo,
            fecha: datos.fecha,
            fk_paciente: parseInt(datos.id_pac),
            fk_psicologo: parseInt(usuario.id_psicologo),
            fk_psicologo_recibe: parseInt(datos.id_psic)
        }

        if (data.fk_psicologo_recibe === 0 || data.fk_psicologo_recibe === undefined) {

            mostrarError("Selecciona un psicólogo.");

        } else if (data.fk_psicologo_recibe === usuario.id_psicologo) {

            mostrarError("Estás seleccionando al psicólogo que está en sesión.");

        } else {

            if (data.motivo === "" || data.motivo === undefined) {
                mostrarError("Completa el campo: Motivo");

            } else {

                // crear registro de transferencia
                await TransferirService.createTransfer(data);

                // eliminar citas posteriores al día actual
                const responseCita = await CitasService.eliminarCitasPxInactivo(fecha, data.fk_paciente);

                if (responseCita.status === 200) {

                    const responsePac = await PacientesService.tranferirPaciente(data.fk_psicologo_recibe, data.fk_paciente);

                    if (responsePac.affectedRows > 0) {  // si se afectó una fila

                        Toast.fire("El paciente se transfirió correctamente. La citas posteriores se han eliminado.", "", "success")
                            .then((result) => {
                                if (result.isConfirmed) {
                                    navigate('/pacientes');        // web
                                    refreshPage();
                                }
                            }).then(function () {
                                navigate('/pacientes');       // web
                                refreshPage();
                            });
                    }
                }

            }
        }
    }


    // Pacientes activos
    const DisplayData = pacientes.map((info, index) => {
        return (
            <div key={index}>
                {
                    info.estatus === "A" ? (
                        <table id="displayData_px">
                            <tbody>
                                <tr className="table-line-bottom">
                                    <td id="td_1">
                                        <label id="lbl_nom_px">
                                            <button onClick={() => {
                                                cargarInformacion(info.id_paciente)
                                            }} id="btn_liga_px">
                                                {info.nombre} {info.ap1} {info.ap2}
                                            </button>
                                        </label>
                                    </td>
                                    <td id="td_2">
                                        <a href={`#/sesiones/${info.id_paciente}`}>
                                            <img id="img_psy" src={img_psy} alt="psy_px"></img>
                                        </a>
                                    </td>

                                    {/*     -------- ruta local --------
                                        <td id="td_3">
                                            <a href={`/sesiones/${info.id_paciente}`}>
                                                <img id="img_psy" src={img_psy} alt="psy_px"></img>
                                            </a>
                                        </td>
                                        <td id="td_4">
                                            <a href={`/expedientes/${info.id_paciente}`}>
                                                <img id="img_exped" src={img_exped} alt="img_exped"></img>
                                            </a>
                                        </td>
                                        <td id="td_5">
                                            <a href={`/citas/${info.id_paciente}`}>
                                                <img id="img_groups" src={img_groups} alt="img_groups"></img>
                                            </a>
                                        </td>
                                        */}

                                    {/*   web   */}
                                    <td id="td_3">
                                        <a href={`#/expedientes/${info.id_paciente}`}>
                                            <img id="img_exped" src={img_exped} alt="img_exped"></img>
                                        </a>
                                    </td>
                                    <td id="td_4">
                                        <a href={`#/citas/${info.id_paciente}`}>
                                            <img id="img_groups" src={img_groups} alt="img_groups"></img>
                                        </a>
                                    </td>
                                    <td id="td_5">
                                        <button id="btn-transfer" onClick={() => transferirPaciente(info.id_paciente)}>
                                            <img src={img_send} alt="img_transfer"></img>
                                        </button>
                                    </td>

                                    <td id="td_6">
                                        {
                                            info.estatusCanalizacion === "A" ? (
                                                //  --------- web -------------
                                                <div>
                                                    <a href={`#/canalizaciones/${info.id_paciente}`}>
                                                        <img id="img_done" src={img_done} alt="img_done"></img>
                                                    </a>
                                                </div>
                                            ) : (<></>)
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    ) : (<></>)
                }
            </div>
        )
    })


    // Pacientes inactivos
    const DisplayData2 = pacientes.map((info) => {
        return (
            <div>
                {
                    info.estatus === "I" ? (
                        <table id="displayData_px">
                            <tbody>
                                <tr className="table-line-bottom">
                                    <td id="td_1">
                                        <label id="lbl_nom_px">
                                            <button onClick={() => {
                                                cargarInformacion(info.id_paciente)
                                            }} id="btn_liga_px">
                                                {info.nombre} {info.ap1} {info.ap2}
                                            </button>
                                        </label>
                                    </td>

                                    {/*     -------- ruta local --------
                                        <td id="td_3">
                                            <a href={`/sesiones/${info.id_paciente}`}>
                                                <img id="img_psy" src={img_psy} alt="psy_px"></img>
                                            </a>
                                        </td>
                                        <td id="td_4">
                                            <a href={`/expedientes/${info.id_paciente}`}>
                                                <img id="img_exped" src={img_exped} alt="img_exped"></img>
                                            </a>
                                        </td>
                                        <td id="td_5">
                                            <a href={`/citas/${info.id_paciente}`}>
                                                <img id="img_groups" src={img_groups} alt="img_groups"></img>
                                            </a>
                                        </td>
                                        */}

                                    {/*   web   */}
                                    <td id="td_3">
                                        <a href={`#/sesiones/${info.id_paciente}`}>
                                            <img id="img_psy" src={img_psy} alt="psy_px"></img>
                                        </a>
                                    </td>
                                    <td id="td_4">
                                        <a href={`#/expedientes/${info.id_paciente}`}>
                                            <img id="img_exped" src={img_exped} alt="img_exped"></img>
                                        </a>
                                    </td>
                                    <td id="td_5">
                                        <a href={`#/citas/${info.id_paciente}`}>
                                            <img id="img_groups" src={img_groups} alt="img_groups"></img>
                                        </a>
                                    </td>

                                    <td id="td_6">
                                        {
                                            info.estatusCanalizacion === "A" ? (
                                                //  --------- web -------------
                                                <div>
                                                    <a href={`#/canalizaciones/${info.id_paciente}`}>
                                                        <img id="img_done" src={img_done} alt="img_done"></img>
                                                    </a>
                                                </div>
                                            ) : (<></>)
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    ) : (<></>)
                }
            </div>
        )
    })


    const activarPaciente = async () => {
        Swal.fire({
            title: "Activar paciente",
            text: "¿Esta seguro de activar al paciente?",
            showCancelButton: true,
            confirmButtonText: "Aceptar",
        }).then((result) => {
            if (result.isConfirmed) {
                activadoLogicoPaciente(informacion.id_paciente);
            }
        });
    }

    const activadoLogicoPaciente = async (id_paciente) => {
        const respuesta = await PacientesService.activarPacienteL(id_paciente);
        if (respuesta.status === 200) {
            Toast.fire("El paciente ha sido activado.", "", "success").then((result) => {
                if (result.isConfirmed) {
                    refreshPage();
                }
            });
        } else {
            Toast.fire("Hubo un problema al activar al paciente.", "", "error");
        }
    }


    const eliminarPaciente = async () => {
        if (informacion.id_paciente === undefined) {
            Toast.fire("Primero debes seleccionar un paciente", "", "error");
        } else {
            Swal.fire({
                title: "Paciente inactivo",
                text: "¿Esta seguro de inactivar al paciente?",
                showCancelButton: true,
                showConfirmButton: false,
                showDenyButton: true,
                denyButtonText: "Inactivar",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.isDenied) {
                    eliminadoLogicoPaciente(informacion.id_paciente);
                }
            });
        }
    }

    const eliminadoLogicoPaciente = async (id_paciente) => {

        const hoy = new Date();     // fecha actual de la Pc
        var anioHoy = hoy.getFullYear();    // Returns the year
        var mesHoy = hoy.getMonth() + 1;  // Returns the month (from 0-11)
        var diaHoy = hoy.getDate();     // Returns the day of the month (from 1-31)

        const fecha = anioHoy + "-" + mesHoy + "-" + diaHoy;

        if (id_paciente !== "" && fecha !== "") {

            const respuesta = await PacientesService.eliminarPacienteL(id_paciente);
            if (respuesta.status === 200) {

                const response = await CitasService.eliminarCitasPxInactivo(fecha, id_paciente);
                if (response.status === 200) {
                    Toast.fire("El paciente ha sido inactivado. Citas previas eliminadas.", "", "success").then((result) => {
                        if (result.isConfirmed) {
                            refreshPage();
                        }
                    });

                } else {
                    mostrarError("Hubo un problema al eliminar las citas del paciente.");
                }

            } else {
                mostrarError("Hubo un problema al inactivar al paciente.");
            }

        } else {
            mostrarError("Hubo un problema en el eliminado lógico del paciente.");
        }
    }

    const editarPaciente = () => {
        // navigate(`/editpaciente/${informacion.id_paciente}`);   //local
        navigate(`/editpaciente/${informacion.id_paciente}`);  //web
    }

    const agregarPaciente = () => {
        // navigate('/addpaciente');   //local
        navigate('/addpaciente');   //web
    }

    const handlePacInact = (event) => {
        setVerPacInactivo(event.target.value);
    }

    // Buscador de pacientes
    const buscar = async (event) => {
        setInputValue(event.target.value);
        const data = await PacientesService.getPacientesBuscador(event.target.value, usuario.id_psicologo);
        setPacientes(data);
    }

    // const buscarFiltro = async (event) => {
    //     if (event.target.value === "") {

    //         const data = await PacientesService.getPacientes(usuario.id_psicologo);

    //         for (let i = 0; i < data.length; i++) {
    //             const estatusCan = await CanalizarService.getEstatusCanalizacionesPaciente(data[i].id_paciente);
    //             if (estatusCan.length !== 0) {
    //                 if (estatusCan.length > 1) {
    //                     for (let x = 0; x < estatusCan.length; x++) {
    //                         if (estatusCan[x].estatus === "A") {
    //                             data[x].estatusCanalizacion = estatusCan[x].estatus;
    //                         } else if (estatusCan[x].estatus === "I") {
    //                             data[x].estatusCanalizacion = estatusCan[x].estatus;
    //                         }
    //                     }
    //                 } else {
    //                     if (estatusCan[0].estatus === "A") {
    //                         data[0].estatusCanalizacion = estatusCan[0].estatus;
    //                     } else if (estatusCan[0].estatus === "I") {
    //                         data[0].estatusCanalizacion = estatusCan[0].estatus;
    //                     }
    //                 }
    //             } else {
    //                 data[i].estatusCanalizacion = "";
    //             }
    //         }
    //         setPacientes(data);

    //     } else {

    //         setSelectedValue(event.target.value);

    //         const data = await PacientesService.getPacientesEstatus(event.target.value);

    //         for (let i = 0; i < data.length; i++) {
    //             const estatusCan = await CanalizarService.getEstatusCanalizacionesPaciente(data[i].id_paciente);
    //             if (estatusCan.length !== 0) {
    //                 if (estatusCan.length > 1) {
    //                     for (let x = 0; x < estatusCan.length; x++) {
    //                         if (estatusCan[x].estatus === "A") {
    //                             data[x].estatusCanalizacion = estatusCan[x].estatus;
    //                         } else if (estatusCan[x].estatus === "I") {
    //                             data[x].estatusCanalizacion = estatusCan[x].estatus;
    //                         }
    //                     }
    //                 } else {
    //                     if (estatusCan[0].estatus === "A") {
    //                         data[0].estatusCanalizacion = estatusCan[0].estatus;
    //                     } else if (estatusCan[0].estatus === "I") {
    //                         data[0].estatusCanalizacion = estatusCan[0].estatus;
    //                     }
    //                 }

    //             } else {
    //                 data[i].estatusCanalizacion = "";
    //             }
    //         }
    //         setPacientes(data);
    //     }
    // }

    const limpiarFiltro = async (event) => {

        setInputValue("");
        setSelectedValue("");

        const data = await PacientesService.getPacientes(usuario.id_psicologo);

        for (let i = 0; i < data.length; i++) {
            const estatusCan = await CanalizarService.getEstatusCanalizacionesPaciente(data[i].id_paciente);
            if (estatusCan.length !== 0) {
                if (estatusCan.length > 1) {
                    for (let x = 0; x < estatusCan.length; x++) {
                        if (estatusCan[x].estatus === "A") {
                            data[x].estatusCanalizacion = estatusCan[x].estatus;
                        } else if (estatusCan[x].estatus === "I") {
                            data[x].estatusCanalizacion = estatusCan[x].estatus;
                        }
                    }
                } else {
                    if (estatusCan[0].estatus === "A") {
                        data[0].estatusCanalizacion = estatusCan[0].estatus;
                    } else if (estatusCan[0].estatus === "I") {
                        data[0].estatusCanalizacion = estatusCan[0].estatus;
                    }
                }

            } else {
                data[i].estatusCanalizacion = "";
            }
        }
        setPacientes(data);

    }


    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }

    return (
        <Layout>
            <Content>
                <div className="px_div_1">

                    <div className="px_div_2">
                        <div className="px_sesion">
                            <label id="lbl-enSesion">{tipoUsuario()} &nbsp; </label>
                            <label>{usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_px">
                            <label>Pacientes</label>
                        </div>
                    </div>

                    <div className="px_buscador">

                        <div className="div_No_exp_buscar_px">
                            <div className="input_buscar_px">
                                <input type="search" placeholder="Buscar paciente..." id="Buscador" value={inputValue} onChange={buscar} />
                                <img src={img_search} alt="buscar" id="img_lupa"></img>
                            </div>
                        </div>

                        <div className="px_filtro">
                            <button id="btn-limpiar-filtro" onClick={limpiarFiltro}>
                                <img src={img_clear} alt="img_clear" />
                                Limpiar
                            </button>
                        </div>

                        <div className="div-verPacInact">
                            <div className="div-verPacInac-label">
                                <label>Consultar pacientes inactivos:</label>
                            </div>
                            <div className="div-verPacInac-radio">
                                <div id="id-verPacInac-No">
                                    <label>No</label>
                                    <input type="radio" name="pac_inac" id="pac_inac" value="no" onChange={handlePacInact} defaultChecked></input>
                                </div>
                                <div id="id-verPacInac-No">
                                    <label>Sí</label>
                                    <input type="radio" name="pac_inac" id="pac_inac" value="si" onChange={handlePacInact}></input>
                                </div>
                            </div>
                        </div>

                        <div className="px_nuevo">
                            <button id="px_btn_nuevo" onClick={agregarPaciente}>
                                <img src={img_person} alt="img_person" />
                                Paciente
                            </button>
                        </div>

                    </div>

                    <div className="px_div_3">
                        <div className="div_px_lista">
                            <div className="div_tablas_th_px">
                                <div className="div_tablas_th_px-a">
                                    <div className="div-pacActivos-px">
                                        <label>Pacientes activos:</label>
                                    </div>
                                    <div className="div_tablas_th_px-b">
                                        <table id="encabezado_px">
                                            <tbody>
                                                <tr className="tr-encabezado-px">
                                                    <th>Nombre</th>
                                                    <th>Sesiones</th>
                                                    <th>Expediente</th>
                                                    <th>Citas</th>
                                                    <th>Transferir</th>
                                                    <th>Canalización activa</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="div_tablas_th_px-c">
                                        {
                                            pacEstatusAct === "activo" ? (
                                                DisplayData
                                            ) : (
                                                pacEstatusAct === "noActivo" ? (
                                                    <label> No existen pacientes activos en el sistema </label>
                                                ) : (<></>)
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="div_tablas_th_px-a">
                                    {
                                        verPacInactivo === "si" ? (
                                            <div>
                                                <div className="div-pacInactivos-px">
                                                    <label>Pacientes inactivos:</label>
                                                </div>
                                                <div className="div_tablas_th_px-b">
                                                    <table id="encabezado_px">
                                                        <tbody>
                                                            <tr className="tr-encabezado-Inpx">
                                                                <th>Nombre</th>
                                                                <th>Sesiones</th>
                                                                <th>Expediente</th>
                                                                <th>Citas</th>
                                                                <th>Canalización activa</th>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="div_tablas_th_px-c">
                                                    <table id="displayData_px">
                                                        <tbody>
                                                            {
                                                                pacEstatusIn === "inactivo" ? (
                                                                    DisplayData2
                                                                ) : (
                                                                    pacEstatusIn === "NoInactivo" ? (
                                                                        <tr>
                                                                            <td>
                                                                                <label> No existen pacientes inactivos en el sistema </label>
                                                                            </td>
                                                                        </tr>
                                                                    ) : (<></>)
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="px_tabla">
                            {informacion.length === 0 ? (
                                <div className="div-edit-delete-px">
                                    <div id="edit_delete">
                                        <div id="px_h3">
                                            <label id="lbl_datos_px">Paciente</label>
                                        </div>
                                    </div>
                                    <div className="div-selec-px">
                                        <label id="label-selec-px-px"> Selecciona un paciente de la lista... </label>
                                    </div>
                                </div>
                            ) : (
                                informacion.estatus === "A" ? (
                                    <div className="px_seleccionado_tabla">
                                        <div id="edit_delete">
                                            <div id="px_h3">
                                                <label id="lbl_datos_px">Paciente</label>
                                            </div>
                                            <div className="div-px-toolTip">
                                                <div id="container-toolTip-editar-px">
                                                    <span className="lbl-toolTip-editar-px">
                                                        Editar
                                                    </span>
                                                    <img src={img_edit} alt="edit" id="img_edit" onClick={editarPaciente} />
                                                </div>
                                                <div id="container-toolTip-delete-px">
                                                    <span className="lbl-toolTip-delete-px">
                                                        Eliminar
                                                    </span>
                                                    <img src={img_delete} alt="delete" id="img_delete" onClick={eliminarPaciente} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div-avatar-nombre-px">
                                            <div className="div-avatarPx">
                                                {
                                                    foto === "1" ? (
                                                        fotografia === "" ? (
                                                            <div id="img_avatar_px">
                                                                <label> Cargando imagen...</label>
                                                            </div>
                                                        ) : (
                                                            <div id="img_avatar_px">
                                                                <img src={fotografia} alt="base64"></img>
                                                            </div>
                                                        )
                                                    ) : (<div></div>)
                                                }
                                            </div>
                                            <div className="div-nombrePx">
                                                <label id="lbl-paciente">
                                                    {informacion.nombre} {informacion.ap1} {informacion.ap2}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="div-table_px_seleccionado">
                                            <table id="table_px_seleccionado">
                                                <tbody>
                                                    <tr className="padding-row">
                                                        <td><label className="label-edad" htmlFor="lbl-edad">Ingreso:</label></td>
                                                        <td><label id="lbl-edad">{formatoFechaPersonal}</label></td>
                                                    </tr>
                                                    <tr className="padding-row">
                                                        <td><label className="label-edad" htmlFor="lbl-edad">Edad:</label></td>
                                                        <td><label id="lbl-edad">{informacion.edad} años</label></td>
                                                    </tr>
                                                    <tr className="padding-row">
                                                        <td><label className="label-sexo" htmlFor="lbl-sexo">Sexo:</label></td>
                                                        <td><label id="lbl-sexo">{sexo}</label></td>
                                                    </tr>
                                                    <tr className="padding-row">
                                                        <td><label className="label-escolaridad" htmlFor="lbl-escolaridad">Escolaridad:</label></td>
                                                        <td><label id="lbl-escolaridad">{informacion.escolaridad}</label></td>
                                                    </tr>
                                                    <tr className="padding-row">
                                                        <td><label className="label-profesion" htmlFor="lbl-profesion">Profesion:</label></td>
                                                        <td><label id="lbl-profesion">{informacion.profesion}</label></td>
                                                    </tr>
                                                    <tr className="padding-row">
                                                        <td><label className="label-trabajo" htmlFor="lbl-trabajo">Trabajo actual:</label></td>
                                                        <td><label id="lbl-trabajo">{informacion.trabajo_actual}</label></td>
                                                    </tr>
                                                    <tr className="padding-row">
                                                        <td><label className="label-pref_sex" htmlFor="lbl-pref_sex">Preferencia sexual:</label></td>
                                                        <td><label id="lbl-pref_sex">{informacion.pref_sexual}</label></td>
                                                    </tr>
                                                    <tr className="padding-row">
                                                        <td><label className="label-edo-civil" htmlFor="lbl-edo-civil">Estado civil:</label></td>
                                                        <td><label id="lbl-edo-civil">{edoCivil}</label></td>
                                                    </tr>
                                                    <tr className="padding-row">
                                                        <td><label className="label-tel" htmlFor="lbl-tel">Teléfono:</label></td>
                                                        <td><label id="lbl-tel">{informacion.tel}</label></td>
                                                    </tr>
                                                    <tr className="padding-row">
                                                        <td><label className="label-correo" htmlFor="lbl-correo">Correo:</label></td>
                                                        <td><label id="lbl-correo">{informacion.correo}</label></td>
                                                    </tr>
                                                    <tr className="padding-row">
                                                        <td><label className="label-domicilio" htmlFor="lbl-domicilio">Domicilio:</label></td>
                                                        <td><label id="lbl-domicilio">{informacion.domicilio}</label></td>
                                                    </tr>
                                                    <tr className="padding-row">
                                                        <td><label className="label-cp" htmlFor="lbl-cp">Código Postal:</label></td>
                                                        <td><label id="lbl-cp">{informacion.cod_pos}</label></td>
                                                    </tr>
                                                    <tr className="padding-row">
                                                        <td><label className="label-ciudad" htmlFor="lbl-ciudad">Ciudad:</label></td>
                                                        <td><label id="lbl-ciudad">{informacion.ciudad}</label></td>
                                                    </tr>
                                                    <tr className="padding-row">
                                                        <td><label className="label-edo" htmlFor="lbl-edo">Estado:</label></td>
                                                        <td><label id="lbl-edo">{edo}</label></td>
                                                    </tr>
                                                    <tr className="padding-row">
                                                        <td><label className="label-pais" htmlFor="lbl-pais">País:</label></td>
                                                        <td><label id="lbl-pais">{pais}</label></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                ) : (
                                    informacion.estatus === "I" ? (
                                        <div className="px_seleccionado_tabla">
                                            <div id="edit_delete-Inactivo">
                                                <div id="px_h3">
                                                    <label id="lbl_datos_px">Paciente</label>
                                                </div>
                                                <div className="div-px-toolTip">
                                                    <div id="container-toolTip-activar-px">
                                                        <span className="lbl-toolTip-activar-px">
                                                            Activar
                                                        </span>
                                                        <img src={img_activar} alt="activar" id="img_activar" onClick={activarPaciente} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="div-avatar-px">
                                                {
                                                    foto === "1" ? (
                                                        fotografia === "" ? (
                                                            <div id="img_avatar_px">
                                                                <label> Cargando imagen...</label>
                                                            </div>
                                                        ) : (
                                                            <div id="img_avatar_px">
                                                                <img src={fotografia} alt="base64"></img>
                                                            </div>
                                                        )
                                                    ) : (<div></div>)
                                                }
                                            </div>
                                            <div className="div-px_seleccionado-inactivo">
                                                <table id="table_px_seleccionado-inactivo">
                                                    <tbody>
                                                        <tr>
                                                            <td id="td-paciente" colSpan="2"><label id="lbl-paciente">{informacion.nombre} {informacion.ap1} {informacion.ap2}</label></td>
                                                        </tr>
                                                        <tr>
                                                            <td><label className="label-familiar" htmlFor="lbl-familiar">Familiares:</label></td>
                                                            <td>
                                                                {/*   local   
                                                                <label id="lbl-familiar">
                                                                    <a href="/familiares">Ver</a>
                                                                </label>*/}

                                                                {/*   web   */}
                                                                <label id="lbl-familiar">
                                                                    <a href="#/familiares">Ver</a>
                                                                </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label className="label-edad" htmlFor="lbl-edad">Edad:</label></td>
                                                            <td><label id="lbl-edad">{informacion.edad}</label></td>
                                                        </tr>
                                                        <tr>
                                                            <td><label className="label-sexo" htmlFor="lbl-sexo">Sexo:</label></td>
                                                            <td><label id="lbl-sexo">{sexo}</label></td>
                                                        </tr>
                                                        <tr>
                                                            <td><label className="label-escolaridad" htmlFor="lbl-escolaridad">Escolaridad:</label></td>
                                                            <td><label id="lbl-escolaridad">{informacion.escolaridad}</label></td>
                                                        </tr>
                                                        <tr>
                                                            <td><label className="label-profesion" htmlFor="lbl-profesion">Profesion:</label></td>
                                                            <td><label id="lbl-profesion">{informacion.profesion}</label></td>
                                                        </tr>
                                                        <tr>
                                                            <td><label className="label-trabajo" htmlFor="lbl-trabajo">Trabajo actual:</label></td>
                                                            <td><label id="lbl-trabajo">{informacion.trabajo_actual}</label></td>
                                                        </tr>
                                                        <tr>
                                                            <td><label className="label-pref_sex" htmlFor="lbl-pref_sex">Preferencia sexual:</label></td>
                                                            <td><label id="lbl-pref_sex">{informacion.pref_sexual}</label></td>
                                                        </tr>
                                                        <tr>
                                                            <td><label className="label-edo-civil" htmlFor="lbl-edo-civil">Estado civil:</label></td>
                                                            <td><label id="lbl-edo-civil">{edoCivil}</label></td>
                                                        </tr>
                                                        <tr>
                                                            <td><label className="label-tel" htmlFor="lbl-tel">Teléfono:</label></td>
                                                            <td><label id="lbl-tel">{informacion.tel}</label></td>
                                                        </tr>
                                                        <tr>
                                                            <td><label className="label-correo" htmlFor="lbl-correo">Correo:</label></td>
                                                            <td><label id="lbl-correo">{informacion.correo}</label></td>
                                                        </tr>
                                                        <tr>
                                                            <td><label className="label-domicilio" htmlFor="lbl-domicilio">Domicilio:</label></td>
                                                            <td><label id="lbl-domicilio">{informacion.domicilio}</label></td>
                                                        </tr>
                                                        <tr>
                                                            <td><label className="label-cp" htmlFor="lbl-cp">Código Postal:</label></td>
                                                            <td><label id="lbl-cp">{informacion.cod_pos}</label></td>
                                                        </tr>
                                                        <tr>
                                                            <td><label className="label-ciudad" htmlFor="lbl-ciudad">Ciudad:</label></td>
                                                            <td><label id="lbl-ciudad">{informacion.ciudad}</label></td>
                                                        </tr>
                                                        <tr>
                                                            <td><label className="label-edo" htmlFor="lbl-edo">Estado:</label></td>
                                                            <td><label id="lbl-edo">{edo}</label></td>
                                                        </tr>
                                                        <tr>
                                                            <td><label className="label-pais" htmlFor="lbl-pais">País:</label></td>
                                                            <td><label id="lbl-pais">{pais}</label></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ) : (<></>)
                                )
                            )}
                        </div>
                    </div>
                </div>
            </Content>
        </Layout >
    );
};

export default Pacientes;
