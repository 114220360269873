
import axios from "axios";

const EXPEDIENTES_API_BASE_URL = "/server/expedientes";
const VEXPEDIENTES_API_BASE_URL = "/server/vexpedientes";
const EXPED_FILE_API_BASE_URL = "server/expedFile";

// const EXPEDIENTES_API_BASE_URL = "http://localhost:3002/expedientes";
// const EXPED_FILE_API_BASE_URL = "http://localhost:3002/expedFile";
// const VEXPEDIENTES_API_BASE_URL = "http://localhost:3002/vexpedientes";


class ExpedientesService {

    async getExpediente(id_paciente) {
        const response = await axios.get(`${VEXPEDIENTES_API_BASE_URL}Individual?id_paciente=${id_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('ES getExpediente: ', error);
            });
        const expediente = response.data;
        return expediente;
    }


    async getExpedientesTodos() {
        const response = await axios.get(`${VEXPEDIENTES_API_BASE_URL}Todos?`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('ES getExpedientesTodos: ', error);
            });
        const expediente = response.data;
        return expediente;
    }

    async editarExpediente(expediente) {
        return await axios.put(EXPEDIENTES_API_BASE_URL, expediente);
    }

    async createExpediente(expediente) {
        return await axios.post(EXPEDIENTES_API_BASE_URL, expediente);
    }

    async getUltimoExpediente() {
        const response = await axios.get(EXPEDIENTES_API_BASE_URL);
        const expedientes = response.data;
        const ultimoExpediente = expedientes.slice(-1)[0];
        return ultimoExpediente;
    }

    async subirArchivos(archivoExp) {
        const response = await axios.post(EXPED_FILE_API_BASE_URL, archivoExp)
          .then((response) => {
            console.log(response.data);
            return response;
          })
          .catch((error) => {
            console.log(error);
          });
          const archivos = response.data;
          return archivos;
      };

      async getArchivos(id_expediente) {
        const response = await axios.get(`${EXPEDIENTES_API_BASE_URL}Archivos?fk_expediente=${id_expediente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('ES getExpediente: ', error);
            });
        const archivos = response.data;
        return archivos;
    }

    async getArchivosPruebaPsicologica(id_expediente) {
        const response = await axios.get(`${EXPEDIENTES_API_BASE_URL}ArchivosPruebaPsicologica?fk_expediente=${id_expediente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('ES getArchivosPruebaPsicologica: ', error);
            });
        const archivos = response.data;
        return archivos;
    }

    async getArchivosSugerencias(id_expediente) {
        const response = await axios.get(`${EXPEDIENTES_API_BASE_URL}ArchivosSugerencias?fk_expediente=${id_expediente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('ES getArchivosSugerencias: ', error);
            });
        const archivos = response.data;
        return archivos;
    }

    async getArchivosHistorialMedico(id_expediente) {
        const response = await axios.get(`${EXPEDIENTES_API_BASE_URL}ArchivosHistorialMedico?fk_expediente=${id_expediente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('ES getArchivosHistorialMedico: ', error);
            });
        const archivos = response.data;
        return archivos;
    }

    async getArchivosExperiencias(id_expediente) {
        const response = await axios.get(`${EXPEDIENTES_API_BASE_URL}ArchivosExperiencias?fk_expediente=${id_expediente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('ES getArchivosExperiencias: ', error);
            });
        const archivos = response.data;
        return archivos;
    }

    async getArchivosFamiliograma(id_expediente) {
        const response = await axios.get(`${EXPEDIENTES_API_BASE_URL}ArchivosFamiliograma?fk_expediente=${id_expediente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('ES getArchivosFamiliograma: ', error);
            });
        const archivos = response.data;
        return archivos;
    }


    async getArchivosDiagnostico(id_expediente) {
        const response = await axios.get(`${EXPEDIENTES_API_BASE_URL}ArchivosDiagnostico?fk_expediente=${id_expediente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('ES getArchivosDiagnostico: ', error);
            });
        const archivos = response.data;
        return archivos;
    }
    

    async descargarArchivo(id_archivo) {
        const response = await axios.get(`${EXPEDIENTES_API_BASE_URL}DescargarArchivo?id_archivo=${id_archivo}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('ES getExpediente: ', error);
            });
        const archivos = response.data;
        return archivos;
    }


    eliminarArchivo(id_archivo) {

        return axios.delete(`${EXPEDIENTES_API_BASE_URL}EliminarArchivo?id_archivo=${id_archivo}`);

    }
}

export default new ExpedientesService();