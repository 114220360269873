
import axios from "axios";


const FAM_ES_PAC_API_BASE_URL = "/server/vFamEsPac";
const FAM_ESPAC_LISTA_API_BASE_URL = "/server/vFamEsPacLista";
const FAM_NO_ES_PAC_API_BASE_URL = "/server/vFamNoEsPac";
const FAM_NOES_PAC_LISTA_API_BASE_URL = "/server/vFamNoEsPacLista";
const FAMNOESPAC_BUSCADOR_API_BASE_URL = "/server/vFamNoEsPacBuscador";
const FAMILIARES_API_BASE_URL = "/server/familiares";
const FAM_EDIT_API_BASE_URL = "/server/familiarEdit";
const FAM_EDIT_NR_API_BASE_URL = "/server/famEditNoRelacion";
const FAM_NE_PAC_PSI_API_BASE_URL = "/server/vFamNoEsPacPsicologo";


// const FAM_ES_PAC_API_BASE_URL = "http://localhost:3002/vFamEsPac";
// const FAM_ESPAC_LISTA_API_BASE_URL = "http://localhost:3002/vFamEsPacLista";
// const FAM_NO_ES_PAC_API_BASE_URL = "http://localhost:3002/vFamNoEsPac";
// const FAM_NOES_PAC_LISTA_API_BASE_URL = "http://localhost:3002/vFamNoEsPacLista";
// const FAMNOESPAC_BUSCADOR_API_BASE_URL = "http://localhost:3002/vFamNoEsPacBuscador";
// const FAMILIARES_API_BASE_URL = "http://localhost:3002/familiares";
// const FAM_EDIT_API_BASE_URL = "http://localhost:3002/familiarEdit";
// const FAM_EDIT_NR_API_BASE_URL = "http://localhost:3002/famEditNoRelacion";
// const FAM_NE_PAC_PSI_API_BASE_URL = "http://localhost:3002/vFamNoEsPacPsicologo";


const Buffer = require('buffer/').Buffer;
const _ = require('lodash');        // _.isEmpty(familiar[i].f_fotografia.data  --- valida si un obj está vacío


class FamiliaresService {

    // obtener familiar por Id de table familiar
    async getFamiliarIndividual(id_familiar) {
        const response = await axios.get(`${FAMILIARES_API_BASE_URL}individual?id_familiar=${id_familiar}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamiliar familiaresindividual: ', error);
            });

        const familiar = response.data;

        //  convertir imagen blob a Uint8Array
        const archivoBase64 = Buffer.from(familiar[0].fotografia.data, 'binary');

        if (familiar[0].formatoFoto === "png") {
            // Uint8Array a String binario tipo PNG
            const base64String = `data:image/png;base64,${archivoBase64}`;
            // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
            const base64Data = base64String.split(',')[2];
            //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
            const imgBlob = `data:image/png;base64,${base64Data}`;
            familiar[0].fotografia.data = imgBlob;
        } else if (familiar[0].formatoFoto === "jpeg") {
            const base64String = `data:image/jpeg;base64,${archivoBase64}`;
            const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
            const imgBlob = `data:image/jpeg;base64,${base64Data}`;
            familiar[0].fotografia.data = imgBlob;
        } else if (familiar[0].formatoFoto === "jpg") {
            const base64String = `data:image/jpg;base64,${archivoBase64}`;
            const base64Data = base64String.split(',')[2];
            const imgBlob = `data:image/jpg;base64,${base64Data}`;
            familiar[0].fotografia.data = imgBlob;
        }

        return familiar;
    }


    // // obtener todos los familiares (no pac) de la Tabla Familiar
    async getFamiliares() {
        const response = await axios.get(FAMILIARES_API_BASE_URL)
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error('FS getFamiliares: ', error);
        })
        const familiar = response.data;
        return familiar;
    }


    // get Tabla familiar Buscador
    async getFamiliarBuscador(palabra) {
        const response = await axios.get(`${FAMILIARES_API_BASE_URL}buscador?palabra=${palabra}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('FS getFamiliaresBuscador: ', error);
            });
        const familiar = response.data;
        return familiar;
    }


    // obtener Familiares NO pacientes por psicólogo de la sesión
    async getFamNoEsPacPsicologo(id_psicologo) {
        const response = await axios.get(`${FAM_NE_PAC_PSI_API_BASE_URL}?id_psicologo=${id_psicologo}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamNoEsPacPsicologo: ', error);
            });

        const familiar = response.data;
        return familiar;
    }


    // obtener Familiares NO pacientes relacionados con un UN paciente
    async getFamiliarNoEsPaciente(fk_paciente) {
        const response = await axios.get(`${FAM_NO_ES_PAC_API_BASE_URL}?fk_paciente=${fk_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamiliarNoEsPaciente: ', error);
            });

        const familiar = response.data;

        if (familiar.length !== 0) {

            for (let i = 0; i < familiar.length; i++) {

                //  convertir imagen Familiar Foto tipo blob a Uint8Array
                const archivoBase64 = Buffer.from(familiar[i].f_fotografia.data, 'binary');

                if (familiar[i].f_formatoFoto === "png") {
                    // Uint8Array a String binario tipo PNG
                    const base64String = `data:image/png;base64,${archivoBase64}`;
                    // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
                    const base64Data = base64String.split(',')[2];
                    //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
                    const imgBlob = `data:image/png;base64,${base64Data}`;
                    familiar[i].f_fotografia.data = imgBlob;
                } else if (familiar[i].f_formatoFoto === "jpeg") {
                    const base64String = `data:image/jpeg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
                    const imgBlob = `data:image/jpeg;base64,${base64Data}`;
                    familiar[i].f_fotografia.data = imgBlob;
                } else if (familiar[i].f_formatoFoto === "jpg") {
                    const base64String = `data:image/jpg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/jpg;base64,${base64Data}`;
                    familiar[i].f_fotografia.data = imgBlob;
                }

                //  convertir imagen Paciente Foto tipo blob a Uint8Array
                const archivoBase64_b = Buffer.from(familiar[i].u_fotografia.data, 'binary');

                if (familiar[i].u_formatoFoto === "png") {
                    const base64String = `data:image/png;base64,${archivoBase64_b}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/png;base64,${base64Data}`;
                    familiar[i].u_fotografia.data = imgBlob;
                } else if (familiar[i].u_formatoFoto === "jpeg") {
                    const base64String = `data:image/jpeg;base64,${archivoBase64_b}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/jpeg;base64,${base64Data}`;
                    familiar[i].u_fotografia.data = imgBlob;
                } else if (familiar[i].u_formatoFoto === "jpg") {
                    const base64String = `data:image/jpg;base64,${archivoBase64_b}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/jpg;base64,${base64Data}`;
                    familiar[i].u_fotografia.data = imgBlob;
                }
            }
        }

        return familiar;
    }


    // obtener Familiar NO paciente por ID relacionado con uno o varios pacientes
    async getFamNoEsPaciente(id_familiar) {
        const response = await axios.get(`${FAM_NO_ES_PAC_API_BASE_URL}BuscarFam?id_familiar=${id_familiar}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamNoEsPaciente: ', error);
            });

        const familiar = response.data;

        if (familiar.length !== 0) {

            for (let i = 0; i < familiar.length; i++) {

                //  convertir imagen Familiar Foto tipo blob a Uint8Array
                const archivoBase64 = Buffer.from(familiar[i].f_fotografia.data, 'binary');

                if (familiar[i].f_formatoFoto === "png") {
                    // Uint8Array a String binario tipo PNG
                    const base64String = `data:image/png;base64,${archivoBase64}`;
                    // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
                    const base64Data = base64String.split(',')[2];
                    //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
                    const imgBlob = `data:image/png;base64,${base64Data}`;
                    familiar[i].f_fotografia.data = imgBlob;
                } else if (familiar[i].f_formatoFoto === "jpeg") {
                    const base64String = `data:image/jpeg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
                    const imgBlob = `data:image/jpeg;base64,${base64Data}`;
                    familiar[i].f_fotografia.data = imgBlob;
                } else if (familiar[i].f_formatoFoto === "jpg") {
                    const base64String = `data:image/jpg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/jpg;base64,${base64Data}`;
                    familiar[i].f_fotografia.data = imgBlob;
                }

                //  convertir imagen Paciente Foto tipo blob a Uint8Array
                const archivoBase64_b = Buffer.from(familiar[i].u_fotografia.data, 'binary');

                if (familiar[i].u_formatoFoto === "png") {
                    const base64String = `data:image/png;base64,${archivoBase64_b}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/png;base64,${base64Data}`;
                    familiar[i].u_fotografia.data = imgBlob;
                } else if (familiar[i].u_formatoFoto === "jpeg") {
                    const base64String = `data:image/jpeg;base64,${archivoBase64_b}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/jpeg;base64,${base64Data}`;
                    familiar[i].u_fotografia.data = imgBlob;
                } else if (familiar[i].u_formatoFoto === "jpg") {
                    const base64String = `data:image/jpg;base64,${archivoBase64_b}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/jpg;base64,${base64Data}`;
                    familiar[i].u_fotografia.data = imgBlob;
                }
            }
        }

        return familiar;
    }


    // obtener Familiares NO pacientes relacionados con un UN paciente por su ID
    async getFamiliarNoEsPacienteLista() {
        const response = await axios.get(FAM_NOES_PAC_LISTA_API_BASE_URL)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamiliarNoEsPacienteLista: ', error);
            });

        const familiar = response.data;

        if (familiar.length !== 0) {

            for (let i = 0; i < familiar.length; i++) {

                //  convertir imagen blob a Uint8Array
                const archivoBase64 = Buffer.from(familiar[i].f_fotografia.data, 'binary');

                if (familiar[i].f_formatoFoto === "png") {
                    // Uint8Array a String binario tipo PNG
                    const base64String = `data:image/png;base64,${archivoBase64}`;
                    // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
                    const base64Data = base64String.split(',')[2];
                    //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
                    const imgBlob = `data:image/png;base64,${base64Data}`;
                    familiar[i].f_fotografia.data = imgBlob;
                } else if (familiar[i].f_formatoFoto === "jpeg") {
                    const base64String = `data:image/jpeg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
                    const imgBlob = `data:image/jpeg;base64,${base64Data}`;
                    familiar[i].f_fotografia.data = imgBlob;
                } else if (familiar[i].f_formatoFoto === "jpg") {
                    const base64String = `data:image/jpg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/jpg;base64,${base64Data}`;
                    familiar[i].f_fotografia.data = imgBlob;
                }
            }
        }

        return familiar;
    }


    // Editar
    // obtener Familiares NO son pacientes por ID Familiar para:  ----   EditFamiliar
    async getEditFamNoEsPaciente(id_familiar) {
        const response = await axios.get(`${FAM_NO_ES_PAC_API_BASE_URL}Edit?id_familiar=${id_familiar}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getEditFamNoEsPaciente: ', error);
            });

        const familiar = response.data;

        if (familiar.length !== 0) {

            for (let i = 0; i < familiar.length; i++) {

                //  convertir imagen blob a Uint8Array
                const archivoBase64 = Buffer.from(familiar[i].f_fotografia.data, 'binary');

                if (familiar[i].f_formatoFoto === "png") {
                    // Uint8Array a String binario tipo PNG
                    const base64String = `data:image/png;base64,${archivoBase64}`;
                    // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
                    const base64Data = base64String.split(',')[2];
                    //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
                    const imgBlob = `data:image/png;base64,${base64Data}`;
                    familiar[i].f_fotografia.data = imgBlob;
                } else if (familiar[i].f_formatoFoto === "jpeg") {
                    const base64String = `data:image/jpeg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
                    const imgBlob = `data:image/jpeg;base64,${base64Data}`;
                    familiar[i].f_fotografia.data = imgBlob;
                } else if (familiar[i].f_formatoFoto === "jpg") {
                    const base64String = `data:image/jpg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/jpg;base64,${base64Data}`;
                    familiar[i].f_fotografia.data = imgBlob;
                }
            }
        }

        return familiar;
    }


    // obtener Familiares SON pacientes: Paciente que tiene relación con otro pac que es Fam
    async getFamiliarEsPaciente(id_paciente) {
        const response = await axios.get(`${FAM_ES_PAC_API_BASE_URL}?id_paciente=${id_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamiliarEsPaciente: ', error);
            });

        const familiar = response.data;

        if (familiar.length !== 0) {

            for (let i = 0; i < familiar.length; i++) {

                //  convertir imagen pf_fotografia blob a Uint8Array
                const archivoBase64 = Buffer.from(familiar[i].pf_fotografia.data, 'binary');

                if (familiar[i].pf_formatoFoto === "png") {
                    // Uint8Array a String binario tipo PNG
                    const base64String = `data:image/png;base64,${archivoBase64}`;
                    // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
                    const base64Data = base64String.split(',')[2];
                    //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
                    const imgBlob = `data:image/png;base64,${base64Data}`;
                    familiar[i].pf_fotografia.data = imgBlob;
                } else if (familiar[i].pf_formatoFoto === "jpeg") {
                    const base64String = `data:image/jpeg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
                    const imgBlob = `data:image/jpeg;base64,${base64Data}`;
                    familiar[i].pf_fotografia.data = imgBlob;
                } else if (familiar[i].pf_formatoFoto === "jpg") {
                    const base64String = `data:image/jpg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/jpg;base64,${base64Data}`;
                    familiar[i].pf_fotografia.data = imgBlob;
                }

                //  convertir imagen p_fotografia blob a Uint8Array
                const archivoBase64_b = Buffer.from(familiar[i].p_fotografia.data, 'binary');

                if (familiar[i].p_formatoFoto === "png") {
                    const base64String = `data:image/png;base64,${archivoBase64_b}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/png;base64,${base64Data}`;
                    familiar[i].p_fotografia.data = imgBlob;
                } else if (familiar[i].p_formatoFoto === "jpeg") {
                    const base64String = `data:image/jpeg;base64,${archivoBase64_b}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/jpeg;base64,${base64Data}`;
                    familiar[i].p_fotografia.data = imgBlob;
                } else if (familiar[i].p_formatoFoto === "jpg") {
                    const base64String = `data:image/jpg;base64,${archivoBase64_b}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/jpg;base64,${base64Data}`;
                    familiar[i].p_fotografia.data = imgBlob;
                }
            }
        }

        return familiar;
    }


    // Familiares Es paciente: obtener por id_pf (paciente familiar), pf_nom, etc
    async getFamiliarEsPacientePxFam(id_paciente_familiar) {
        const response = await axios.get(`${FAM_ES_PAC_API_BASE_URL}PxFam?id_paciente_familiar=${id_paciente_familiar}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamiliarEsPacientePxFam: ', error);
            });

        const familiar = response.data;

        if (familiar.length !== 0) {

            for (let i = 0; i < familiar.length; i++) {

                //  convertir imagen pf_fotografia blob a Uint8Array
                const archivoBase64 = Buffer.from(familiar[i].pf_fotografia.data, 'binary');

                if (familiar[i].pf_formatoFoto === "png") {
                    // Uint8Array a String binario tipo PNG
                    const base64String = `data:image/png;base64,${archivoBase64}`;
                    // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
                    const base64Data = base64String.split(',')[2];
                    //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
                    const imgBlob = `data:image/png;base64,${base64Data}`;
                    familiar[i].pf_fotografia.data = imgBlob;
                } else if (familiar[i].pf_formatoFoto === "jpeg") {
                    const base64String = `data:image/jpeg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
                    const imgBlob = `data:image/jpeg;base64,${base64Data}`;
                    familiar[i].pf_fotografia.data = imgBlob;
                } else if (familiar[i].pf_formatoFoto === "jpg") {
                    const base64String = `data:image/jpg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/jpg;base64,${base64Data}`;
                    familiar[i].pf_fotografia.data = imgBlob;
                }

                //  convertir imagen p_fotografia blob a Uint8Array
                const archivoBase64_b = Buffer.from(familiar[i].p_fotografia.data, 'binary');

                if (familiar[i].p_formatoFoto === "png") {
                    const base64String = `data:image/png;base64,${archivoBase64_b}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/png;base64,${base64Data}`;
                    familiar[i].p_fotografia.data = imgBlob;
                } else if (familiar[i].p_formatoFoto === "jpeg") {
                    const base64String = `data:image/jpeg;base64,${archivoBase64_b}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/jpeg;base64,${base64Data}`;
                    familiar[i].p_fotografia.data = imgBlob;
                } else if (familiar[i].p_formatoFoto === "jpg") {
                    const base64String = `data:image/jpg;base64,${archivoBase64_b}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/jpg;base64,${base64Data}`;
                    familiar[i].p_fotografia.data = imgBlob;
                }
            }
        }

        return familiar;
    }


    // obtener Familiares SON pacientes
    async getFamiliarEsPaciente_Pac(id_paciente, id_psicologo, id_paciente_familiar) {
        const response = await axios.get(`${FAM_ES_PAC_API_BASE_URL}_Pac?id_paciente=${id_paciente}&&id_psicologo=${id_psicologo}&&id_paciente_familiar=${id_paciente_familiar}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamiliarEsPaciente: ', error);
            });

        const familiar = response.data;

        if (familiar.length !== 0) {

            for (let i = 0; i < familiar.length; i++) {

                //  convertir imagen pf_fotografia blob a Uint8Array
                const archivoBase64 = Buffer.from(familiar[i].pf_fotografia.data, 'binary');

                if (familiar[i].pf_formatoFoto === "png") {
                    // Uint8Array a String binario tipo PNG
                    const base64String = `data:image/png;base64,${archivoBase64}`;
                    // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
                    const base64Data = base64String.split(',')[2];
                    //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
                    const imgBlob = `data:image/png;base64,${base64Data}`;
                    familiar[i].pf_fotografia.data = imgBlob;
                } else if (familiar[i].pf_formatoFoto === "jpeg") {
                    const base64String = `data:image/jpeg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
                    const imgBlob = `data:image/jpeg;base64,${base64Data}`;
                    familiar[i].pf_fotografia.data = imgBlob;
                } else if (familiar[i].pf_formatoFoto === "jpg") {
                    const base64String = `data:image/jpg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/jpg;base64,${base64Data}`;
                    familiar[i].pf_fotografia.data = imgBlob;
                }

                //  convertir imagen p_fotografia blob a Uint8Array
                const archivoBase64_b = Buffer.from(familiar[i].p_fotografia.data, 'binary');

                if (familiar[i].p_formatoFoto === "png") {
                    const base64String = `data:image/png;base64,${archivoBase64_b}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/png;base64,${base64Data}`;
                    familiar[i].p_fotografia.data = imgBlob;
                } else if (familiar[i].p_formatoFoto === "jpeg") {
                    const base64String = `data:image/jpeg;base64,${archivoBase64_b}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/jpeg;base64,${base64Data}`;
                    familiar[i].p_fotografia.data = imgBlob;
                } else if (familiar[i].p_formatoFoto === "jpg") {
                    const base64String = `data:image/jpg;base64,${archivoBase64_b}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/jpg;base64,${base64Data}`;
                    familiar[i].p_fotografia.data = imgBlob;
                }
            }
        }

        return familiar;
    }


    // obtener Familiares SON pacientes filtrado por psicólogo
    async getFamiliarEsPacienteLista(id_psicologo) {
        const response = await axios.get(`${FAM_ESPAC_LISTA_API_BASE_URL}?id_psicologo=${id_psicologo}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamiliarEsPacienteLista: ', error);
            });

        const familiar = response.data;

        if (familiar.length !== 0) {

            for (let i = 0; i < familiar.length; i++) {

                //  convertir imagen blob a Uint8Array
                const archivoBase64 = Buffer.from(familiar[i].pf_fotografia.data, 'binary');

                if (familiar[i].pf_formatoFoto === "png") {
                    // Uint8Array a String binario tipo PNG
                    const base64String = `data:image/png;base64,${archivoBase64}`;
                    // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
                    const base64Data = base64String.split(',')[2];
                    //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
                    const imgBlob = `data:image/png;base64,${base64Data}`;
                    familiar[i].pf_fotografia.data = imgBlob;
                } else if (familiar[i].pf_formatoFoto === "jpeg") {
                    const base64String = `data:image/jpeg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
                    const imgBlob = `data:image/jpeg;base64,${base64Data}`;
                    familiar[i].pf_fotografia.data = imgBlob;
                } else if (familiar[i].pf_formatoFoto === "jpg") {
                    const base64String = `data:image/jpg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/jpg;base64,${base64Data}`;
                    familiar[i].pf_fotografia.data = imgBlob;
                }
            }
        }

        return familiar;
    }


    // obtener Familiares SON pacientes Todos
    async getFamiliarEsPacienteTodos() {
        const response = await axios.get(`${FAM_ESPAC_LISTA_API_BASE_URL}Todos?`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamiliarEsPacienteTodos: ', error);
            });

        const familiar = response.data;

        if (familiar.length !== 0) {

            for (let i = 0; i < familiar.length; i++) {

                //  convertir imagen blob a Uint8Array
                const archivoBase64 = Buffer.from(familiar[i].pf_fotografia.data, 'binary');

                if (familiar[i].pf_formatoFoto === "png") {
                    // Uint8Array a String binario tipo PNG
                    const base64String = `data:image/png;base64,${archivoBase64}`;
                    // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
                    const base64Data = base64String.split(',')[2];
                    //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
                    const imgBlob = `data:image/png;base64,${base64Data}`;
                    familiar[i].pf_fotografia.data = imgBlob;
                } else if (familiar[i].pf_formatoFoto === "jpeg") {
                    const base64String = `data:image/jpeg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
                    const imgBlob = `data:image/jpeg;base64,${base64Data}`;
                    familiar[i].pf_fotografia.data = imgBlob;
                } else if (familiar[i].pf_formatoFoto === "jpg") {
                    const base64String = `data:image/jpg;base64,${archivoBase64}`;
                    const base64Data = base64String.split(',')[2];
                    const imgBlob = `data:image/jpg;base64,${base64Data}`;
                    familiar[i].pf_fotografia.data = imgBlob;
                }
            }
        }

        return familiar;
    }



    async getFamiliarNoEsPacienteBuscador(palabra) {
        const response = await axios.get(`${FAMNOESPAC_BUSCADOR_API_BASE_URL}?palabra=${palabra}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('FS getFamiliarNoEsPacienteBuscador:', error);
            });
        const fam = response.data;
        return fam;
    }


    async createFamiliares(familiar) {
        const response = await axios.post(FAMILIARES_API_BASE_URL, familiar)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('createFamiliares FS: ', error);
            });

        // console.log(response.status)
        return response;
    }


    async EditarFamiliar(familiar) {
        const response = await axios.put(FAM_EDIT_API_BASE_URL, familiar)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('EditarFamiliar FS: ', error);
            });
        const fam = response.data;
        return fam;
    }


    // editar el número de relaciones de un familiar
    async EditarFamiliarNoRelacion(relacion, id_familiar) {

        console.log("EditarFamiliarNoRelacion ----")
        console.log(relacion, id_familiar)

        return await axios.put(`${FAM_EDIT_NR_API_BASE_URL}?relacion=${relacion}&&id_familiar=${id_familiar}`);

    }


    async getUltimoFamiliar() {
        const response = await axios.get(FAMILIARES_API_BASE_URL)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('getFamiliares: ', error);
            });
        const familiar = response.data;
        const ultimoFamiliar = familiar.slice(-1)[0];
        return ultimoFamiliar;
    }


    // DELETE familiar, cuando se crea y al agregar parentesco éste se repite
    async DeleteFamiliar(id_familiar) {

        return await axios.delete(`${FAMILIARES_API_BASE_URL}delete?id_familiar=${id_familiar}`);

    }

}


export default new FamiliaresService();


