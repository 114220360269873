
import axios from "axios";

const CANALIZAR_API_BASE_URL = "/server/canalizar";
const VCANALIZAR_API_BASE_URL = "/server/vcanalizar";
const CANALIZADOCON_API_BASE_URL = "/server/canalizadoCon";
const RELACIONADOCON_API_BASE_URL = "/server/relacionadoCon";
const FECHASRELCON_API_BASE_URL = "/server/FechasRelacionadasCon";
const FIN_CAN_PX_API_BASE_URL = "/server/finCanlizacionPac";
const MOTIVOPAC_API_BASE_URL = "/server/MotivoCanalizacion";
const CREAR_CANALIZAR_API_BASE_URL = "/server/canalizar";
const EDITAR_CANALIZAR_API_BASE_URL = "/server/canalizarEdit";
const PAC_EDITAR_CANALIZAR_API_BASE_URL = "/server/pacienteEditarCanalizar";


// const CANALIZAR_API_BASE_URL = "http://localhost:3002/canalizar";
// const VCANALIZAR_API_BASE_URL = "http://localhost:3002/vcanalizar";
// const CANALIZADOCON_API_BASE_URL = "http://localhost:3002/canalizadoCon";
// const RELACIONADOCON_API_BASE_URL = "http://localhost:3002/relacionadoCon";
// const FECHASRELCON_API_BASE_URL = "http://localhost:3002/FechasRelacionadasCon";
// const FIN_CAN_PX_API_BASE_URL = "http://localhost:3002/finCanlizacionPac";
// const MOTIVOPAC_API_BASE_URL = "http://localhost:3002/MotivoCanalizacion";
// const CREAR_CANALIZAR_API_BASE_URL = "http://localhost:3002/canalizar";
// const EDITAR_CANALIZAR_API_BASE_URL = "http://localhost:3002/canalizarEdit";
// const PAC_EDITAR_CANALIZAR_API_BASE_URL = "http://localhost:3002/pacienteEditarCanalizar";


const Buffer = require('buffer/').Buffer;


class CanalizarService {

    async getCanalizar(id_paciente) {
        const response = await axios.get(`${CANALIZAR_API_BASE_URL}?id_paciente=${id_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('CS getCanalizar: ', error);
            });
        const canalizar = response.data;
        return canalizar;
    }


    // Buscador
    async getPacientesCanBusqueda(id_psicologo, dato) {
        const response = await axios.get(`${VCANALIZAR_API_BASE_URL}buscador?id_psicologo=${id_psicologo}&dato=${dato}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('CS getPacientesCanBusqueda: ', error);
            });
        const canalizaciones = response.data;
        return canalizaciones;
    }


    // estatus: si el paciente está o no canalizado   activo, inactivo  A | I
    async getEstatusCanalizacionesPaciente(id_paciente) {
        const response = await axios.get(`${VCANALIZAR_API_BASE_URL}estatusPaciente?id_paciente=${id_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('CS getEstatusCanalizaciones: ', error);
            });
        const canalizaciones = response.data;
        return canalizaciones;
    }


    // estatus Canalizaciones del colega
    async getEstatusCanalizacionesColega(id_colega) {
        const response = await axios.get(`${VCANALIZAR_API_BASE_URL}estatusColega?id_colega=${id_colega}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('CS getEstatusCanalizaciones: ', error);
            });
        const canalizaciones = response.data;
        return canalizaciones;
    }


    //pacientes canalizados, por id_psicólogo
    async getPacientesCanalizados(id_psicologo) {
        const response = await axios.get(`${VCANALIZAR_API_BASE_URL}paxCanalizados?id_psicologo=${id_psicologo}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('CS getPacientesCanalizados: ', error);
            });
        const canalizaciones = response.data;
        return canalizaciones;
    }



    //pacientes canalizados Todos
    async getPacientesCanalizadosTodos() {
        const response = await axios.get(`${VCANALIZAR_API_BASE_URL}Todos?`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('CS getPacientesCanalizadosTodos: ', error);
            });
        const canalizaciones = response.data;
        return canalizaciones;
    }


    //Colegas que atienden un paciente
    async getColegasAtiendenPaciente(id_psicologo) {
        const response = await axios.get(`${VCANALIZAR_API_BASE_URL}colegas?id_psicologo=${id_psicologo}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('CS getColegasAtiendenPaciente: ', error);
            });
        const canalizaciones = response.data;
        return canalizaciones;
    }


    // obtener Colegas relacionados con UN paciente [id_paciente, id_colega], pueden ser más de un colega
    async getColegasRelacionPaciente(id_paciente, id_psicologo) {
        const response = await axios.get(`${CANALIZADOCON_API_BASE_URL}?id_paciente=${id_paciente}&id_psicologo=${id_psicologo}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('CS getColegasRelacionPaciente: ', error);
            });
        const canalizaciones = response.data;
        return canalizaciones;
    }

    // obtener Pacientes relacionados con UN colega [id_colega, id_psic], pueden ser varios pacientes
    async getPacientesRelacionadosColega(id_colega, id_psicologo) {
        const response = await axios.get(`${RELACIONADOCON_API_BASE_URL}?id_colega=${id_colega}&id_psicologo=${id_psicologo}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('CS getPacientesRelacionadosColega: ', error);
            });
        const canalizaciones = response.data;
        return canalizaciones;
    }


    // Fechas relacionadas con UN paciente
    async getFechasRelacionPaciente(id_paciente, id_colega, id_psicologo) {
        const response = await axios.get(`${FECHASRELCON_API_BASE_URL}?id_paciente=${id_paciente}&id_colega=${id_colega}&id_psicologo=${id_psicologo}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('CS getFechasRelacionPaciente: ', error);
            });
        const canalizaciones = response.data;
        return canalizaciones;
    }



    // Motivo canalización del paciente
    async getMotivoCanalizacionPaciente(id_canalizar) {
        const response = await axios.get(`${MOTIVOPAC_API_BASE_URL}?id_canalizar=${id_canalizar}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('CS MotivoCanalizacionPaciente: ', error);
            });
        const canalizaciones = response.data;

        //  convertir imagen blob a Uint8Array
        const archivoBase64 = Buffer.from(canalizaciones[0].co_fotografia.data, 'binary');

        if (canalizaciones[0].co_formatoFoto === "png") {
            // Uint8Array a String binario tipo PNG
            const base64String = `data:image/png;base64,${archivoBase64}`;
            // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
            const base64Data = base64String.split(',')[2];
            //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
            const imgBlob = `data:image/png;base64,${base64Data}`;
            canalizaciones[0].co_fotografia.data = imgBlob;
        } else if(canalizaciones[0].co_formatoFoto === "jpeg") {
            const base64String = `data:image/jpeg;base64,${archivoBase64}`;
            const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
            const imgBlob = `data:image/jpeg;base64,${base64Data}`;
            canalizaciones[0].co_fotografia.data = imgBlob;
        } else if(canalizaciones[0].co_formatoFoto === "jpg") {
            const base64String = `data:image/jpg;base64,${archivoBase64}`;
            const base64Data = base64String.split(',')[2];
            const imgBlob = `data:image/jpg;base64,${base64Data}`;
            canalizaciones[0].co_fotografia.data = imgBlob;
        }

        const archivoBase64_2 = Buffer.from(canalizaciones[0].p_fotografia.data, 'binary');

        if (canalizaciones[0].p_formatoFoto === "png") {
            // Uint8Array a String binario tipo PNG
            const base64String = `data:image/png;base64,${archivoBase64_2}`;
            // Separar 'data:image/png;base64,' del String binario, porque se agrega 2 veces
            const base64Data = base64String.split(',')[2];
            //  Adecuar cadena de conversión con un solo: data:image/png;base64, + String binario
            const imgBlob = `data:image/png;base64,${base64Data}`;
            canalizaciones[0].p_fotografia.data = imgBlob;
        } else if(canalizaciones[0].p_formatoFoto === "jpeg") {
            const base64String = `data:image/jpeg;base64,${archivoBase64_2}`;
            const base64Data = base64String.split(',')[2];  // por el formato, cambia valor split
            const imgBlob = `data:image/jpeg;base64,${base64Data}`;
            canalizaciones[0].p_fotografia.data = imgBlob;
        } else if(canalizaciones[0].p_formatoFoto === "jpg") {
            const base64String = `data:image/jpg;base64,${archivoBase64_2}`;
            const base64Data = base64String.split(',')[2];
            const imgBlob = `data:image/jpg;base64,${base64Data}`;
            canalizaciones[0].p_fotografia.data = imgBlob;
        }

        return canalizaciones;
    }



    //paciente individual canalizado
    async getPacienteCanalizado(id_paciente) {
        const response = await axios.get(`${VCANALIZAR_API_BASE_URL}Individual?id_paciente=${id_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('CS getCanalizaciones: ', error);
            });
        const canalizaciones = response.data;
        return canalizaciones;
    }


    // Eventos POST, PUT, DELETE

    async createCanalizaciones(datos) {
        const response = await axios.post(CREAR_CANALIZAR_API_BASE_URL, datos)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error("CS createCanalizar: ", error);
            })
        const canalizarNuevo = response.data;
        return canalizarNuevo;
    }


    async editarCanalizar(id_paciente) {
        const response = await axios.put(`${EDITAR_CANALIZAR_API_BASE_URL}?fk_paciente=${id_paciente}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error("CS editarCANALIZAR: ", error);
            })
        const editarCanalizar = response.data;
        return editarCanalizar;
    }


    // marcar paciente canalizado
    async editarPacienteCanalizar(datos) {
        const response = await axios.put(PAC_EDITAR_CANALIZAR_API_BASE_URL, datos)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error("CS editarPacienteCanalizar: ", error);
            })
        const editPaxCanalizar = response.data;
        return editPaxCanalizar;
    }

    // Finalizar canalización de un Paciente
    async finalizarCanalizacionPaciente(valores) {
        const response = await axios.put(FIN_CAN_PX_API_BASE_URL,valores)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('CS finalizarCanalizacionPaciente: ', error);
            });
        const canalizaciones = response.data;
        return canalizaciones;
    }

}

export default new CanalizarService();


