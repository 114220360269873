
import React from "react";
import moment from 'moment';

import "../../styles/Canalizaciones.css";
import "../../styles/FormularioEditarCita.css";


const FormularioCanalizarPaciente = ({ paciente, colega, fecha }) => {

  return (
    <div className="div-registro-motivo">
      <table className="table-registro-motivo">
        <tr>
          {
            (paciente[0].fotografia.data).length !== 0 ? (
              <div>
                <td id="td-img-canalizar"><img src={paciente[0].fotografia.data} alt="avatar" id="img-agendado" /></td>
                <td id="td-pac-canalizar">
                  <a href={`#/pacientes`}>
                    <label className="lbl-px-canalizar" id="select-paciente">Paciente: {paciente[0].nombre} {paciente[0].ap1} {paciente[0].ap2}</label>
                  </a>
                </td>
              </div>
            ) : (
              <div>
                <td></td>
                <td id="td-pac-canalizar">
                  <a href={`#/pacientes`}>
                    <label className="lbl-px-canalizar" id="select-paciente">Paciente: {paciente[0].nombre} {paciente[0].ap1} {paciente[0].ap2}</label>
                  </a>
                </td>
              </div>
            )
          }
        </tr>
        <tr>
          {
            (colega[0].fotografia.data).length !== 0 ? (
              <div>
                <td id="td-img-canalizar"><img src={colega[0].fotografia.data} alt="avatar" id="img-agendado" /></td>
                <td id="td-pac-canalizar">
                  <a href={`#/colegas`}>
                    <label className="lbl-px-canalizar" id="select-colega">Colega: {colega[0].nombre} {colega[0].ap1} {colega[0].ap2}</label>
                  </a>
                </td>
              </div>
            ) : (
              <div>
                <td></td>
                <td id="td-pac-canalizar">
                  <a href={`#/colegas`}>
                    <label className="lbl-px-canalizar" id="select-colega">Colega: {colega[0].nombre} {colega[0].ap1} {colega[0].ap2}</label>
                  </a>
                </td>
              </div>
            )
          }
        </tr>
        <tr>
          <td><label> Motivo: </label></td>
          <td id="td-txt-canalizar">
            <textarea id="input-motivo" required placeholder="Motivo de canalización" rows="3" cols="50"></textarea>
          </td>
        </tr>
        <tr>
          <td><label> Comentario: </label></td>
          <td id="td-txt-canalizar">
            <textarea id="input-comentario" placeholder="Comentarios..." rows="3" cols="50"></textarea>
          </td>
        </tr>
        <tr>
          <td><label> Fecha: </label></td>
          <td id="td-time-canalizar">
            <label>{moment(fecha).format('DD-MM-YYYY')}</label>
          </td>
        </tr>
      </table>
      <input hidden='true' id="input-fecha" value={fecha}></input>
    </div>
  );
};

export default FormularioCanalizarPaciente;
