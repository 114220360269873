
import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useNavigate, useParams } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import PacientesService from "../../services/PacientesService";
import PsicologoService from "../../services/PsicologoService";
import FormularioCambiarContrasena from "../Psicologo/FormularioCambiarContrasena";
import InformacionPacientes from "../../services/InformacionPacientes";
import ExpedientesService from "../../services/ExpedientesService";
import IngresoService from "../../services/IngresoService";
import RespaldoService from "../../services/RespaldoService";

import "../../styles/Psicologo.css";

import img_exped from "../../img/folder_shared.svg";
import img_edit from "../../img/edit_note.svg";



const Psicologo = (id) => {

    console.log("---- Psicólogo ----")

    const [pacientes, setPacientes] = useState([]);
    const [psicologo, setPsicologo] = useState([]);
    const [fotografia, setFotografia] = useState('');
    const [sinFoto, setSinFoto] = useState('');
    const [foto, setFoto] = useState('');  // bandera, hay o no foto en la DB
    const [fotos, setFotos] = useState([]);
    const [selectRespaldar, setSelectRespaldar] = useState("");
    const [respaldo, setRespaldo] = useState("")
    const [status, setStatus] = useState("");
    const [archivosRespaldo, setArchivosRespaldo] = useState([]);
    const [archivoSeleccionado, setArchivoSeleccionado] = useState("");
    const navigate = useNavigate();
    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);
    const routeParams = useParams();

    const MySwal = withReactContent(Swal);


    function refreshPage() {
        window.location.reload(false);
    }

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    })

    function mostrarError(mensaje) {
        Swal.fire({
            title: '¡Error!',
            // timer: 6000,
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }

    const getData = async () => {

        // const hoy = new Date();
        // formatoHoraFecha(hoy);

        if (routeParams.id !== undefined) {
            cargarInformacionPsicologo(parseInt(routeParams.id));
            cargarInformacionPacientes(parseInt(routeParams.id));
            const data = await RespaldoService.obtenerListaRespaldos();
            console.log(data)
            setArchivosRespaldo(data);

        } else {
            alert("Error al cargar la página.")
        }
    };


    useEffect(() => {
        getData();
    }, [])

    const cargarInformacionPsicologo = async (id_psicologo) => {

        const data = await PsicologoService.getPsicologoIndividual(id_psicologo);

        data[0].sexo = InformacionPacientes.sexoPaciente(data[0].sexo);
        data[0].edo_civil = InformacionPacientes.estadoCivil(data[0].edo_civil);
        data[0].edo = InformacionPacientes.estadosRepublica(data[0].edo);
        data[0].pais = InformacionPacientes.paisesPaciente(data[0].pais);

        // obtener foto convertida
        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64
        if (cont.length < 1) {
            setFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setFoto("1");
        }

        setFotografia(data[0].fotografia.data)  // get base64 de DB

        setPsicologo(data[0]);
    };


    // Todos los pacientes peretenecientes al psicologo
    const cargarInformacionPacientes = async (id_psicologo) => {

        const data = await PacientesService.getPacientes(id_psicologo);
        setPacientes(data);

        const arr = [];
        for (let i = 0; i < data.length; i++) {
            const data2 = await PacientesService.getInfoPaciente(data[i].id_paciente);
            arr.push(data2[0].fotografia.data);
            setFotos(arr);
        }

    };



    const modalEliminarPac = async (id_paciente, index) => {

        const dataPac = await ExpedientesService.getExpediente(id_paciente);

        // formato personal fecha para presentar
        let fe_ingreso = "";
        let fe_in = dataPac[0].fecha_ingreso;
        fe_in = fe_in.split("-");
        if (fe_in[1] > 0 && fe_in[1] < 10) {
            fe_ingreso = fe_in[2] + "- 0" + fe_in[1] + "-" + fe_in[0];
        } else {
            fe_ingreso = fe_in[2] + "-" + fe_in[1] + "-" + fe_in[0];
        }


        MySwal.fire({
            title: 'El paciente será eliminado del sistema.',
            html: (
                <div className="datos-px-modal-eliminar">
                    {
                        fotos[index].length !== 0 ? (
                            <div className="div-avatar-pac">
                                <div className="div-avatar-delete">
                                    <img src={fotos[index]} alt="avatar" id="img-agendado" />
                                </div>
                                <div className="div-labelPac-eliminar">
                                    <a href={`#/pacientes/${dataPac[0].id_paciente}`}>
                                        <label>{dataPac[0].nombre} {dataPac[0].ap1} {dataPac[0].ap2}</label>
                                    </a>
                                </div>
                            </div>
                        ) : (
                            <div className="div-avatar-pac">
                                <div className="div-avatar-delete">
                                </div>
                                <div className="div-labelPac-eliminar">
                                    <a href={`#/pacientes/${dataPac[0].id_paciente}`}>
                                        <label>{dataPac[0].nombre} {dataPac[0].ap1} {dataPac[0].ap2}</label>
                                    </a>
                                </div>
                            </div>
                        )
                    }
                    <table className="modal-pax-eliminar">
                        <tbody>
                            <tr>
                                <td>
                                    <label className="lbl-eliminar-izq">Ingreso:</label>
                                </td>
                                <td>
                                    <label id="lbl-eliminar-der">{fe_ingreso}</label>
                                </td>
                            </tr>
                            <tr>
                                <td><label className="lbl-eliminar-izq">Edad:</label></td>
                                <td><label id="lbl-eliminar-der">{dataPac[0].edad}</label></td>
                            </tr>
                            <tr>
                                <td><label className="lbl-eliminar-izq">Trabajo actual:</label></td>
                                <td><label id="lbl-eliminar-der">{dataPac[0].trabajo_actual}</label></td>
                            </tr>
                            <tr>
                                <td><label className="lbl-eliminar-izq">Telefono:</label></td>
                                <td><label id="lbl-eliminar-der">{dataPac[0].tel}</label></td>
                            </tr>
                            <tr>
                                <td><label className="lbl-eliminar-izq">Ciudad:</label></td>
                                <td><label id="lbl-eliminar-der">{dataPac[0].ciudad}</label></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ),
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                eliminarPacienteSys(dataPac[0].fk_ingreso, dataPac[0].fecha_ingreso, dataPac[0].id_expediente);
            }
        });

    }



    const eliminarPacienteSys = async (id_ingreso, fecha_ingreso, id_expediente) => {

        // validando fecha actual
        const hoy = new Date();
        hoy.setHours(0);    // eliminar tiempo
        hoy.setMinutes(0);
        hoy.setSeconds(0);
        hoy.setMilliseconds(0);
        const fechaActual = hoy.getTime();    // convertir fecha a mls, getTime() solo funciona para comparar fechas


        // validando fecha de ingreso al sistema
        const fOr = new Date(fecha_ingreso);     // tipo de dato new Date()
        fOr.setHours(0);
        fOr.setMinutes(0);
        fOr.setSeconds(0);
        fOr.setMilliseconds(0);
        const fechaIngreso = fOr.getTime();    // fecha inicial que se ingresa en el input del modal, convertir a mls

        // 5 años en mls: 157788000000
        let fecha5anios = 157788000000;
        let feIng5 = parseInt(fechaIngreso) + parseInt(fecha5anios);  // fecha ingreso + 5 años


        if (parseInt(fechaActual) < feIng5) {

            mostrarError("El paciente no puede eliminarse hasta que tenga más de 5 años en el sistema.");

        } else if (parseInt(fechaActual) > feIng5) {

            // Eliminar id_ingreso.
            // Al eliminar Ingreso, por cascada se elimina -> Usuario -> Paciente -> toda relación: cita, sesión canaliz, transf, recom
            const deleteIngreso = await IngresoService.deleteIngreso(id_ingreso);

            if (deleteIngreso.status === 200) {

                // Eliminar expediente.   Se elimina despúes porque no está en cascada con Paciente
                const exped = await PacientesService.eliminarPacienteExped(id_expediente);

                if (exped.status === 200) {

                    Toast.fire("¡El paciente se eliminó correctamente!", "", "success")
                        .then((result) => {
                            if (result.isConfirmed) {
                                navigate(`/psicologo/${usuario.id_psicologo}`);  //web
                                refreshPage();
                            }
                        }).then(function () {
                            navigate(`/psicologo/${usuario.id_psicologo}`);  //web
                            refreshPage();
                        });

                } else {
                    mostrarError("Error al eliminar el Expediente.");
                }

            } else {
                mostrarError("Error al eliminar el Ingreso del paciente al sistema.");
            }
        }
    }



    // Pacciente Activos
    const DisplayData = pacientes.map((info, index) => {
        return (
            info.estatus === "A" ? (
                <div key={index}>
                    <table className="table-displays-psi">
                        <tbody>
                            <tr id="tr-psi">
                                <td id="td_1-psi-a">
                                    <label>{info.nombre} {info.ap1} {info.ap2}</label>
                                </td>
                                <td id="td_2-psi-a">
                                    <label>{info.estatus}</label>
                                </td>
                                <td id="td_3-psi-a">
                                    <a href={`#/expediente/${info.id_paciente}`}><img id="img_exped" src={img_exped} alt="img_exped"></img>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (<></>)
        )
    })


    // Pacientes Inactivos
    const DisplayData2 = pacientes.map((info, index) => {
        return (
            info.estatus === "I" ? (
                <div key={index}>
                    <table>
                        <tbody>
                            <tr id="tr-psi">
                                <td id="td_1-psi-i">
                                    <label>{info.nombre} {info.ap1} {info.ap2}</label>
                                </td>
                                <td id="td_2-psi-i">
                                    <label>{info.estatus}</label>
                                </td>
                                <td id="td_3-i">
                                    <a href={`#/expediente/${info.id_paciente}`}><img id="img_exped" src={img_exped} alt="img_exped"></img>
                                    </a>
                                </td>
                                <td id="td_4-psi-i">
                                    <button id="liga_psi" onClick={() => {
                                        modalEliminarPac(info.id_paciente, index);
                                    }}>Eliminar del sistema</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (<></>)
        )
    })


    const editarPsicologo = () => {
        navigate(`/editpsicologo/${usuario.id_psicologo}`);
    }


    const cambiarContrasena = async () => {

        const data = await PsicologoService.getPsicologoIndividual(usuario.id_psicologo);

        const formularioHtml = ReactDOMServer.renderToString(
            <FormularioCambiarContrasena usuario={data} />
        );
        Swal.fire({
            title: "Cambiar contraseña",
            html: formularioHtml,
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: "Guardar",
            cancelButtonText: "Cancelar",
            preConfirm: () => {
                const contrasenaActual = document.getElementById("input-contrasenaActual").value;
                const contrasenaNueva = document.getElementById("input-contrasenaNueva").value;
                const id_usuario = data[0].id_usuario;

                if (contrasenaActual !== data[0].contrasena) {
                    mostrarError("La contraseña actual no es correcta.");
                } else if (contrasenaNueva === data[0].contrasena) {
                    mostrarError("No se puede repetir una contraseña actual.");
                } else {
                    return { contrasenaNueva, id_usuario };
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const datos = result.value;
                putCambiarContrasena(datos);
                refreshPage();
            } else if (result.dismiss === 'cancel') {
                navigate(`/psicologo/${usuario.id_psicologo}`);
            }
        });
    }


    const putCambiarContrasena = async (datos) => {

        let data = {
            contrasena: datos.contrasenaNueva,
            id_usuario: datos.id_usuario,
        }

        const respuesta = await PsicologoService.editarContrasenaPsicologo(data);

        if (respuesta.status === 200) {

            Toast.fire("¡La contraseña se actualizó correctamente!", "", "success").then((result) => {
                if (result.isConfirmed) {
                    navigate(`/psicologo`);
                }
            }).then(function () {
                navigate(`/psicologo`);
            });

        } else {
            mostrarError("Error al cambiar contraseña.");
        }
    }

    const handleSelectRespaldar = (event) => {
        console.log(event.target.value)
        setSelectRespaldar(event.target.value);
    }

    const handleRespaldarManual = async (event) => {

        MySwal.fire({
            title: 'Respaldando Base de Datos...',
            text: 'Esperar mientras se crea un respaldo.',
            allowOutsideClick: false,
            didOpen: () => {
                MySwal.showLoading();
            }
        })

        const response = await RespaldoService.RespaldoManual();
        console.log(response.status)

        if(response.status === 200) {
            MySwal.close();
            Swal.fire({
                title: '¡Respaldo completo!',
                text: '',
                icon: 'success',
                confirmButtonText: 'Aceptar'
            });
            console.log("Backup exitoso")
        }
        

    }

    const handleRespaldarAutomatico = async (event) => {
        const response = await RespaldoService.RespaldoAutomatico(selectRespaldar);
        setStatus(response.status);

        Swal.fire({
            title: '¡Respaldo programado!',
            text: `Se programó el resplado: ${selectRespaldar}`,
            icon: 'success',
            confirmButtonText: 'Aceptar'
        });

        console.log("respAut: ", response.status)
    }

    const handleRestaurar = async (event) => {

        MySwal.fire({
            title: 'Restaurando Base de Datos...',
            text: 'Esperar mientras se restaura.',
            allowOutsideClick: false,
            didOpen: () => {
                MySwal.showLoading();
            }
        });

        const response = await RespaldoService.restaurarDB(archivoSeleccionado);

        if(response.status === 200) {
            MySwal.close();
            Swal.fire({
                title: '¡Restauración completa!',
                text: '',
                icon: 'success',
                confirmButtonText: 'Aceptar'
            });
            console.log("Restauración exitosa")
        }
    }


    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }

    return (
        <Layout>
            <Content>
                <div className="psi_div_1">
                    <div className="psi_div_2">
                        <div className="psi_sesion">
                            <label id="lbl-enSesion">{tipoUsuario()} &nbsp;</label>
                            <label>{usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_psi">
                            <label>Psicólogo</label>
                        </div>
                    </div>
                    <div className="div-datos-psi">
                        <div className="div-foto-psi">
                            {
                                fotografia.length !== 0 ? (
                                    <div className="div-avatar-psi">
                                        <img src={fotografia} alt="img_psic"></img>
                                    </div>
                                ) : (
                                    <div className="div-avatar-psi">
                                    </div>
                                )
                            }
                            <div className="div-label-psi">
                                <label> {psicologo.nombre} {psicologo.ap1} {psicologo.ap2} </label>
                                <div id="container-toolTip-editar-psi">
                                    <span className="lbl-toolTip-editar-psi">
                                        Editar
                                    </span>
                                    <img src={img_edit} alt="edit" id="img_psi" onClick={editarPsicologo} />
                                </div>
                            </div>
                        </div>
                        <div className="div-table-label-psi">
                            <label>Datos personales:</label>
                        </div>
                        <div className="div-table-psi">
                            <table className="table-psi">
                                <tbody>
                                    <tr>
                                        <td className="psi-td-izq"><label className="label-edad" htmlFor="input-edad">Edad:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.edad}</label>
                                        </td>
                                        <td className="psi-td-izq"><label className="label-sexo" htmlFor="select-sexo">Sexo:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.sexo}</label>
                                        </td>
                                    </tr>
                                    <tr>

                                        <td className="psi-td-izq"><label className="label-edo-civil" htmlFor="select-edo-civil">Estado civil:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.edo_civil}</label>
                                        </td>
                                        <td className="psi-td-izq"><label className="label-domicilio" htmlFor="input-domicilio">Domicilio:</label></td>
                                        <td className="psi-td-der" colSpan={3}>
                                            <label>{psicologo.domicilio}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="psi-td-izq"><label className="label-ciudad" htmlFor="input-ciudad">Ciudad:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.ciudad}</label>
                                        </td>
                                        <td className="psi-td-izq"><label className="label-cp" htmlFor="input-cp">Código Postal:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.cod_pos}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="psi-td-izq"><label className="label-tel" htmlFor="input-tel">Teléfono:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.tel}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="psi-td-izq"><label className="label-correo" htmlFor="input-correo">Correo:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.correo}</label>
                                        </td>
                                        <td className="psi-td-izq"><label className="label-contrasena" htmlFor="input-contrasena">Contraseña:</label></td>
                                        <td className="psi-td-der">
                                            <button className="div-psi-botones" onClick={cambiarContrasena}>Cambiar</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="psi-td-izq"><label className="label-escolaridad" htmlFor="select-escolaridad">Escolaridad:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.escolaridad}</label>
                                        </td>
                                        <td className="psi-td-izq"><label className="label-cedula" htmlFor="input-cedula">Cédula:</label></td>
                                        <td className="psi-td-der" colSpan={2}>
                                            <label>{psicologo.cedula}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="psi-td-izq"><label className="label-especialidad" htmlFor="input-especialidad">Especialidad actual:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.especialidad}</label>
                                        </td>
                                        <td className="psi-td-izq"><label className="label-titulo" htmlFor="select-titulo">Titulo:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.titulo}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="psi-td-izq"><label className="label-edo" htmlFor="select-edo">Estado:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.edo}</label>
                                        </td>
                                        <td className="psi-td-izq"><label className="label-pais" htmlFor="select-pais">País:</label></td>
                                        <td className="psi-td-der">
                                            <label>{psicologo.pais}</label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="div-displays-psic">
                        <div className="div-displays-pAct">
                            <div className="div-displays-pAct-label">
                                <label>Pacientes en proceso: Activo</label>
                            </div>
                            <div className="div-displaysAct">
                                {DisplayData}
                            </div>
                        </div>
                        <div className="div-displays-pInAct">
                            <div className="div-displays-pInAct-label">
                                <label>Pacientes con proceso finalizado: Inactivo</label>
                            </div>
                            <div className="div-displaysInAct">
                                {DisplayData2}
                            </div>
                        </div>
                    </div>
                    <div className="div-contentDB-psi">
                        <div className="div-contentDB2-psi">
                            <label>Respladar la Base de datos</label>
                        </div>
                        <div className="div-respaldDB-psi">
                            <div className="div-respaldDB2-psi">
                                <div className="div-respaldDB3-psi">
                                    <label>Opciones para automatizar los respaldos: </label>
                                </div>
                                <div className="div-respaldDB4-psi">
                                    <select id="select-respaldoDB" value={selectRespaldar} onChange={handleSelectRespaldar}>
                                        <option value="select"> Selecciona una opción </option>
                                        <option value="diario"> Cada día </option>
                                        <option value="semanal"> Cada semana </option>
                                        <option value="mensual"> Cada mes </option>
                                    </select>
                                </div>
                            </div>
                            <div className="div-horarioDB-psi">
                                <div className="btn-horarioDB-psi">
                                    <button id="btn-respladar" onClick={handleRespaldarAutomatico}> Respaldar </button>
                                </div>
                            </div>
                        </div>
                        {
                            status === 200 ? (
                                <div className="div-notaDB-psi">
                                    <label>* Se ha configurado un respaldo {selectRespaldar}, a las 3:00 am</label>
                                </div>
                            ) : (<></>)
                        }
                        <hr></hr>
                        <div className="div-respaldoManual">
                            <div className="div-respaldoManual2">
                                <label> Hacer un respaldo manual: </label>
                            </div>
                            <div className="div-respaldoManual3">
                                <button id="btn-respladar" onClick={handleRespaldarManual}> Respaldar </button>
                            </div>
                            <div className="div-respaldoManual4">
                                <label> * El respaldo se creará en el momento en que se presione el botón. </label>
                            </div>
                        </div>
                    </div>
                    <div className="div-recuperarDB-psi">
                        <div className="div-contentDB2-psi">
                            <label>Restaurar la Base de datos</label>
                        </div>
                        <div className="div-restaurarDB-psi">
                            <div className="div-restaurarDB2-psi">
                                <div className="div-restaurarDB3-psi">
                                    <label>Archivos de restauración:</label>
                                </div>
                            </div>
                            <div className="div-restaurarDB5-psi">
                                <div className="div-restaurarDB6-psi">
                                    <select id="select-respaldoDB_2" onChange={(e) => setArchivoSeleccionado(e.target.value)}>
                                        <option value="">Selecciona un respaldo</option>
                                        {archivosRespaldo.map((archivo, index) => (
                                            <option key={index} value={archivo}>
                                                {archivo}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="div-restaurarDB7-psi">
                                    <button id="btn-respladar" onClick={handleRestaurar}> Restaurar </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </Layout >
    );
};

export default Psicologo;
