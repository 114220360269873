
// FormularioCitaPX.js

import React from "react";
import "../../styles/FormularioRegistrarCita.css";

const FormularioRegistroCitaPX = ({ psicologo, fechaF, horaI, horaF, comentario }) => {

  console.log("FormularioRegistroCitaPX")

  return (
    <div className="div-agendar-cita">
      <div className="div-agendar2-cita">
        <table className="table-registro-cita-px">
          <tbody>
            <tr className="table-line-bottom">
              <td className="registrar-cita-td-izq">
                <label className="lbl-paciente-registro"> Psicologo: </label>
              </td>
              <td className="registrar-cita-td-der">
                <label>{psicologo.nombre} {psicologo.ap1} {psicologo.ap2}</label>
              </td>
            </tr>
            <tr className="table-line-bottom">
              <td className="registrar-cita-td-izq">
                <label className="lbl-fecha-registro"> Fecha: </label>
              </td>
              <td className="registrar-cita-td-der">
                <input type="date" id="input-fecha" defaultValue={fechaF} />
              </td>
            </tr>
            <tr className="table-line-bottom">
              <td className="registrar-cita-td-izq">
                <label className="lbl-horaI-registro"> Hora Inicio: </label>
              </td>
              <td className="registrar-cita-td-der">
                <input type="time" id="input-horaI" defaultValue={horaI} />
              </td>
            </tr>
            <tr className="table-line-bottom">
              <td className="registrar-cita-td-izq">
                <label className="lbl-horaF-registro"> Hora Final: </label>
              </td>
              <td className="registrar-cita-td-der">
                <input type="time" id="input-horaF" defaultValue={horaF} />
              </td>
            </tr>
            <tr>
              <td className="registrar-cita-td-izq">
                <label className="lbl-comentario-registro"> Modalidad: </label>
              </td>
              <td className="registrar-cita-td-der">
                <select className="selected-pax" id="select-modalidad" defaultValue="elegir">
                  <option className="selected-option" value="elegir" disabled> Selecciona una opción </option>
                  <option className="selected-option" value="presencial">Presencial</option>
                  <option className="selected-option" value="videollamada">Videollamada</option>
                  <option className="selected-option" value="telefono">Teléfono</option>
                  <option className="selected-option" value="mensajes">Mensajes: voz, texto</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="div-comentario-registro-px">
        <div className="div-comentario2-registro-px">
          <label> Comentario: </label>
        </div>
        <div className="div-comentario3-registro-px">
          <textarea id="input-comentario" placeholder="Deja un comentario..." defaultValue={comentario} rows="4" cols="50" />
        </div>
      </div>
    </div>
  );
};

export default FormularioRegistroCitaPX;
