
import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { parse, format } from 'date-fns';
import { useNavigate } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
import listPlugin from '@fullcalendar/list';
import esLocale from '@fullcalendar/core/locales/es';
import moment from 'moment';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import PacientesService from "../../services/PacientesService";
import PsicologoService from "../../services/PsicologoService";
import CitasService from "../../services/CitasService";
import PendientesService from "../../services/PendientesService";
import RecomendacionService from "../../services/RecomendacionService";

import FormularioRegistroCita from "../Agenda/FormularioRegistroCita";
import FormularioRegistroCitaPX from "../Agenda/FormularioRegistroCitaPX";
import FormularioEditarCita from "../Agenda/FormularioEditarCita";
import FormularioEditarCitaPX from "../Agenda/FormularioEditarCitaPX";

import "../../styles/Calendario.css";
import "../../styles/FormularioEditarCita.css";
import "../../styles/Pacientes.css";
import "../../styles/FullCalendar.css";

import img_edit from "../../img/edit_note.svg";
import SesionesService from "../../services/SesionesService";
import { constrainPoint } from "@fullcalendar/core/internal";



const Calendario = () => {

    const [pacientes, setPacientes] = useState([]);     // recibe los pacientes del sistema
    const [psicologos, setPsicologos] = useState([]);
    const [paciente, setPaciente] = useState([]);   // info paciente individual
    const [psicologo, setPsicologo] = useState([]);     // info psicologo individual
    const [eventos, setEventos] = useState([]);
    const [pendientes, setPendientes] = useState([]);
    const [recomendacion, setRecomendacion] = useState([]);
    const [numCitasPx, setNumCitasPx] = useState("");

    const formatosFecha = ["DD-MM-YYYY", "DD/MM/YYYY"];

    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);  // usuario que ingresó al sistema
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);

    function refreshPage() {
        window.location.reload(false);
    }

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    })

    const Toast_Dialog = Swal.mixin({
        toast: true,
        confirmButtonText: "Salir"
    })

    function mostrarErrorFecha(mensaje) {
        Swal.fire({
            title: '¡Error en la Fecha ingresada!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    function mostrarErrorHora(mensaje) {
        Swal.fire({
            title: '¡Error en la Hora ingresada!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        }).then((result) => {
            if (result.isConfirmed) {
                // refreshPage();
            }
        });
    }

    function mostrarErrorHraOcupado(mensaje) {
        Swal.fire({
            title: '¡Error: Hora ocupada!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        }).then((result) => {
            if (result.isConfirmed) {
                // refreshPage();
            }
        });
    }

    function mostrarError(mensaje) {
        Swal.fire({
            title: '¡Error!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    const getData = async () => {

        if (usuario.rol === "psi") {

            const data2 = await PacientesService.getPacientesCalendario(usuario.id_psicologo);
            setPacientes(data2);

            const data = await CitasService.getCitasPS(usuario.id_psicologo);

            // citas del psicólogo
            setEventos(data?.map(evento => ({   // ?, este operador se cerciora que la propiedad no contenga el valor null o undefined antes de intentar accederla
                title: (evento.p_nombre + " " + evento.p_ap1 + " " + evento.p_ap2),
                start: `${evento.fecha}T${evento.hora_i}:00`,
                end: `${evento.fecha}T${evento.hora_f}:00`,
                editable: true
            })));

        } else if (usuario.rol === "pax") {

            const data = await CitasService.getCitasPacientePX(usuario.id_paciente);
            setNumCitasPx(data.length);     // para saber si el pax ya programó su 1ra cita, bloquear si ya los hizo

            const data2 = await CitasService.getCitasPsicologoPX(usuario.psicologo, usuario.id_paciente);

            // citas del paciente
            const eventosCombinados = [...data.map(evento => (
                {
                    title: (evento.p_nombre + " " + evento.p_ap1 + " " + evento.p_ap2),
                    start: `${evento.fecha}T${evento.hora_i}:00`,
                    end: `${evento.fecha}T${evento.hora_f}:00`,
                    editable: true,
                    description: 'Paciente'
                }
            )), ...data2.map(evento => (

                // citas del psicólogo
                {
                    title: "Ocupado",
                    start: `${evento.fecha}T${evento.hora_i}:00`,
                    end: `${evento.fecha}T${evento.hora_f}:00`,
                    color: 'gray',
                    description: 'Psicologo'
                }
            ))]

            setEventos(eventosCombinados);

            const data3 = await PsicologoService.getPsicologos();
            setPsicologos(data3);
        }
    };


    const getDataPendientes = async () => {
        if (usuario.rol === "psi") {
            const data = await PendientesService.getPendientes(usuario.id_psicologo);
            setPendientes(data);
        }
    };

    const getDataRecomendaciones = async () => {
        if (usuario.rol === "pax") {
            const data = await RecomendacionService.getRecomendaciones(usuario.id_paciente)
            setRecomendacion(data);
        }
    };

    // rol=pax
    const getDataInfoPacientePsicologo = async () => {
        if (usuario.rol === "pax") {
            const data = await PacientesService.getInfoPaciente(usuario.id_paciente);   // info individual
            setPaciente(data[0]);

            const data2 = await PsicologoService.getPsicologoIndividual(usuario.psicologo);     // info individual, // usuario.psicologo, es el Id del psic que atiende, en rol: pax
            setPsicologo(data2[0]);
        }
    }

    useEffect(() => {
        getData();
        getDataPendientes();
        getDataRecomendaciones();
        getDataInfoPacientePsicologo();  // info pac de la sesión
    }, []);



    /*********************************************************/

    // Informacion MODAL PACIENTE AGENDADO,  sesión Psicólogo
    const informacionPaciente = async (info) => {

        console.log("---- informacionPaciente psi -----")

        // // Los datos se toman del item agendado con: info.event

        const horaI = moment(info.event.start).format('HH:mm');
        const horaF = moment(info.event.end).format('HH:mm');
        const fechaF = moment(info.event.start).format('YYYY-MM-DD');   // fecha de la agenda

        const data = await CitasService.getInfoCita(fechaF, horaI, horaF);
        const data2 = await PacientesService.getInfoPaciente(data[0].id_paciente);

        const horaValida = compararHoraEdit(info.event.end);    // la hora actual debe ser menor a la final
        const fechaValida = compararFechaEdit(info.event.start);

        console.log("cita-----------")
        console.log(horaValida, fechaValida)


        if (fechaValida === "fechaValida" && horaValida === "horaValida") {
            MySwal.fire({
                title: 'Paciente agendado',
                html: (
                    <div className="datos-paciente-modal">
                        <div className="datos-foto-modal">
                            <div id="img-agendado">
                                {
                                    (data2[0].fotografia.data).length !== 0 ? (
                                        <img src={data2[0].fotografia.data} alt="base64"></img>
                                    ) : (
                                        <></>
                                    )
                                }
                            </div>
                            <div id="lbl-a-agendado">
                                <a href={`#/pacientes/${data[0].id_paciente}`}>
                                    <label id="lbl-px-agendado">{data[0].p_nombre} {data[0].p_ap1} {data[0].p_ap2}</label>
                                </a>
                            </div>
                        </div>
                        <div className="div-pax-agendado">
                            <table className="table-modal-pax-agendado">
                                <tbody>
                                    <tr>
                                        <td><label className="lbl-fecha-agendado">Fecha:</label></td>
                                        <td><label id="lbl-fecha-agendado">{moment(data[0].fecha).format("DD-MM-YYYY")}</label></td>
                                        <td><label className="lbl-horaI-agendado">Horario:</label></td>
                                        <td><label id="lbl-horaI-agendado">{data[0].hora_i} - {data[0].hora_f}</label></td>
                                    </tr>
                                    <tr>
                                        <td><label className="lbl-edad-agendado" htmlFor="lbl-edad-agendado">Edad:</label></td>
                                        <td><label id="lbl-edad-agendado">{data[0].edad} años</label></td>
                                        <td><label className="lbl-trabajo-agendado" htmlFor="lbl-trabajo-agendado">Trabajo actual:</label></td>
                                        <td><label id="lbl-trabajo-agendado">{data[0].trabajo_actual}</label></td>
                                    </tr>
                                    <tr>
                                        <td><label className="lbl-telefono-agendado" htmlFor="lbl-telefono-agendado">Telefono:</label></td>
                                        <td><label id="lbl-telefono-agendado">{data[0].tel}</label></td>
                                        <td><label className="lbl-ciudad-agendado" htmlFor="lbl-ciudad-agendado">Ciudad:</label></td>
                                        <td><label id="lbl-ciudad-agendado">{data[0].ciudad}</label></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="div-Coment-agendadoPx">
                            <div className="div-Coment2-3-agendadoPx">
                                <div className="div-Coment2-agendadoPx">
                                    <label>Comentario del paciente:</label>
                                </div>
                                <div className="div-Coment2-1-agendadoPx">
                                    <label>Modalidad: {data[0].modalidad}</label>
                                </div>
                            </div>
                            <div className="div-Coment3-agendadoPx">
                                <p> {data[0].comentario_px} </p>
                            </div>
                        </div>
                        <div className="div-Coment-agendadoPx">
                            <div className="div-Coment2-3-agendadoPx">
                                <div className="div-Coment2-agendadoPx">
                                    <label>Comentario del psicólogo:</label>
                                </div>
                            </div>
                            <div className="div-Coment3-agendadoPx">
                                <p> {data[0].comentario_psi} </p>
                            </div>
                        </div>
                    </div>
                ),
                showCancelButton: true,
                showDenyButton: true,
                showCloseButton: true,
                confirmButtonText: 'Editar',
                cancelButtonText: 'Ir a sesión',
                denyButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    editarCita(info);
                } else if (result.isDenied) {
                    eliminarCita(info);
                } else if (result.dismiss === 'cancel') {
                    navigate(`/sesiones/${data[0].id_paciente}`);       // entorno web
                }
            });

        } else {

            MySwal.fire({
                title: 'Cita transcurrida',
                html: (
                    <div className="datos-paciente-modal">
                        <div className="datos-foto-modal">
                            <div id="img-agendado">
                                {
                                    (data2[0].fotografia.data).length !== 0 ? (
                                        <img src={data2[0].fotografia.data} alt="base64"></img>
                                    ) : (
                                        <></>
                                    )
                                }
                            </div>
                            <div id="lbl-a-agendado">
                                <a href={`#/pacientes/${data[0].id_paciente}`}>
                                    <label id="lbl-px-agendado">{data[0].p_nombre} {data[0].p_ap1} {data[0].p_ap2}</label>
                                </a>
                            </div>
                        </div>
                        <div className="div-pax-agendado">
                            <table className="table-modal-pax-agendado">
                                <tbody>
                                    <tr>
                                        <td><label className="lbl-fecha-agendado">Fecha:</label></td>
                                        <td><label id="lbl-fecha-agendado">{moment(data[0].fecha).format("DD-MM-YYYY")}</label></td>
                                        <td><label className="lbl-horaI-agendado">Horario:</label></td>
                                        <td><label id="lbl-horaI-agendado">{data[0].hora_i} - {data[0].hora_f}</label></td>
                                    </tr>
                                    <tr>
                                        <td><label className="lbl-edad-agendado" htmlFor="lbl-edad-agendado">Edad:</label></td>
                                        <td><label id="lbl-edad-agendado">{data[0].edad} años</label></td>
                                        <td><label className="lbl-telefono-agendado" htmlFor="lbl-telefono-agendado">Telefono:</label></td>
                                        <td><label id="lbl-telefono-agendado">{data[0].tel}</label></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="div-Coment-agendadoPx">
                            <div className="div-Coment2-3-agendadoPx">
                                <div className="div-Coment2-agendadoPx">
                                    <label>Comentario del paciente:</label>
                                </div>
                                <div className="div-Coment2-1-agendadoPx">
                                    <label>Modalidad: {data[0].modalidad}</label>
                                </div>
                            </div>
                            <div className="div-Coment3-agendadoPx">
                                <p> {data[0].comentario_px} </p>
                            </div>
                        </div>
                        <div className="div-Coment-agendadoPx">
                            <div className="div-Coment2-3-agendadoPx">
                                <div className="div-Coment2-agendadoPx">
                                    <label>Comentario del psicólogo:</label>
                                </div>
                            </div>
                            <div className="div-Coment3-agendadoPx">
                                <p> {data[0].comentario_psi} </p>
                            </div>
                        </div>
                    </div>
                ),
                confirmButtonText: 'Salir'
            });
        }
    }


    const moverCita = async (info) => {

        console.log("---- Desplazar cita ----")

        if (usuario.rol === "psi") {

            let horaI = moment(info.oldEvent.start).format('HH:mm');    // obtiene la hora inicial del evento (en la agenda) antes de moverse
            let horaF = moment(info.oldEvent.end).format('HH:mm');
            let fecha = moment(info.oldEvent.start).format('YYYY-MM-DD');

            const data = await CitasService.getInfoCita(fecha, horaI, horaF);   // obtiene la cita agendada del evento antes de editarse
            const paciente = data[0].id_paciente

            // compara la fecha_Original con la fecha_Actual, en mls
            // fecha_Actual < fecha_Original = Correcto *** fecha_Actual > fecha_Original = Incorrecto
            const fechaValida = compararFechaDrag(info.oldEvent.start);
            // console.log("fechaValida: ", fechaValida)

            if (fechaValida === "fechaValida") {

                horaI = moment(info.event.start).format('HH:mm');   // obtiene la nueva hora inicial del evento (en la agenda) después de desplazarse (drop), antes de llamar a editarDatos()
                horaF = moment(info.event.end).format('HH:mm');
                fecha = moment(info.event.start).format('YYYY-MM-DD');

                const modalidad = data[0].modalidad
                const comentario = data[0].comentario
                const estatus_cita = "Modificada";
                const desplazar = "si";     // bandera para señalar que se usó dezplazar (drag)
                const datos = { paciente, fecha, horaI, horaF, modalidad, estatus_cita, comentario, desplazar };

                editarDatos(datos, data[0].id_cita, data[0].fecha, data[0].hora_i);     // data[0].fecha, para mantener la fecha original antes de editar

            } else if (fechaValida === "fechaNoValida") {

                // cuando se desplaza de días pasados a la fecha actual o antes de la fecha actual

                // para Validar Fecha en formato: dd-mm-yyyy
                let fIn = data[0].fecha;
                fIn = fIn.split("-");
                let fInput = fIn[2] + "-" + fIn[1] + "-" + fIn[0];

                Toast_Dialog.fire(`El día ${fInput} u hora ${data[0].hora_i} seleccionados es anterior al día u hora actual. -`, "", "error")
                    .then((result) => {
                        if (result.isConfirmed) {
                            refreshPage();
                        }
                    }).then(function () {
                        refreshPage();
                    });
            }

        } else if (usuario.rol === "pax") {

            let horaI = moment(info.oldEvent.start).format('HH:mm');    // obtiene la hora inicial del evento (en la agenda) antes de moverse
            let horaF = moment(info.oldEvent.end).format('HH:mm');
            let fecha = moment(info.oldEvent.start).format('YYYY-MM-DD');

            const data = await CitasService.getInfoCita(fecha, horaI, horaF);   // obtiene la cita agendada del evento antes de editarse
            const idPac = data[0].id_paciente;
            const idPsi = data[0].id_psicologo;

            const fechaValida = compararFechaDrag(info.oldEvent.start);
            console.log("fechaValida: ", fechaValida)

            if (fechaValida === "fechaValida") {

                horaI = moment(info.event.start).format('HH:mm');   // obtiene la nueva hora inicial del evento (en la agenda) después de desplazarse (drop), antes de llamar a editarDatos()
                horaF = moment(info.event.end).format('HH:mm');
                fecha = moment(info.event.start).format('YYYY-MM-DD');

                const modalidad = data[0].modalidad
                const comentario = data[0].comentario
                const estatus_cita = "Modificada";
                const desplazar = "si";     // bandera para señalar que se usó dezplazar (drag)
                const datos = { idPac, idPsi, fecha, horaI, horaF, modalidad, estatus_cita, comentario, desplazar };

                editarDatosPX(datos, data[0].id_cita, data[0].fecha, data[0].hora_i);     // data[0].fecha, para mantener la fecha original antes de editar

            } else if (fechaValida === "fechaNoValida") {

                // para Validar Fecha en formato: dd-mm-yyyy
                let fIn = data[0].fecha;
                fIn = fIn.split("-");
                let fInput = fIn[2] + "-" + fIn[1] + "-" + fIn[0];

                Toast_Dialog.fire(`El día ${fInput} u hora ${data[0].hora_i} seleccionados es anterior al día u hora actual. -`, "", "error")
                    .then((result) => {
                        if (result.isConfirmed) {
                            refreshPage();
                        }
                    }).then(function () {
                        refreshPage();
                    });

            }
        }
    }



    /**************  Agendar cita por Psicologo  ***************/

    // Componente MODAL para agendar cita por el psicolgo
    const agendarCita = async (info) => {

        console.log("agendar cita Psi")

        // Los datos se toman de la retícula del calendario con: info.date

        const fechaF = moment(info.date).format('YYYY-MM-DD');      // fecha de la agenda
        const horaI = moment(info.date).format('HH:mm');
        const horaF = sumarMediaHora(info.date);
        const horaFForm = moment(horaF).format('HH:mm');
        const formularioHtml = ReactDOMServer.renderToString(
            <FormularioRegistroCita pacientes={pacientes} fechaF={fechaF} horaI={horaI} horaF={horaFForm} />
        );

        const horaValida = compararHora(info.date);
        const fechaValida = compararFecha(info.date);

        if (fechaValida === "fechaValida") {
            if (horaValida === "horaValida") {
                Swal.fire({
                    title: "Agendar cita",
                    html: formularioHtml,
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Guardar",
                    cancelButtonText: "Nuevo paciente",
                    preConfirm: () => {
                        const paciente = document.getElementById("select-paciente").value;
                        const fecha = (document.getElementById("input-fecha").value);
                        const horaI = document.getElementById("input-horaI").value;
                        const horaF = document.getElementById("input-horaF").value;
                        const modalidad = document.getElementById("select-modalidad").value;
                        const comentario = document.getElementById("input-comentario").value;
                        return { paciente, fecha, horaI, horaF, modalidad, comentario };
                    },
                }).then((result) => {
                    if (result.isConfirmed) {   // buscando al usuario paciente de la cita
                        const datos = result.value;
                        for (let i = 0; i < pacientes?.length; i++) {   // ?, este operador se cerciora que la propiedad no contenga el valor null o undefined antes de intentar accederla
                            if (parseInt(pacientes[i].id_paciente) === parseInt(datos.paciente)) {
                                datos.id_usuario = parseInt(pacientes[i].id_usuario);   // añadiendo id_usuario al objeto
                                datos.estatus = pacientes[i].estatus;
                            }
                        }
                        guardarDatos(datos);
                    } else if (result.dismiss === 'cancel') {
                        navigate('/addpacienteexpress');        // entorno web
                    }
                });
            }
        }
    }

    //Psicólogo, crear la cita
    const guardarDatos = async (datos) => {

        console.log("---- guardarDatos Psi ----")

        // console.log("obf Datos: ------ ")
        // console.log(datos)

        // ******* validando fechas *******

        // const hoy = new Date().toDateString;     // regresa la fecha con el nombre del día y el mes

        // validando fecha actual
        const hoy = new Date();
        // var anioHoy = hoy.getFullYear();    // Returns the year
        // var mesHoy = hoy.getMonth() + 1;  // Returns the month (from 0-11)
        // var diaHoy = hoy.getDate();     // Returns the day of the month (from 1-31)
        const fActualMls = hoy.getTime();    // convertir fecha a mls, getTime() solo funciona para comparar fechas
        // const dateHoy = diaHoy + "-" + mesHoy + "-" + anioHoy;
        // console.log("Fecha actual: ", dateHoy)
        // console.log("fActual_mls: ", fActualMls)

        // hora Input
        // console.log("fecha Input (editar)")
        // console.log(datos.fecha, datos.horaI)
        const fecIn = new Date(datos.fecha + " " + datos.horaI);   // convierte string fecha a tipo dato: new Date()
        const fInputMls = fecIn.getTime();
        // console.log("fInput_mls: ", fInputMls)

        // para Validar Fecha en formato: dd-mm-yyyy
        let fIn = datos.fecha;
        fIn = fIn.split("-");
        let fInput = fIn[2] + "-" + fIn[1] + "-" + fIn[0];


        if (datos.paciente === "Selecciona una opción") {

            mostrarError("Asegúrate de seleccionar un paciente");

        } else {

            if (datos.estatus === "A") {
                // console.log("aaaaaaa")

                if (fInputMls > fActualMls) {   // si la fecha ingresada es > a la fecha: día, hora Actual (que está transcurriendo)
                    // console.log("bbbbb")

                    if (validarFecha(fInput) === true) {
                        // console.log("cccccc")

                        if (validarHora(datos.horaI) && validarHora(datos.horaF)) {
                            // console.log("ddddd")

                            const fechaFormateada = format(parse(fInput, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                            const tiempo = verficarTiempo(datos.horaI, datos.horaF);

                            if (tiempo < 60 || tiempo > 60) {

                                // console.log("eeeeee")

                                mostrarErrorHora("La cita tiene que durar una hora.");

                            } else if (tiempo === 60) {

                                // console.log("ffffff")

                                const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.id_psicologo);

                                if (solapamiento === true) {    // está desocupado este horario
                                    let cita = {
                                        fecha: fechaFormateada,
                                        hora_i: datos.horaI,
                                        hora_f: datos.horaF,
                                        modalidad: datos.modalidad,
                                        estatus_cita: "Confirmada",
                                        comentario_px: "",
                                        comentario_psi: datos.comentario,
                                        fk_paciente: parseInt(datos.paciente),
                                        fk_psicologo: parseInt(usuario.id_psicologo)
                                    }

                                    // console.log("obj cita ----")
                                    // console.log(cita)

                                    try {

                                        // console.log("gggggg")

                                        // Crear la cita
                                        const respuestaCita = await CitasService.crearCita(cita);

                                        // respuestaCita.data.insertId, regresa el Id de la cita creada, que debe ser la última

                                        let sesion = {
                                            sesion: "",
                                            estatus_sesion: "",
                                            fk_cita: respuestaCita.data.insertId
                                        }

                                        // Crear la sesión
                                        const respuestaSesion = await SesionesService.crearSesion(sesion);
                                        // console.log("Server cita-sesion: ", respuestaSesion.status)

                                        // bloquear Programar cita para el paciente
                                        const response = await PacientesService.denegarProgramarCitasPx(datos.id_usuario, "denegado");

                                        if (respuestaCita.status === 200 && respuestaSesion.status === 200 && response.status === 200) {

                                            Toast.fire("Se registró la cita correctamente!", "", "success")
                                                .then((result) => {
                                                    if (result.isConfirmed) {
                                                        refreshPage();
                                                    }
                                                }).then(function () {
                                                    refreshPage();
                                                });

                                        } else {
                                            mostrarError("Hubo un problema al registrar la cita. Estatus 200. Agendar cita. Psi");
                                        }

                                    } catch (error) {
                                        mostrarError("Hubo un problema al registrar la cita. Hora ocupada. Agendar cita. Psi");
                                        console.log('Error al realizar la inserción:', error.message);
                                    }

                                } else {
                                    mostrarError("Ya hay una cita en ese horario");
                                }
                            }

                        } else {
                            mostrarError("Hora incorrecta, asegurate que el formato sea HH:MM");
                        }

                    } else {
                        mostrarError("Fecha incorrecta, asegurate que el formato sea DD-MM-AAAA");
                    }

                } else {
                    mostrarErrorFecha(`Fecha incorrecta, la fecha ${fInput} ya no es actual.`);
                }

            } else if (paciente.estatus === "I") {
                mostrarError("Estatus del paciente: Inactivo");
            }
        }
    }

    const editarCita = async (info) => {

        console.log("------- editarCita Psi --------")

        const horaI = moment(info.event.start).format('HH:mm');
        const horaF = moment(info.event.end).format('HH:mm');
        const fecha = moment(info.event.start).format('YYYY-MM-DD');

        const data = await CitasService.getInfoCita(fecha, horaI, horaF);
        const fechaF = moment(info.event.start).format('YYYY-MM-DD');

        let pac;

        for (let i = 0; i < pacientes.length; i++) {
            if (pacientes[i].id_paciente === data[0].id_paciente) {
                pac = pacientes[i]
            }
        }

        const formularioHtml = ReactDOMServer.renderToString(
            <FormularioEditarCita paciente={pac} fechaF={fechaF} horaI={horaI} horaF={horaF} modalidad={data[0].modalidad} comentario_px={data[0].comentario_px} comentario_psi={data[0].comentario_psi} />
        );

        Swal.fire({
            title: "Editar cita",
            html: formularioHtml,
            // showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: "Guardar cambios",
            // cancelButtonText: "Cancelar",
            preConfirm: () => {
                const paciente = document.getElementById("select-px-editar").value;     // id_pac
                const fecha = document.getElementById("input-fecha-editar").value;
                const horaI = document.getElementById("input-horaI-editar").value;
                const horaF = document.getElementById("input-horaF-editar").value;
                const modalidad = document.getElementById("select-modalidad-editar").value;
                const estatus_cita = "Modificada";
                const comentario_psi = document.getElementById("textarea-comentario-editar").value;
                return { paciente, fecha, horaI, horaF, modalidad, estatus_cita, comentario_psi };
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const datos = result.value;
                datos.comentario_px = data[0].comentario_px;
                editarDatos(datos, data[0].id_cita, data[0].fecha, data[0].hora_i);     // data[0].fecha, data[0].hora_i para mantener la fecha, hora_i original antes de editar
            }
        });
    }

    const editarDatos = async (datos, idCita, feOrig, hrOrig) => {

        console.log("----- editarDatos Psi -----")
        // datos guardados antes de editarse
        // console.log(idCita, feOrig, hrOrig)
        // datos nuevos para actualizarse
        // console.log(datos)

        // ******* validando fechas *******

        // fecha actual
        const hoy = new Date();
        // var anioHoy = hoy.getFullYear();    // Returns the year
        // var mesHoy = hoy.getMonth() + 1;  // Returns the month (from 0-11)
        // var diaHoy = hoy.getDate();     // Returns the day of the month (from 1-31)
        const fActualMls = hoy.getTime();    // convertir fecha a mls, getTime() solo funciona para comparar fechas
        // const dateHoy = diaHoy + "-" + mesHoy + "-" + anioHoy;
        // console.log("Fecha actual: ", dateHoy)
        // console.log("fActual_mls: ", fActualMls)

        // hora original, antes de editarse
        // console.log("hora original: ", feOrig, hrOrig)
        // const feOr = new Date(feOrig + " " + hrOrig);
        // const fOrigMls = feOr.getTime();
        // console.log("fOrigMls: ", fOrigMls);

        // hora Input
        // console.log("fecha Input (editar)")
        // console.log(datos.fecha, datos.horaI)
        const fecIn = new Date(datos.fecha + " " + datos.horaI);   // convierte string fecha a tipo dato: new Date()
        const fInputMls = fecIn.getTime();
        // console.log("fInput_mls: ", fInputMls)

        // validando: Si ya se cumplió la cita o no
        // fecha Actual - fecha Input (cantidades en mls)
        // si hra_act > hr_input (hora a editar) => La cita ya pasó
        const citaTranscurrida = fActualMls - fInputMls;
        // console.log("citaTranscurrida: ", citaTranscurrida)
        // si dif_min_cita_Act_Input > 0 ----- la cita ya transcurrió
        // si dif_min_cita_Act_Input < 0 ----- la cita no ha transcurrido

        // para Validar Fecha_input en formato: dd-mm-yyyy
        let fIn = datos.fecha;
        fIn = fIn.split("-");
        let fInput = fIn[2] + "-" + fIn[1] + "-" + fIn[0];


        if (citaTranscurrida < 0) {  // la edición de cita es en días posteriores a la fecha y hora actual, num negativos
            // console.log("aaaaaaaa")

            if (validarFecha(fInput)) {
                // console.log("bbbbbbbb")

                if (validarHora(datos.horaI) && validarHora(datos.horaF)) {
                    // console.log("ccccccccc")

                    if (datos.horaI >= datos.horaF) {

                        mostrarErrorHora("La hora inicial no es menor a la final");

                    } else {

                        // console.log("dddddddd")

                        const fechaFormateada = format(parse(fInput, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                        const tiempo = verficarTiempo(datos.horaI, datos.horaF);


                        if (tiempo < 60 || tiempo > 60) {

                            // console.log("eeeeeee")

                            mostrarError("La cita tiene que durar una hora.");

                        } else if (tiempo === 60) {

                            // console.log("ffffffffff")

                            const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.id_psicologo);

                            if (solapamiento === true) {    // no hay otra cita en el mismo horario

                                let cita = {
                                    fecha: fechaFormateada,
                                    hora_i: datos.horaI,
                                    hora_f: datos.horaF,
                                    modalidad: datos.modalidad,
                                    estatus_cita: datos.estatus_cita,
                                    comentario_px: datos.comentario_px,
                                    comentario_psi: datos.comentario_psi,
                                    fk_paciente: parseInt(datos.paciente),
                                    fk_psicologo: parseInt(usuario.id_psicologo),
                                    id_cita: parseInt(idCita)
                                }

                                // console.log("gggggggg")

                                // console.log("obj cita ----")
                                // console.log(cita)

                                try {

                                    const respuesta = await CitasService.editarCita(cita);
                                    console.log("Sever: ", respuesta.status)

                                    if (respuesta.status === 200) {

                                        Toast.fire("Se editó la cita correctamente!", "", "success")
                                            .then((result) => {
                                                if (result.isConfirmed) {
                                                    refreshPage();
                                                }
                                            }).then(function () {
                                                refreshPage();
                                            });

                                    } else {
                                        mostrarError("Hubo un problema al registrar la cita. Estatus 200. Edit cita. Psi");
                                        console.log("Estatus-200...");
                                    }

                                } catch (error) {
                                    mostrarError("Hubo un problema al registrar la cita. Hora ocupada Edit cita. Psi");
                                    console.log('Error al realizar la inserción:', error.message);
                                }

                            } else {    // Hay una cita en el mismo horario

                                // console.log("hhhhhhhh")

                                // Saltar Solapamiento de cita
                                // Se abre posibilidad para que sólo se pueda editar el campo: Comentario

                                console.log("***********************************")

                                const data = await CitasService.getCitaIndividual(idCita);

                                // comparar que datos Originales no estén alterados con los datos Input

                                // si los datos son =, no hay alteración y se puede editar el campo: Comentario

                                // console.log("datos orig: ")
                                // console.log(data)

                                if (
                                    data[0].fecha === datos.fecha &&
                                    data[0].hora_i === datos.horaI &&
                                    data[0].hora_f === datos.horaF &&
                                    parseInt(data[0].id_paciente) === parseInt(datos.paciente)   // id_pac
                                ) {

                                    let cita = {
                                        fecha: fechaFormateada,
                                        hora_i: datos.horaI,
                                        hora_f: datos.horaF,
                                        modalidad: datos.modalidad,
                                        estatus_cita: datos.estatus_cita,
                                        comentario_px: datos.comentario_px,
                                        comentario_psi: datos.comentario_psi,
                                        fk_paciente: parseInt(datos.paciente),
                                        fk_psicologo: parseInt(usuario.id_psicologo),
                                        id_cita: parseInt(idCita)
                                    }


                                    // console.log("iiiiiiiii")
                                    // console.log("obj cita ----")
                                    // console.log(cita)

                                    try {

                                        const respuesta = await CitasService.editarCita(cita);
                                        console.log("Sever: ", respuesta.status)

                                        if (respuesta.status === 200) {

                                            Toast.fire("Se editó la cita correctamente!", "", "success")
                                                .then((result) => {
                                                    if (result.isConfirmed) {
                                                        refreshPage();
                                                    }
                                                }).then(function () {
                                                    refreshPage();
                                                });

                                        } else {
                                            mostrarError("Hubo un problema al registrar la cita. Estatus 200. Edit cita. Psi");
                                            console.log("Estatus-200...");
                                        }

                                    } catch (error) {
                                        mostrarError("Hubo un problema al registrar la cita. Hora ocupada Edit cita. Psi");
                                        console.log('Error al realizar la inserción:', error.message);
                                    }

                                } else {

                                    if (datos.desplazar === "si") {
                                        
                                        Toast_Dialog.fire("Ya existe una cita en este horario.", "", "error")
                                            .then((result) => {
                                                if (result.isConfirmed) {
                                                    refreshPage();
                                                }
                                            }).then(function () {
                                                refreshPage();
                                            });

                                    } else {

                                        mostrarErrorHraOcupado("Ya existe una cita en este horario.");

                                    }
                                }
                            }
                        }
                    }

                } else {
                    mostrarErrorHora("Hora incorrecta, asegurate que el formato sea HH:MM");
                }

            } else {
                mostrarErrorFecha("Fecha incorrecta, asegurate que el formato sea DD-MM-AAAA");
            }

        } else if (citaTranscurrida > 0) {     // La cita se programa después del día, hora Actual (Error: Ya transcurrió día, hora Actual)

            // console.log("kkkkkkkk")

            if (datos.desplazar === "si") {     // si se usa Drager

                Toast_Dialog.fire(`El día ${fInput} u hora ${datos.horaI} seleccionados es anterior al día u hora actual.`, "", "error")
                    .then((result) => {
                        if (result.isConfirmed) {
                            refreshPage();
                        }
                    }).then(function () {
                        refreshPage();
                    });

            } else {

                mostrarErrorFecha(`El día ${fInput} u hora ${datos.horaI} seleccionados es anterior al día u hora actual.`);

            }
        }

    }

    const eliminarCita = async (info) => {

        const horaI = moment(info.event.start).format('HH:mm');
        const horaF = moment(info.event.end).format('HH:mm');
        const fechaF = moment(info.event.start).format('YYYY-MM-DD');

        const data = await CitasService.getInfoCita(fechaF, horaI, horaF);

        Swal.fire({
            title: "Eliminar cita",
            text: "¿Esta seguro de eliminar la cita?",
            showCancelButton: true,
            showConfirmButton: false,
            showCloseButton: true,
            showDenyButton: true,
            denyButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isDenied) {
                eliminarDatos(data[0].id_cita, data[0].hora_i, data[0].fecha);
            }
        });
    }

    const eliminarDatos = async (idCita, hraI, fecha) => {

        // fecha actual
        const hoy = new Date();
        hoy.setHours(0);    // eliminar tiempo
        hoy.setMinutes(0);
        hoy.setSeconds(0);
        hoy.setMilliseconds(0);
        const fechaActual = hoy.getTime();    // convertir fecha a mls, getTime() solo funciona para comparar fechas

        // validando cita pasada
        const feLetras = fecha + " " + hraI;     // con ambos datos, antes de modificarse, se crea el tipo de dato Date()
        const fOr = new Date(feLetras);     // tipo de dato new Date()
        fOr.setHours(0);
        fOr.setMinutes(0);
        fOr.setSeconds(0);
        fOr.setMilliseconds(0);
        const fechaOriginal = fOr.getTime();    // convertir a mls

        // la diferencia entre fechas es igual a días y horas
        const resta = (parseInt(fechaOriginal) - parseInt(fechaActual)) / 1000;   // obtener segundos
        const res = resta / 3600;     // obtener horas
        const citaTranscurrida = (res);    // si la fecha input de la cita tiene una != > a 24hrs, ya es una cita pasada.
        // si es = 0, se está editando una cita en el día actual

        // validando hora actual
        const tiempo = new Date();    // tiempo actual de la fecha actual
        const hrAct = tiempo.getHours();   // retorna las horas (0-23) acorde a la hora local
        const minutosActual = tiempo.getMinutes();   // Retorna los minutos (0–59) para la fecha especificada acorde a la hora local.
        const sumMinAct = minutosActual + (hrAct * 60);     // los minutos actuales que han transcurrido

        // validando hora original antes de modificar el input de la nueva hora
        const hrOrIn = hraI.split(":");    // hora obtenida del campo input modal hora inicio
        const sumaMinOr = parseInt(hrOrIn[0]) * 60 + parseInt(hrOrIn[1]);   // los minutos de la hr original del input antes de modificarse


        if (fechaOriginal >= fechaActual) {      // la fecha de la cita aun no transcurre
            if (citaTranscurrida > 0) {
                const respuestaSesion = await SesionesService.eliminarSesion(idCita);
                const respuestaCita = await CitasService.eliminarCita(idCita);
                if (respuestaCita.status === 200 && respuestaSesion.status === 200) {
                    Toast.fire("Se eliminó la cita correctamente!", "", "success").then((result) => {
                        if (result.isConfirmed) {
                            refreshPage();
                        }
                    }).then(function () {
                        refreshPage();
                    });

                } else {
                    mostrarError("Hubo un problema al eliminar la cita.");
                }
            } else if (citaTranscurrida === 0) {     // se está eliminando una cita de la fecha en transcurso (actual)
                // validar hora actual
                if (sumaMinOr > sumMinAct) {
                    const respuestaSesion = await SesionesService.eliminarSesion(idCita);
                    const respuestaCita = await CitasService.eliminarCita(idCita);
                    if (respuestaCita.status === 200 && respuestaSesion.status === 200) {
                        Toast.fire("Se eliminó la cita correctamente!", "", "success").then((result) => {
                            if (result.isConfirmed) {
                                refreshPage();
                            }
                        }).then(function () {
                            refreshPage();
                        });

                    } else {
                        mostrarError("Hubo un problema al eliminar la cita.");
                    }

                } else {
                    mostrarError("Estás tratando de eliminar una cita ya transcurrida.");
                }

            } else {
                mostrarError("Estás tratando de eliminar una cita del día, antes de que transcurrida.");
            }

        } else {
            mostrarError("No está permitido eliminar las citas programadas ya transcurridas.");
        }
    }

    /*********************************************************/


    const verficarSolapamiento = async (fecha, horaI, horaF, id_psicologo) => {
        const data = await CitasService.comprobarCita(fecha, horaI, horaF, id_psicologo);
        if (data[0] === undefined) {    // Sí se puede agendar, está vacía
            return true;
        } else {    // No se puede agendar, ya está ocupada
            return false;
        }
    }

    function verificarCitaAnterior(fechaAnterior, fecha) {

        // paciente solo puede agendar 1 cita cada semana

        const lastAppointmentDate = new Date(fechaAnterior); // Fecha de la última cita
        const currentDate = new Date(fecha); // Fecha actual
        const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // Un día en milisegundos
        const daysDifference = Math.floor((currentDate - lastAppointmentDate) / oneDayInMilliseconds);

        if (daysDifference >= 7) {
            return true;
        } else {
            return false;
        }
    }

    function verficarTiempo(horaI, horaF) {
        const tInicio = new Date();
        const pInicio = horaI.split(':');
        tInicio.setHours(parseInt(pInicio[0]), parseInt(pInicio[1]));

        const tFin = new Date();
        const pFin = horaF.split(':');
        tFin.setHours(parseInt(pFin[0]), parseInt(pFin[1]));

        const diferenciaEnMilisegundos = Math.abs(tFin - tInicio);
        const minutosDiferencia = Math.floor(diferenciaEnMilisegundos / (1000 * 60));
        return minutosDiferencia;
    }

    function validarFecha(fecha) {
        // console.log("validarFecha ----", fecha)
        // console.log("la fecha es: ", moment(fecha, formatosFecha, true).isValid())
        return moment(fecha, formatosFecha, true).isValid();
    }

    function validarHora(hora) {
        return moment(hora, "HH:mm", true).isValid();
    }

    function sumarMediaHora(hora) {
        const horaActual = new Date(hora); // Crea un objeto Date a partir de la hora recibida
        horaActual.setMinutes(horaActual.getMinutes() + 60); // Suma 60 minutos
        return horaActual;
    }

    const compararFecha = (fechaAgenda) => {

        const hoy = new Date();     // fecha actual de la Pc
        var anioHoy = hoy.getFullYear();    // Returns the year
        var mesHoy = hoy.getMonth() + 1;  // Returns the month (from 0-11)
        var diaHoy = hoy.getDate();     // Returns the day of the month (from 1-31)

        // fecha obtenida en campo input
        var anioFecha = fechaAgenda.getFullYear();    // Returns the year
        var mesFecha = fechaAgenda.getMonth() + 1;  // Returns the month (from 0-11)
        var diaFecha = fechaAgenda.getDate();     // Returns the day of the month (from 1-31)

        if (anioFecha < anioHoy) {
            mostrarErrorFecha(`El año ${anioFecha} seleccionado es anterior al actual.`);
        } else if (anioFecha == anioHoy && mesFecha < mesHoy) {
            mostrarErrorFecha(`El mes ${mesFecha} seleccionado es anterior al actual.`);
        } else if (anioFecha == anioHoy && mesFecha == mesHoy && diaFecha < diaHoy) {
            mostrarErrorFecha(`El día ${diaFecha} seleccionado es anterior al día de Hoy.`);
        } else if (anioFecha == anioHoy && mesFecha >= mesHoy) {
            return "fechaValida";
        } if (anioFecha > anioHoy) {
            return "fechaValida";
        }

        // si No es válida la fecha, regresará: undefined
    }

    const compararHora = (horaInput) => {

        let horaActual = new Date();
        let hrInput = horaInput.getHours();
        let minInput = horaInput.getMinutes();

        if (horaInput.getTime() > horaActual.getTime()) {    // .getTime(), convierte a milisegundos desde 1970
            return "horaValida"
        } else {
            mostrarErrorHora(`La hora ${hrInput}:${minInput}0 seleccionada es menor a la actual.`);
            // si no es valida la hora, retorna: undefined
        }
    }

    const compararFechaDrag = (fechaAgenda) => {

        const hoy = new Date();     // fecha actual de la Pc
        var fechaActual = hoy.getTime();

        // usar fecha Original del evento (sin editar) para comparar con la actual
        var fechaOriginal = fechaAgenda.getTime();

        if (fechaActual < fechaOriginal) {
            return "fechaValida";
        } else if (fechaActual > fechaOriginal) {
            return "fechaNoValida";
        }

    }

    /* const compararHoraDrag = (horaInput) => {

        let horaActual = new Date();
        let hrInput = horaInput.getHours();
        let minInput = horaInput.getMinutes();

        if (horaInput.getTime() > horaActual.getTime()) {    // .getTime(), convierte a milisegundos desde 1970
            return "horaValida"
        } else {
            mostrarErrorHora(`La hora ${hrInput}:${minInput}0 seleccionada es menor a la actual.`);
            // si no es valida la hora, retorna: undefined
        }
    }
        */

    const compararFechaEdit = (fechaAgenda) => {

        const hoy = new Date();     // fecha actual de la Pc
        var anioHoy = hoy.getFullYear();    // Returns the year
        var mesHoy = hoy.getMonth() + 1;  // Returns the month (from 0-11)
        var diaHoy = hoy.getDate();     // Returns the day of the month (from 1-31)

        // fecha obtenida en campo input
        var anioFecha = fechaAgenda.getFullYear();    // Returns the year
        var mesFecha = fechaAgenda.getMonth() + 1;  // Returns the month (from 0-11)
        var diaFecha = fechaAgenda.getDate();     // Returns the day of the month (from 1-31)

        if (anioFecha < anioHoy) {
            mostrarErrorFecha(`El año ${anioFecha} seleccionado es anterior al actual.`);
        } else if (anioFecha == anioHoy && mesFecha < mesHoy) {
            mostrarErrorFecha(`El mes ${mesFecha} seleccionado es anterior al actual.`);
        } else if (anioFecha == anioHoy && mesFecha == mesHoy && diaFecha < diaHoy) {
            mostrarErrorFecha(`El día ${diaFecha} seleccionado es anterior al día de Hoy.`);
        } else if (anioFecha == anioHoy && mesFecha >= mesHoy) {
            return "fechaValida";
        } else {
            return "fechaNoValida";
        }
        if (anioFecha > anioHoy) {
            return "fechaValida";
        }

        // si No es válida la fecha, regresará: undefined
    }

    const compararHoraEdit = (horaFin) => {

        let horaActual = new Date();
        // let hrInput = horaInput.getHours();
        // let minInput = horaInput.getMinutes();

        if (horaActual.getTime() <= horaFin.getTime()) {    // .getTime(), convierte a milisegundos desde 1970
            return "horaValida";
        } else {
            return "horaNoValida";
        }
    }

    /*********************************************************/


    /*****************   Agendar Cita Paciente     ********************/

    // Informacion MODAL PACIENTE agendado: rol Paciente
    const informacionPacientePX = async (info) => {

        console.log("---- informacionPaciente px -----")
        // console.log(info)
        // console.log(info.event.extendedProps.description) --- obtner rol usuario

        if (info.event.extendedProps.description === 'Paciente') {

            const horaI = moment(info.event.start).format('HH:mm');
            const horaF = moment(info.event.end).format('HH:mm');
            const fechaF = moment(info.event.start).format('YYYY-MM-DD');

            const data = await CitasService.getInfoCita(fechaF, horaI, horaF);
            const data2 = await PacientesService.getInfoPaciente(data[0].id_paciente);

            const horaValida = compararHoraEdit(info.event.start);
            const fechaValida = compararFechaEdit(info.event.start);

            if (fechaValida === "fechaValida" && horaValida === "horaValida") {

                MySwal.fire({
                    title: 'Cita agendada',
                    html: (
                        <div className="div-citaAgendada-pac">
                            <div className="div-citaAgendada2-pac">
                                <div className="div-avatarModal-pac">
                                    <div id="img-FotoModal-calend">
                                        {
                                            (data2[0].fotografia.data).length !== 0 ? (
                                                <img src={data2[0].fotografia.data} alt="base64"></img>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="div-fotoPac-pac">
                                    <div>
                                        <label> Paciente: </label>
                                    </div>
                                    <div>
                                        <label id="lbl-paciente">{data[0].p_nombre} {data[0].p_ap1} {data[0].p_ap2}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="div-citaAgendada3-pac">
                                <table className="table-citaAgenda1-px">
                                    <tbody>
                                        <tr>
                                            <td id="td-izq-citaAgenda1-px">
                                                <label>Fecha:</label>
                                            </td>
                                            <td id="td-der-citaAgenda1-px">
                                                <label>{fechaF}</label>
                                            </td>
                                            <td id="td-izq-citaAgenda1-px">
                                                <label>Horario:</label>
                                            </td>
                                            <td id="td-der-citaAgenda1-px">
                                                <label>{horaI} - {horaF}</label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="div-citaAgendada6-pac">
                                <div className="div-citaAgendada7-pac">
                                    <div className="div-citaAgendada8-pac">
                                        <label>Comentario:</label>
                                    </div>
                                    <div className="div-citaAgendada9-pac">
                                        Modalidad: {data[0].modalidad}
                                    </div>
                                </div>
                                <div className="div-citaAgendada10-pac">
                                    <p>{data[0].comentario_px}</p>
                                </div>
                            </div>
                            <div className="div-citaAtiende-pac">
                                <label>Atenderá tu proceso: </label>
                            </div>
                            <div className="div-citaAgendada4-pac">
                                <div className="div-avatarModal-Psi">
                                    <div id="img-FotoModal-calend">
                                        {
                                            (data[0].fotografia.data).length !== 0 ? (
                                                <img src={data[0].psi_fotografia.data} alt="base64"></img>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="div-citaAgendada5-pac">
                                    <table className="table-citaAgenda2-px">
                                        <tbody>
                                            <tr>
                                                <td className="td-citaAgendaPsi-px">
                                                    <label>Psicólogo: {data[0].psi_nombre} {data[0].psi_ap1} {data[0].psi_ap2}</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="td-citaAgendaPsi-px">
                                                    <label>Cédula: {data[0].cedula}</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="td-citaAgendaPsi-px">
                                                    <label>Especialidad: {data[0].especialidad}</label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ),
                    showCancelButton: true,
                    showDenyButton: true,
                    confirmButtonText: 'Editar cita',
                    cancelButtonText: 'Salir',
                    denyButtonText: 'Eliminar cita',
                }).then((result) => {
                    if (result.isConfirmed) {
                        editarCitaPX(info);
                    } else if (result.isDenied) {
                        // Lógica a ejecutar cuando se presiona: Eliminar cita
                        eliminarCitaPX(info);
                    }
                });

            } else {

                MySwal.fire({
                    title: 'Cita transcurrida',
                    html: (
                        <div className="div-citaAgendada-pac">
                            <div className="div-citaAgendada2-pac">
                                <div className="div-avatarModal-pac">
                                    <div id="img-FotoModal-calend">
                                        {
                                            (data2[0].fotografia.data).length !== 0 ? (
                                                <img src={data2[0].fotografia.data} alt="base64"></img>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="div-fotoPac-pac">
                                    <div>
                                        <label> Paciente: </label>
                                    </div>
                                    <div>
                                        <label id="lbl-paciente">{data[0].p_nombre} {data[0].p_ap1} {data[0].p_ap2}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="div-citaAgendada3-pac">
                                <table className="table-citaAgenda1-px">
                                    <tbody>
                                        <tr>
                                            <td id="td-izq-citaAgenda1-px">
                                                <label>Fecha:</label>
                                            </td>
                                            <td id="td-der-citaAgenda1-px">
                                                <label>{fechaF}</label>
                                            </td>
                                            <td id="td-izq-citaAgenda1-px">
                                                <label>Horario:</label>
                                            </td>
                                            <td id="td-der-citaAgenda1-px">
                                                <label>{horaI} - {horaF}</label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="div-citaAgendada6-pac">
                                <div className="div-citaAgendada7-pac">
                                    <div className="div-citaAgendada8-pac">
                                        <label>Comentario:</label>
                                    </div>
                                    <div className="div-citaAgendada9-pac">
                                        Modalidad: {data[0].modalidad}
                                    </div>
                                </div>
                                <div className="div-citaAgendada10-pac">
                                    <p>{data[0].comentario_px}</p>
                                </div>
                            </div>
                            <div className="div-citaAgendada4-pac">
                                <div className="div-avatarModal-Psi">
                                    <div id="img-FotoModal-calend">
                                        {
                                            (data[0].fotografia.data).length !== 0 ? (
                                                <img src={data[0].psi_fotografia.data} alt="base64"></img>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="div-citaAgendada5-pac">
                                    <table className="table-citaAgenda2-px">
                                        <tbody>
                                            <tr>
                                                <td className="td-citaAgendaPsi-px">
                                                    <label>Psicólogo: {data[0].psi_nombre} {data[0].psi_ap1} {data[0].psi_ap2}</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="td-citaAgendaPsi-px">
                                                    <label>Cédula: {data[0].cedula}</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="td-citaAgendaPsi-px">
                                                    <label>Especialidad: {data[0].especialidad}</label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ),

                    confirmButtonText: 'Salir',

                })
            }

        }
    }

    // administrar Cita por el Paciente
    const agendarCitaPX = async (info) => {

        console.log("agendar cita Px")
        // console.log("num citas: ", numCitasPx)

        if (numCitasPx > 0) {   // el paciente ya programó su 1er cita o el psicólogo la programó

            mostrarError("Consulte con su psicólogo para agendar su próxima cita.");

        } else {    // el pax no ha programado su primer cita, o el psic no la ha programado

            const fechaF = moment(info.date).format('YYYY-MM-DD');
            const horaI = moment(info.date).format('HH:mm');
            const horaF = sumarMediaHora(info.date);
            const horaFForm = moment(horaF).format('HH:mm');
            const formularioHtml = ReactDOMServer.renderToString(
                <FormularioRegistroCitaPX psicologo={psicologo} fechaF={fechaF} horaI={horaI} horaF={horaFForm} />
            );

            const horaValida = compararHora(info.date);
            const fechaValida = compararFecha(info.date);

            if (fechaValida === "fechaValida") {
                if (horaValida === "horaValida") {
                    Swal.fire({
                        title: "Agendar cita",
                        html: formularioHtml,
                        showCancelButton: true,
                        confirmButtonText: "Guardar",
                        cancelButtonText: "Cancelar",
                        preConfirm: () => {
                            const fecha = document.getElementById("input-fecha").value;
                            const horaI = document.getElementById("input-horaI").value;
                            const horaF = document.getElementById("input-horaF").value;
                            const modalidad = document.getElementById("select-modalidad").value;
                            const comentario = document.getElementById("input-comentario").value;

                            return { fecha, horaI, horaF, comentario, modalidad };
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            const datos = result.value;
                            datos.idPsicologo = psicologo.id_psicologo;
                            guardarDatosPX(datos);
                        }
                    });
                }
            }
        }

    }

    //Paciente, agendar cita
    const guardarDatosPX = async (datos) => {

        console.log("---- guardarDatosPX ----")

        // console.log("obj DatosPX: ")
        // console.log(datos)

        // ******* validando fechas *******

        // obtener fecha, hora actual
        const hoy = new Date();
        // var anioHoy = hoy.getFullYear();    // Returns the year
        // var mesHoy = hoy.getMonth() + 1;  // Returns the month (from 0-11)
        // var diaHoy = hoy.getDate();     // Returns the day of the month (from 1-31)
        const fActualMls = hoy.getTime();    // convertir fecha a mls, getTime() solo funciona para comparar fechas
        // const dateHoy = diaHoy + "-" + mesHoy + "-" + anioHoy;
        // console.log("Fecha actual: ", dateHoy)
        // console.log("fActual_mls: ", fActualMls)

        // hora Input
        // console.log("fecha Input (editar)")
        // console.log(datos.fecha, datos.horaI)
        const fecIn = new Date(datos.fecha + " " + datos.horaI);   // convierte string fecha a tipo dato: new Date()
        const fInputMls = fecIn.getTime();
        // console.log("fInput_mls: ", fInputMls)

        // para Validar Fecha en formato: dd-mm-yyyy
        let fIn = datos.fecha;
        fIn = fIn.split("-");
        let fInput = fIn[2] + "-" + fIn[1] + "-" + fIn[0];


        if (paciente.estatus === "A") {
            // console.log("aaaaaaa")

            if (fInputMls > fActualMls) {   // si la fecha ingresada es > a la fecha: día, hora Actual (que está transcurriendo)
                // console.log("bbbbb")

                if (validarFecha(fInput)) {
                    // console.log("cccccc")

                    if (validarHora(datos.horaI) && validarHora(datos.horaF)) {
                        // console.log("ddddd")

                        const fechaFormateada = format(parse(fInput, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                        const data = await CitasService.verificarCitaAnterior(usuario.id_paciente);     // ver todas las citas del pax

                        if (data.length === 0) {     // no tiene citas anteriores

                            const tiempo = verficarTiempo(datos.horaI, datos.horaF);

                            if (tiempo < 60 || tiempo > 60) {

                                // console.log("eeeeee")

                                mostrarErrorHora("La cita tiene que durar una hora.");

                            } else if (tiempo === 60) {

                                // console.log("ffffff")

                                const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.psicologo);

                                if (solapamiento === true) {    // está desocupado este horario

                                    let cita = {
                                        fecha: fechaFormateada,
                                        hora_i: datos.horaI,
                                        hora_f: datos.horaF,
                                        modalidad: datos.modalidad,
                                        estatus_cita: "Confirmada",
                                        comentario_px: datos.comentario,
                                        comentario_psi: "",
                                        fk_paciente: usuario.id_paciente,
                                        fk_psicologo: parseInt(datos.idPsicologo)
                                    }

                                    console.log("obj cita ----")
                                    console.log(cita)

                                    try {

                                        // console.log("gggggg")

                                        // Crear la cita
                                        const respuestaCita = await CitasService.crearCita(cita);

                                        // respuestaCita.data.insertId, regresa el Id de la cita creada, que debe ser la última

                                        let sesion = {
                                            sesion: "",
                                            estatus_sesion: "",
                                            fk_cita: respuestaCita.data.insertId
                                        }

                                        // Crear la sesión
                                        const respuestaSesion = await SesionesService.crearSesion(sesion);
                                        console.log("Server cita-sesion: ", respuestaSesion.status)

                                        // bloquear Programar cita para el paciente
                                        const response = await PacientesService.denegarProgramarCitasPx(paciente.id_usuario, "denegado");

                                        if (respuestaCita.status === 200 && respuestaSesion.status === 200 && response.status === 200) {

                                            Toast.fire("Se registró la cita correctamente!", "", "success").then((result) => {
                                                if (result.isConfirmed) {
                                                    // refreshPage();
                                                }
                                            }).then(function () {
                                                // refreshPage();
                                            });

                                        } else {
                                            mostrarError("Hubo un problema al registrar la cita.");
                                            console.log("Estatus = 200");
                                        }

                                    } catch (error) {
                                        mostrarError("Hubo un problema al registrar la cita.");
                                        console.log('Error al realizar la inserción:', error.message);
                                    }

                                } else {
                                    mostrarErrorHraOcupado("Ya hay una cita en ese horario");
                                }
                            }

                        } else {        // tiene citas anteriores, verificarlas

                            // Suspender citas al paciente

                            if (paciente.programar_cita === "permitido") {

                                const response = await PacientesService.denegarProgramarCitasPx(paciente.id_paciente, "denegado");

                                if (response.status === 200) {
                                    alert(`Se ha denegado la solicitud de citas al paciente: ${paciente.nombre}`);
                                }
                            }

                            mostrarError("Para programar una cita, debes consultarlo con tu psicólogo.")

                            /* -------- Permitir que el paciente Agende Citas cada semana... ----------

                            // verificar que la última cita fue una semana atrás
                            const citaAnt = verificarCitaAnterior(data[0].fecha, fechaFormateada);

                            console.log("citaAnt ----------")
                            console.log(citaAnt)

                            if (citaAnt) {      // si ya pasó una semana de su última cita

                                console.log("888888")

                                const tiempo = verficarTiempo(datos.horaI, datos.horaF);

                                if (tiempo < 60 || tiempo > 60) {

                                    console.log("999999")

                                    mostrarErrorHora("La cita tiene que durar una hora.");

                                } else if (tiempo === 60) {
                                    console.log("-- 10 --")

                                    const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.psicologo);

                                    if (solapamiento === true) {
                                        let cita = {
                                            fecha: fechaFormateada,
                                            hora_i: datos.horaI,
                                            hora_f: datos.horaF,
                                            modalidad: datos.modalidad,
                                            estatus_cita: "Confirmada",
                                            comentario: datos.comentario,
                                            fk_paciente: usuario.id_paciente,
                                            fk_psicologo: parseInt(datos.idPsicologo)
                                        }
                                        try {

                                            console.log("-- 11 --")

                                            // Crear la cita
                                            const respuestaCita = await CitasService.crearCita(cita);

                                            // respuestaCita.data.insertId, regresa el Id de la cita creada, que debe ser la última

                                            let sesion = {
                                                sesion: "",
                                                estatus_sesion: "",
                                                fk_cita: respuestaCita.data.insertId
                                            }

                                            // Crear la sesión
                                            const respuestaSesion = await SesionesService.crearSesion(sesion);

                                            if (respuestaCita.status === 200 && respuestaSesion.status === 200) {

                                                Toast.fire("Se registró la cita correctamente!", "", "success").then((result) => {
                                                    if (result.isConfirmed) {
                                                        refreshPage();
                                                    }
                                                }).then(function () {
                                                    refreshPage();
                                                });

                                            } else {
                                                mostrarError("Hubo un problema al registrar la cita.");
                                                console.log("Estatus = 200");
                                            }

                                        } catch (error) {
                                            mostrarError("Hubo un problema al registrar la cita.");
                                            console.log('Error al realizar la inserción:', error.message);
                                        }

                                    } else {
                                        mostrarErrorHraOcupado("Ya hay una cita en ese horario");
                                    }
                                }

                            } else {
                                mostrarError("Para agendar una cita, tiene que pasar al menos una semana de la cita anterior");
                            }
                                */

                        }

                    } else {
                        mostrarErrorHora("Hora incorrecta, asegurate que el formato sea HH:MM");
                    }

                } else {
                    mostrarErrorFecha("Fecha incorrecta, asegurate que el formato sea DD-MM-AAAA");
                }

            } else {
                mostrarErrorFecha(`Fecha incorrecta, la fecha ${fInput} ya no es actual.`);
            }

        } else if (paciente.estatus === "I") {
            mostrarError("Estatus del paciente: Inactivo");
        }

    }

    const editarCitaPX = async (info) => {

        console.log("------- editarCitaPX --------")

        const horaI = moment(info.event.start).format('HH:mm');
        const horaF = moment(info.event.end).format('HH:mm');
        const fecha = moment(info.event.start).format('YYYY-MM-DD');

        const data = await CitasService.getInfoCita(fecha, horaI, horaF);

        let psi;    // guardar datos del psicólogo

        for (let i = 0; i < psicologos.length; i++) {
            if (psicologos[i].id_psicologo === data[0].id_psicologo) {
                psi = psicologos[i]
            }
        }

        let nom_px = data[0].p_nombre + " " + data[0].p_ap1 + " " + data[0].p_ap2;

        const formularioHtml = ReactDOMServer.renderToString(
            <FormularioEditarCitaPX psicologo={psi} nom_px={nom_px} fechaF={fecha} horaI={horaI} horaF={horaF} modalidad={data[0].modalidad} comentario={data[0].comentario_px} />
        );

        Swal.fire({
            title: "Editar cita",
            html: formularioHtml,
            // showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: "Guardar cambios",
            // cancelButtonText: "Cancelar",
            preConfirm: () => {
                const fecha = document.getElementById("input-editFecha-px").value;
                const horaI = document.getElementById("input-editHoraI-px").value;
                const horaF = document.getElementById("input-editHoraF-px").value;
                const modalidad = document.getElementById("select-editModalidad-px").value;
                const comentario = document.getElementById("input-editComentario-px").value;
                const estatus_cita = "Modificada";
                return { fecha, horaI, horaF, modalidad, comentario, estatus_cita };
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const datos = result.value;
                datos.idPsi = data[0].id_psicologo;
                datos.comentario_psi = data[0].comentario_psi;
                editarDatosPX(datos, data[0].id_cita, data[0].fecha, data[0].hora_i);   // data[0].fecha, para mantener la fecha original antes de editar
            }
        });
    }

    const editarDatosPX = async (datos, idCita, feOrig, hrOrig) => {

        console.log("----- editarDatosPX -----")
        // datos guardados antes de editarse
        // console.log(idCita)
        // datos nuevos para actualizarse
        // console.log(datos)

        // ******* validando fechas *******

        // fecha actual
        const hoy = new Date();    // Día, Hora de la fecha actual
        // var anioHoy = hoy.getFullYear();    // Returns the year
        // var mesHoy = hoy.getMonth() + 1;  // Returns the month (from 0-11)
        // var diaHoy = hoy.getDate();     // Returns the day of the month (from 1-31)
        const fActualMls = hoy.getTime();    // convertir fecha a mls, getTime() solo funciona para comparar fechas
        // const dateHoy = diaHoy + "-" + mesHoy + "-" + anioHoy;
        // console.log("Fecha actual: ", dateHoy)
        // console.log("fActual_mls: ", fActualMls)

        // hora original, antes de editarse
        // console.log("hora original: ", feOrig, hrOrig)
        // const feOr = new Date(feOrig + " " + hrOrig);
        // const fOrigMls = feOr.getTime();
        // console.log("fOrigMls: ", fOrigMls);

        // hora Input: 
        // console.log("fecha Input (editar)")
        // console.log(datos.fecha, datos.horaI)
        const fecIn = new Date(datos.fecha + " " + datos.horaI);   // convierte string fecha a tipo dato: new Date()
        const fInputMls = fecIn.getTime();
        // console.log("fInput_mls: ", fInputMls)

        // validando: Si ya se cumplió la cita o no
        // fecha Actual - fecha Input (cantidades en mls)
        // si hra_act > hr_input (hora a editar) => La cita ya pasó
        const citaTranscurrida = fActualMls - fInputMls;
        // console.log("citaTranscurrida: ", citaTranscurrida)
        // si dif_min_cita_Act_Input > 0 ----- la cita ya transcurrió
        // si dif_min_cita_Act_Input < 0 ----- la cita no ha transcurrido

        // para Validar Fecha en formato: dd-mm-yyyy
        let fIn = datos.fecha;
        fIn = fIn.split("-");
        let fInput = fIn[2] + "-" + fIn[1] + "-" + fIn[0];


        if (citaTranscurrida < 0) {     // La cita se programa antes del día, hora Actual

            // console.log("aaaaaaaa")

            if (validarFecha(fInput)) {

                // console.log("bbbbbbbb")

                if (validarHora(datos.horaI) && validarHora(datos.horaF)) {

                    // console.log("ccccccccc")

                    if (datos.horaI >= datos.horaF) {

                        mostrarErrorHora("La hora Inicio debe ser menor que la hora Final.");

                    } else {

                        // console.log("dddddddd")

                        const fechaFormateada = format(parse(fInput, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
                        const tiempo = verficarTiempo(datos.horaI, datos.horaF);

                        if (tiempo < 60 || tiempo > 60) {

                            // console.log("eeeeeee")

                            mostrarError("La cita tiene que durar una hora.");

                        } else if (tiempo === 60) {

                            // console.log("ffffffffff")

                            const solapamiento = await verficarSolapamiento(fechaFormateada, datos.horaI, datos.horaF, usuario.psicologo);

                            if (solapamiento === true) {

                                // console.log("ggggggggg")

                                let cita = {
                                    fecha: fechaFormateada,
                                    hora_i: datos.horaI,
                                    hora_f: datos.horaF,
                                    modalidad: datos.modalidad,
                                    estatus_cita: datos.estatus_cita,
                                    comentario_px: datos.comentario,
                                    comentario_psi: datos.comentario_psi,
                                    fk_paciente: parseInt(usuario.id_paciente),
                                    fk_psicologo: parseInt(datos.idPsi),
                                    id_cita: parseInt(idCita)
                                }

                                // console.log("obj cita ----")
                                // console.log(cita)

                                try {

                                    const respuesta = await CitasService.editarCita(cita);
                                    console.log("Sever: ", respuesta.status)

                                    if (respuesta.status === 200) {

                                        Toast.fire("Se editó la cita correctamente!", "", "success")
                                            .then((result) => {
                                                if (result.isConfirmed) {
                                                    refreshPage();
                                                }
                                            }).then(function () {
                                                refreshPage();
                                            });

                                    } else {
                                        mostrarError("Hubo un problema al registrar la cita.");
                                        console.log("Estatus 200")
                                    }
                                } catch (error) {
                                    mostrarError("Hubo un problema al registrar la cita.");
                                    console.log('Error al realizar la inserción:', error.message);
                                }

                            } else {    // Hay una cita en el mismo horario

                                // console.log("hhhhhhhh")

                                // Saltar Solapamiento de cita
                                // Se abre posibilidad para que sólo se pueda editar el campo: Comentario

                                console.log("***********************************")

                                const data = await CitasService.getCitaIndividual(idCita);

                                // comparar que datos Originales no estén alterados con los datos Input

                                // si los datos son =, no hay alteración y se puede editar el campo: Comentario

                                // console.log("datos orig: ")
                                // console.log(data)

                                if (
                                    data[0].fecha === datos.fecha &&
                                    data[0].hora_i === datos.horaI &&
                                    data[0].hora_f === datos.horaF &&
                                    parseInt(data[0].id_paciente) === parseInt(usuario.id_paciente) &&   // id_pac
                                    parseInt(data[0].id_psicologo) === parseInt(datos.idPsi)
                                ) {

                                    let cita = {
                                        fecha: fechaFormateada,
                                        hora_i: datos.horaI,
                                        hora_f: datos.horaF,
                                        modalidad: datos.modalidad,
                                        estatus_cita: datos.estatus_cita,
                                        comentario_px: datos.comentario,
                                        comentario_psi: datos.comentario_psi,
                                        fk_paciente: parseInt(usuario.id_paciente),
                                        fk_psicologo: parseInt(datos.idPsi),
                                        id_cita: parseInt(idCita)
                                    }

                                    // console.log("obj cita ----")
                                    // console.log(cita)

                                    try {

                                        const respuesta = await CitasService.editarCita(cita);
                                        console.log("Sever: ", respuesta.status)

                                        if (respuesta.status === 200) {

                                            Toast.fire("Se editó la cita correctamente!", "", "success")
                                                .then((result) => {
                                                    if (result.isConfirmed) {
                                                        refreshPage();
                                                    }
                                                }).then(function () {
                                                    refreshPage();
                                                });

                                        } else {
                                            mostrarError("Hubo un problema al registrar la cita.");
                                            console.log("Estatus 200")
                                        }
                                    } catch (error) {
                                        mostrarError("Hubo un problema al registrar la cita.");
                                        console.log('Error al realizar la inserción:', error.message);
                                    }

                                } else {

                                    if (datos.desplazar === "si") {     // usando drager
                                        
                                        Toast_Dialog.fire("Ya existe una cita en este horario.", "", "error")
                                            .then((result) => {
                                                if (result.isConfirmed) {
                                                    refreshPage();
                                                }
                                            }).then(function () {
                                                refreshPage();
                                            });
                                            
                                    } else {

                                        mostrarErrorHraOcupado("Ya existe una cita en este horario.");

                                    }

                                }
                            }
                        }
                    }

                } else {

                    mostrarErrorHora("Hora incorrecta, asegurate que el formato sea HH:MM");
                }

            } else {
                mostrarErrorFecha("Fecha incorrecta, asegurate que el formato sea DD-MM-AAAA");
            }

        } else if (citaTranscurrida > 0) {     // La cita se programa después del día, hora Actual (Error: Ya transcurrió día, hora Actual)

            // console.log("kkkkkkkk")

            if (datos.desplazar === "si") {     // si se usa Drager

                Toast_Dialog.fire(`El día ${fInput} u hora ${datos.horaI} seleccionados es anterior al día u hora actual.`, "", "error")
                    .then((result) => {
                        if (result.isConfirmed) {
                            refreshPage();
                        }
                    }).then(function () {
                        refreshPage();
                    });

            } else {

                mostrarErrorFecha(`El día ${fInput} u hora ${datos.horaI} seleccionados es anterior al día u hora actual.`);

            }

        }

    }

    const eliminarCitaPX = async (info) => {

        const horaI = moment(info.event.start).format('HH:mm');
        const horaF = moment(info.event.end).format('HH:mm');
        const fechaF = moment(info.event.start).format('YYYY-MM-DD');

        const data = await CitasService.getInfoCita(fechaF, horaI, horaF);

        Swal.fire({
            title: "Eliminar cita",
            text: "¿Esta seguro de eliminar la cita?",
            showCancelButton: true,
            showConfirmButton: false,
            showDenyButton: true,
            denyButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isDenied) {
                eliminarDatosPX(data[0].id_cita, data[0].hora_i, data[0].fecha);
            }
        });
    }

    const eliminarDatosPX = async (idCita, hraI, fecha) => {

        // fecha actual
        const hoy = new Date();
        hoy.setHours(0);    // eliminar tiempo
        hoy.setMinutes(0);
        hoy.setSeconds(0);
        hoy.setMilliseconds(0);
        const fechaActual = hoy.getTime();    // convertir fecha a mls, getTime() solo funciona para comparar fechas

        // validando cita pasada
        const feLetras = fecha + " " + hraI;     // con ambos datos, antes de modificarse, se crea el tipo de dato Date()
        const fOr = new Date(feLetras);     // tipo de dato new Date()
        fOr.setHours(0);
        fOr.setMinutes(0);
        fOr.setSeconds(0);
        fOr.setMilliseconds(0);
        const fechaOriginal = fOr.getTime();    // convertir a mls

        // la diferencia entre fechas es igual a días y horas
        const resta = (parseInt(fechaOriginal) - parseInt(fechaActual)) / 1000;   // obtener segundos
        const res = resta / 3600;     // obtener horas
        const citaTranscurrida = (res);    // si la fecha input de la cita tiene una != > a 24hrs, ya es una cita pasada.
        // si es = 0, se está editando una cita en el día actual

        // validando hora actual
        const tiempo = new Date();    // tiempo actual de la fecha actual
        const hrAct = tiempo.getHours();   // retorna las horas (0-23) acorde a la hora local
        const minutosActual = tiempo.getMinutes();   // Retorna los minutos (0–59) para la fecha especificada acorde a la hora local.
        const sumMinAct = minutosActual + (hrAct * 60);     // los minutos actuales que han transcurrido

        // validando hora original antes de modificar el input de la nueva hora
        const hrOrIn = hraI.split(":");    // hora obtenida del campo input modal hora inicio
        const sumaMinOr = parseInt(hrOrIn[0]) * 60 + parseInt(hrOrIn[1]);   // los minutos de la hr original del input antes de modificarse


        if (fechaOriginal >= fechaActual) {      // la fecha de la cita aun no transcurre

            if (citaTranscurrida > 0) {

                const respuestaSesion = await SesionesService.eliminarSesion(idCita);
                const respuestaCita = await CitasService.eliminarCita(idCita);
                if (respuestaCita.status === 200 && respuestaSesion.status === 200) {
                    Toast.fire("Se eliminó la cita correctamente!", "", "success").then((result) => {
                        if (result.isConfirmed) {
                            refreshPage();
                        }
                    }).then(function () {
                        refreshPage();
                    });
                } else {
                    mostrarError("Hubo un problema al eliminar la cita.");
                }

            } else if (citaTranscurrida === 0) {     // se está eliminando una cita de la fecha en transcurso (actual)

                if (sumaMinOr > sumMinAct) {

                    const respuestaSesion = await SesionesService.eliminarSesion(idCita);
                    const respuestaCita = await CitasService.eliminarCita(idCita);
                    if (respuestaCita.status === 200 && respuestaSesion.status === 200) {
                        Toast.fire("Se eliminó la cita correctamente!", "", "success").then((result) => {
                            if (result.isConfirmed) {
                                refreshPage();
                            }
                        }).then(function () {
                            refreshPage();
                        });
                    } else {
                        mostrarError("Hubo un problema al eliminar la cita.");
                    }

                } else {
                    mostrarError("Estás tratando de eliminar una cita ya transcurrida.");
                }

            } else {
                mostrarError("Estás tratando de eliminar una cita del día, antes de que transcurrida.");
            }

        } else {
            mostrarError("No está permitido eliminar las citas programadas ya transcurridas.");
        }
    }

    /********************************************* */


    const handleComentario = (index, comentario) => {
        const pendienteComentario = pendientes[index]
        pendienteComentario.comentario = comentario;
    }

    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }



    return (
        <Layout>
            <Content>
                <div className="vista-calendario">
                    <div className="encabezado-usuario">
                        <div className="px_sesion_fam">
                            <label id="lbl-enSesion">{tipoUsuario()} &nbsp; </label>
                            <label>{usuario.nombre}</label>
                        </div>
                        {
                            usuario.rol === "pax" ? (
                                <div className="px_sesion_fam">
                                    <label id="lbl-enSesion"> Psicólogo que atiende: &nbsp; </label>
                                    <label>{psicologo.nombre} {psicologo.ap1} {psicologo.ap2}</label>
                                </div>
                            ) : (<></>)
                        }
                    </div>
                    <div className="encabezado-agenda">
                        <label>Agenda</label>
                    </div>
                    {usuario.rol === "psi" ? (
                        <div className="calendario-datos">
                            <div className="calend">
                                {eventos !== undefined && eventos.length > 0 ? (
                                    <FullCalendar
                                        headerToolbar={{
                                            left: 'prev,next today',
                                            center: 'title',
                                            right: 'listDay, dayGridMonth,timeGridWeek,multiMonthYear' // Agrega las vistas aquí
                                        }}
                                        buttonText={{
                                            day: 'Dia',
                                            today: 'Hoy', // Cambia el texto del botón "Hoy"
                                            month: 'Mes', // Cambia el texto del botón "Mes"
                                            week: 'Semana', // Cambia el texto del botón "Semana"
                                            year: 'Año' // Cambia el texto del botón "Día"
                                        }}
                                        locales={[esLocale]}
                                        slotLabelFormat={{
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hourCycle: "h12",
                                            meridiem: 'short',
                                        }}
                                        eventTimeFormat={{
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hourCycle: "h12",
                                        }}
                                        nowIndicator={true}
                                        slotMinTime="07:00:00"
                                        slotMaxTime="22:00:00"
                                        allDaySlot={false}
                                        themeSystem="pulse"
                                        plugins={[listPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin, multiMonthPlugin]}
                                        initialView="timeGridWeek"
                                        events={eventos}
                                        dateClick={agendarCita}
                                        eventClick={informacionPaciente}
                                        eventDrop={moverCita}
                                    />
                                ) : (
                                    <FullCalendar
                                        headerToolbar={{
                                            left: 'prev,next today',
                                            center: 'title',
                                            right: 'listDay, dayGridMonth,timeGridWeek,multiMonthYear' // Agrega las vistas aquí
                                        }}
                                        buttonText={{
                                            day: 'Dia',
                                            today: 'Hoy', // Cambia el texto del botón "Hoy"
                                            month: 'Mes', // Cambia el texto del botón "Mes"
                                            week: 'Semana', // Cambia el texto del botón "Semana"
                                            year: 'Año' // Cambia el texto del botón "Día"
                                        }}
                                        locales={[esLocale]}
                                        slotLabelFormat={{
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hourCycle: "h12",
                                            meridiem: 'short',
                                        }}
                                        eventTimeFormat={{
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hourCycle: "h12",
                                        }}
                                        nowIndicator={true}
                                        slotMinTime="07:00:00"
                                        slotMaxTime="22:00:00"
                                        allDaySlot={false}
                                        themeSystem="pulse"
                                        plugins={[listPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin, multiMonthPlugin]}
                                        initialView="timeGridWeek"
                                        dateClick={agendarCita}
                                        eventClick={informacionPaciente}
                                    />
                                )}
                            </div>
                            <div className="datos-paciente">
                                <div className="edit_delete-pendientes">
                                    <div id="px_h3_calend">
                                        <label id="lbl_datos_calend">Pendientes</label>
                                    </div>
                                </div>
                                <div className="div-pendientes-textArea">
                                    <div>
                                        {
                                            pendientes.length === 0 ? (
                                                <div className="div-pendientes-edit-delete">
                                                    <div className="div-pendientes-label">
                                                        <label>No existen pendientes añadidos</label>
                                                    </div>
                                                </div>
                                            ) : (
                                                pendientes.map((pendiente, index) => (
                                                    <div>
                                                        <div className="div-pendientes-edit-delete">
                                                            <div key={index} id="id-hora-fecha-pendientes">
                                                                <label> Registrado el día: {pendiente.fecha} </label>
                                                            </div>
                                                            <div className="div-pendientes-toolTip">
                                                                <div id="container-toolTip-save">
                                                                    <span className="lbl-toolTip-save">
                                                                        Editar
                                                                    </span>
                                                                    { /*   entorno local
                                                                        <a href="/pendientes">
                                                                        <img src={img_edit} alt="edit" id="img_edit-calendar" />
                                                                    </a>
                                                                    */}

                                                                    {/*  entorno web   */}
                                                                    <a href="#/pendientes">
                                                                        <img src={img_edit} alt="edit" id="img_edit-calendar" />
                                                                    </a>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="div-notif-px">
                                                            <p style={{ border: "none" }}>
                                                                <textarea id="textArea-pendientes" disabled rows="4" cols="50" onChange={(event) => handleComentario(index, event.target.value)} defaultValue={pendiente.comentario}></textarea>
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {usuario.rol === "pax" ? (
                                <div className="calendario-datos">
                                    <div className="calend">
                                        {eventos !== undefined && eventos.length > 0 ? (
                                            <FullCalendar
                                                headerToolbar={{
                                                    left: 'prev,next today',
                                                    center: 'title',
                                                    right: 'listDay, dayGridMonth,timeGridWeek,multiMonthYear' // Agrega las vistas aquí
                                                }}
                                                buttonText={{
                                                    day: 'Dia',
                                                    today: 'Hoy', // Cambia el texto del botón "Hoy"
                                                    month: 'Mes', // Cambia el texto del botón "Mes"
                                                    week: 'Semana', // Cambia el texto del botón "Semana"
                                                    year: 'Año' // Cambia el texto del botón "Día"
                                                }}
                                                locales={[esLocale]}
                                                slotLabelFormat={{
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hourCycle: "h12",
                                                    meridiem: 'short',
                                                }}
                                                eventTimeFormat={{
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hourCycle: "h12",
                                                }}
                                                nowIndicator={true}
                                                slotMinTime="07:00:00"
                                                slotMaxTime="22:00:00"
                                                allDaySlot={false}
                                                themeSystem="pulse"
                                                plugins={[listPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin, multiMonthPlugin]}
                                                initialView="timeGridWeek"
                                                events={eventos}
                                                dateClick={agendarCitaPX}
                                                eventClick={informacionPacientePX}
                                                eventDrop={moverCita}
                                            />
                                        ) : (
                                            <FullCalendar
                                                headerToolbar={{
                                                    left: 'prev,next today',
                                                    center: 'title',
                                                    right: 'listDay, dayGridMonth,timeGridWeek,multiMonthYear' // Agrega las vistas aquí
                                                }}
                                                buttonText={{
                                                    day: 'Dia',
                                                    today: 'Hoy', // Cambia el texto del botón "Hoy"
                                                    month: 'Mes', // Cambia el texto del botón "Mes"
                                                    week: 'Semana', // Cambia el texto del botón "Semana"
                                                    year: 'Año' // Cambia el texto del botón "Día"
                                                }}
                                                locales={[esLocale]}
                                                slotLabelFormat={{
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hourCycle: "h12",
                                                    meridiem: 'short',
                                                }}
                                                eventTimeFormat={{
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hourCycle: "h12",
                                                }}
                                                nowIndicator={true}
                                                slotMinTime="07:00:00"
                                                slotMaxTime="22:00:00"
                                                allDaySlot={false}
                                                themeSystem="pulse"
                                                plugins={[listPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin, multiMonthPlugin]}
                                                initialView="timeGridWeek"
                                                dateClick={agendarCitaPX}
                                                eventClick={informacionPacientePX}
                                            />
                                        )}
                                    </div>
                                    <div className="datos-paciente">
                                        <div className="edit_delete-pendientes">
                                            <div id="px_h3_pax">
                                                <label>Recomendaciones</label>
                                            </div>
                                        </div>
                                        <div className="div-pendientes-textArea">
                                            <div>
                                                {
                                                    recomendacion.length === 0 ? (
                                                        <div className="div-pendientes-edit-delete">
                                                            <label>No existen recomendaciones añadidas</label>
                                                        </div>
                                                    ) : (
                                                        recomendacion.map((recomendacion, index) => (
                                                            <div className="div-pendientes-box">
                                                                <div className="div-pendientes-edit-delete">
                                                                    <div key={index} id="id-hora-fecha-pendientes">
                                                                        <label> Registrada el día: {moment(recomendacion.fecha).format('DD-MM-YYYY')} </label>
                                                                    </div>
                                                                </div>
                                                                <div className="div-notif-px">
                                                                    <p style={{ border: "none" }}>
                                                                        <textarea id="textArea-pendientes" disabled rows="4" cols="50" defaultValue={recomendacion.recomendacion}></textarea>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ))
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {usuario.rol === 'adm' ? (
                                        <div className="calendario-datos">

                                            <h1>Agenda...</h1>

                                            <div className="datos-paciente">
                                                <div className="edit_delete-pendientes">
                                                    <div id="px_h3">
                                                        <h3>Pendientes del sistema</h3>
                                                    </div>
                                                </div>
                                                <div className="div-pendientes-textArea">

                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </Content>
        </Layout >
    );
};

export default Calendario;
