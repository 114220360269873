
// FormularioEditarCitaPX.js  ---- Paciente

import React from "react";
import "../../styles/FormularioEditarCita.css";

const FormularioEditarCitaPX = ({ psicologo, nom_px, fechaF, horaI, horaF, comentario, modalidad }) => {
  return (
    <div className="div-editCita-modal-px">
      <div className="div-tableModal-px">
        <table className="tabla-editCita-modal-px">
          <tbody>
            <tr className="tr-edit-lineBottom-px">
              <td className="td-editCita-izq-px">
                <label className="lbl-psiEditar-px"> Psicologo: </label>
              </td>
              <td className="td-editCita-der-px">
                <label>{psicologo.nombre} {psicologo.ap1} {psicologo.ap2}</label>
              </td>
            </tr>
            <tr className="tr-edit-lineBottom-px">
              <td className="td-editCita-izq-px">
                <label className="lbl-psiEditar-px"> Paciente: </label>
              </td>
              <td className="td-editCita-der-px">
                <label>{ nom_px }</label>
              </td>
            </tr>
            <tr className="tr-edit-lineBottom-px">
              <td className="td-editCita-izq-px">
                <label> Fecha: </label>
              </td>
              <td className="td-editCita-der-px">
                <input className="lbl-editLbl-px" type="date" id="input-editFecha-px" defaultValue={fechaF} />
              </td>
            </tr>
            <tr className="tr-edit-lineBottom-px">
              <td className="td-editCita-izq-px">
                <label> Hora Inicio: </label>
              </td>
              <td className="td-editCita-der-px">
                <input type="time" id="input-editHoraI-px" defaultValue={horaI} />
              </td>
            </tr>
            <tr className="tr-edit-lineBottom-px">
              <td className="td-editCita-izq-px">
                <label> Hora Final: </label>
              </td>
              <td className="td-editCita-der-px">
                <input type="time" id="input-editHoraF-px" defaultValue={horaF} />
              </td>
            </tr>
            <tr>
              <td className="td-editCita-izq-px">
                <label> Modalidad: </label>
              </td>
              <td className="td-editCita-der-px">
                <select className="selected-pax" id="select-editModalidad-px" defaultValue={modalidad}>
                  <option className="selected-option" value="elegir" disabled> Selecciona una opción </option>
                  <option className="selected-option" value="presencial">Presencial</option>
                  <option className="selected-option" value="videollamada">Videollamada</option>
                  <option className="selected-option" value="telefono">Teléfono</option>
                  <option className="selected-option" value="mensajes">Mensajes: voz, texto</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="div-editComentario-px">
        <div className="div-editComentario2-px">
          <label> Comentario: </label>
        </div>
        <div className="div-editComentario3-px">
          <textarea id="input-editComentario-px" placeholder="Puedes dejar un comentario..." defaultValue={comentario}></textarea>
        </div>
      </div>
    </div>
  );
};

export default FormularioEditarCitaPX;
