
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';
import moment from 'moment';

import PacientesService from "../../services/PacientesService";
import CitasService from "../../services/CitasService";
import InformacionPacientes from "../../services/InformacionPacientes";

import "../../styles/Citas.css";
import "../../styles/MenuHamburguesa.css";

import img_search from "../../img/search_pax.svg";
import img_clear from "../../img/clear_all.svg";



const Citas = (id) => {

    const [pacientes, setPacientes] = useState([]);
    const [informacion, setInformacion] = useState([]);
    const [citas, setCitas] = useState([]);
    const [selectedValue, setSelectedValue] = useState(""); // Estado para el valor del select
    const [inputValue, setInputValue] = useState("");
    const [fotografia, setFotografia] = useState('');
    const [sinFoto, setSinFoto] = useState('');

    const [edoCivil, setEdoCivil] = useState('');

    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);
    const routeParams = useParams();

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    });

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }

    const navigate = useNavigate();

    const getData = async () => {
        if (routeParams.id !== undefined) {
            cargarInformacion(routeParams.id);
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        } else {
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        }
    };

    useEffect(() => {
        getData();
    }, []);


    // Cargar info de Citas
    const cargarInformacion = async (id_paciente) => {
        const data2 = await CitasService.getCitasHistorial(id_paciente);
        if (data2.length === 0) {
            Swal.fire({
                title: "Citas del paciente",
                text: "El paciente no tiene citas registradas. ¿Deseas agendar una cita?",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: "Agendar",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(`/calendario`);    // web
                }
            });
        } else {
            for (let i = 0; i < data2.length; i++) {
                data2[i].numeroCita = (data2.length + 1) - (i + 1);
            }
            setCitas(data2);
            cargarInformacionPx(id_paciente);
        }
    };


    const DisplayData = pacientes.map((info, index) => {
        return (
            <tr key={index}>
                <td className="td-border-button">
                    <button onClick={() => cargarInformacion(info.id_paciente)} id="liga_px">
                        {info.nombre} {info.ap1} {info.ap2}
                    </button>
                </td>
            </tr>
        )
    });

    const cargarInformacionPx = async (id_paciente) => {

        const data = await PacientesService.getInfoPaciente(id_paciente);

        setEdoCivil(InformacionPacientes.estadoCivil(data[0].edo_civil));

        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64

        if (cont.length < 1) {
            setSinFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setSinFoto("1");
        }

        setFotografia(data[0].fotografia.data)  // get base64 de DB
        setInformacion(data[0]);

    };



    const DisplayCitas = citas.map((cita, index) => {
        return (
            <div key={index}>
                <table id="table_datos_cita">
                    <tbody>
                        <tr id="filas_datos_citas">
                            <td>{cita.numeroCita}</td>
                            <td>{cita.hora_i} - {cita.hora_f}</td>
                            <td>{moment(cita.fecha).format('DD-MM-YYYY')}</td>
                            <td>{cita.modalidad}</td>
                            <td>{cita.estatus_cita}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="div-citas-comen">
                    <div className="div-citas2-comen">
                        <label>Comentarios </label>
                    </div>
                    <div className="div-citas3-comen">
                        <div className="div-citas4-comen">
                            <div className="div-citas4-1-comen">
                                <label>Paciente: </label>
                            </div>
                            <div className="div-citas4-2-comen">
                                <textarea value={cita.comentario_px.replace(/\[\[SALTO\]\]/g, '\n')}></textarea>
                            </div>
                        </div>
                        <div className="div-citas5-comen">
                            <div className="div-citas5-1-comen">
                                <label>Psicólogo: </label>
                            </div>
                            <div className="div-citas5-2-comen">
                                <textarea value={cita.comentario_psi.replace(/\[\[SALTO\]\]/g, '\n')}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <hr></hr>
            </div>
        )
    });


    // Buscador de pacientes
    const buscar = async (event) => {
        setInputValue(event.target.value);
        const data = await PacientesService.getPacientesBuscador(event.target.value, usuario.id_psicologo);
        setPacientes(data);
    }

    const buscarFiltro = async (event) => {
        if (event.target.value === "") {
            const data = await PacientesService.getPacientes(usuario.id_psicologo);
            setPacientes(data);
        } else {
            setSelectedValue(event.target.value);
            const data = await PacientesService.getPacientesEstatus(event.target.value, usuario.id_psicologo);
            setPacientes(data);
        }
    };

    const limpiarFiltro = async () => {
        setInputValue("");
        setSelectedValue("");
        const data = await PacientesService.getPacientes(usuario.id_psicologo);
        setPacientes(data);
    };

    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }


    return (
        <Layout>
            <Content>
                <div className="div-1-citas">
                    <div className="px_div_2_citas">
                        <div className="px_sesion_citas">
                            <label id="lbl-enSesion">{tipoUsuario()} &nbsp; </label>
                            <label id="lbl-enSesion_psic">{usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_citas">
                            <label>Historial de citas</label>
                        </div>
                    </div>
                    <div className="px_buscador_citas">
                        <div className="px_filtro_cita">
                            <select id="px_select_cita" value={selectedValue} onChange={buscarFiltro}>
                                <option value="" disabled >Selecciona un filtro</option>
                                <option value="A">Pacientes activos</option>
                                <option value="I">Pacientes inactivos</option>
                            </select>
                            <button id="btn-limpiar-filtro" onClick={limpiarFiltro}>
                                <img src={img_clear} alt="img_clear" />
                                Limpiar
                            </button>
                        </div>
                        <div className="input_buscar_cita">
                            <input type="search" placeholder="Buscar paciente..." id="Buscador" value={inputValue} onChange={buscar} />
                            <img src={img_search} alt="buscar" id="img_lupa"></img>
                        </div>
                    </div>
                    <div className="px_labelPx_citas">
                        <label>Pacientes</label>
                    </div>
                    <div className="px_div_3_citas">
                        <div className="div-displayPx-cita">
                            <table id="table_filas_px-citas">
                                <tbody>
                                    {DisplayData}
                                </tbody>
                            </table>
                        </div>
                        <div className="div-3-citas-menu">
                            {citas.length === 0 ? (
                                <div className="faq-container">
                                    <details open>
                                        <summary> Historial de citas </summary>
                                    </details>
                                    <div className="div-label-selec-cita">
                                        <p> Selecciona un paciente de la lista </p>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="div-p-nombre-cita">
                                        <div className="div-avatar-Cita">
                                            {
                                                sinFoto === "1" ? (
                                                    fotografia === "" ? (
                                                        <div id="img_avatar_Exp">
                                                            <label> Cargando imagen...</label>
                                                        </div>
                                                    ) : (
                                                        <div id="img_avatar_Exp">
                                                            <img src={fotografia} alt="base64"></img>
                                                        </div>
                                                    )
                                                ) : (<div></div>)
                                            }
                                        </div>
                                        <div className="div-table-head-cita">
                                            <table className="table-head-px-cita">
                                                <tbody>
                                                    <tr>
                                                        <td id="td-paciente-citas" colSpan={4}>
                                                            {/*    local
                                                        <a href={`/pacientes/${informacion.id_paciente}`}>
                                                            <label>{informacion.nombre} {informacion.ap1} {informacion.ap2}</label>
                                                        </a>
                                                        */}

                                                            {/*  web   */}
                                                            <a href={`#/pacientes/${informacion.id_paciente}`}>
                                                                <label>{informacion.nombre} {informacion.ap1} {informacion.ap2}</label>
                                                            </a>
                                                        </td>
                                                        <td id="td-menuHamb-citas">
                                                            <div id="div-menuHamb">
                                                                <nav className="nav-menuHamb">
                                                                    <input type="checkbox" id="in-menuHamb"></input>
                                                                    <label id="label-menuHamb" htmlFor="in-menuHamb"> ☰ </label>
                                                                    <ul id="ul-menuHamb">
                                                                        {/*     entorno local
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/sesiones/${informacion.id_paciente}`}>Sesiones</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/expedientes/${informacion.id_paciente}`}>Expediente</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/familiares/${informacion.id_paciente}`}>Familiares</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/canalizaciones/${informacion.id_paciente}`}>Canalizaciones</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`/notificaciones/${informacion.id_paciente}`}>Notificaciones</a>
                                                                        </li>
                                                                        */}

                                                                        {/*     entorno web     */}
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`#/sesiones/${informacion.id_paciente}`}>Sesiones</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`#/expedientes/${informacion.id_paciente}`}>Expediente</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`#/familiares/${informacion.id_paciente}`}>Familiares</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`#/canalizaciones/${informacion.id_paciente}`}>Canalizaciones</a>
                                                                        </li>
                                                                        <li id="li-menuHamb-admin">
                                                                            <a href={`#/notificaciones/${informacion.id_paciente}`}>Notificaciones</a>
                                                                        </li>
                                                                    </ul>
                                                                </nav>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr id="encabezado_px_citas">
                                                        <th>Edad</th>
                                                        <th>Trabajo actual</th>
                                                        <th>Edo. civil</th>
                                                        <th>Ciudad</th>
                                                        <th>Tel</th>
                                                    </tr>
                                                    <tr id="filas_px_citas">
                                                        <td>
                                                            <label id="lbl-edad-citas">{informacion.edad}</label>
                                                        </td>
                                                        <td>
                                                            <label id="lbl-trabajo-citas">{informacion.trabajo_actual}</label>
                                                        </td>
                                                        <td>
                                                            <label id="lbl-edo-civil-citas">{edoCivil}</label>
                                                        </td>
                                                        <td>
                                                            <label id="lbl-ciudad-citas">{informacion.ciudad}</label>
                                                        </td>
                                                        <td>
                                                            <label id="lbl-tel-citas">{informacion.tel}</label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="div_table_display_citas">
                                        <div className="div_tableEncabezado_citas">
                                            <table id="table_head_datos_cita">
                                                <thead>
                                                    <tr id="encabezado_datos_citas">
                                                        <th>No. cita</th>
                                                        <th>Hora</th>
                                                        <th>Fecha</th>
                                                        <th>Modalidad</th>
                                                        <th>Estatus</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div className="div_tableFilas_citas">
                                            {DisplayCitas}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Content>
        </Layout>
    );
};

export default Citas;