
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';

import GlosarioService from "../../services/GlosarioService";

import "../../styles/Sesiones.css";
import "../../styles/Glosario.css";

import img_editar from "../../img/edit_note.svg";
import img_eliminar from "../../img/delete.svg";
import img_search from "../../img/search_pax.svg";



const Glosario = (id) => {

    console.log("---- Glosario -----")


    const [glosario, setGlosario] = useState([]);
    const [concepto, setConcepto] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [inputValue, setInputValue] = useState("");


    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);
    const routeParams = useParams();

    function refreshPage() {
        window.location.reload(false);
    }


    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    });

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }

    function mostrarError(mensaje) {
        Swal.fire({
            title: '¡Error!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    const navigate = useNavigate();

    const getData = async () => {
        const data2 = await GlosarioService.getConceptos();
        setGlosario(data2);
    };


    useEffect(() => {
        getData();
    }, []);


    const DisplayGlosario = glosario.map((info, index) => {
        return (
            <div id="div-btn-concepto" key={index}>
                <button id="btn-concepto" onClick={() => mostrarDescripcion(info.id_glosario)}>
                    {info.concepto}
                </button>
            </div>
        );
    });


    async function mostrarDescripcion(id_glosario) {
        const data = await GlosarioService.getDescripcionIndividual(id_glosario);
        setDescripcion(data[0]);
    }


    const editarConcepto = (id) => {
        navigate(`/GlosarioEditar/${id}`)
    }


    const DisplayDescripcion = () => {
        return (
            <div className="div-DisplayDescripcion">
                <div className="div-btn-descripcion">
                    <button onClick={() => editarConcepto(descripcion.id_glosario)}>
                        <img src={img_editar} alt="editar" id="img_editar"></img>
                    </button>
                    <button onClick={() => eliminarConcepto(descripcion.id_glosario)}>
                        <img src={img_eliminar} alt="eliminar" id="img_eliminar"></img>
                    </button>
                </div>
                <div className="div-descripcion-concepto">
                    <label>
                        {descripcion.concepto}
                    </label>
                </div>
                <div className="div-descripcion-descripcion">
                    <label>
                        {descripcion.descripcion}
                    </label>
                </div>
                <div className="div-descripcion-bibliografia">
                    <label>
                        {descripcion.bibliografia}
                    </label>
                </div>
            </div>
        );
    }

    const nuevoConcepto = () => {
        navigate("/GlosarioAdd");
    }


    const eliminarConcepto = async (id) => {
        const response = await GlosarioService.eliminarConcepto(id);
        console.log(response)
        if (response.status === 200) {
            Toast.fire("¡El concepto se eliminó correctamente!", "", "success")
                .then((result) => {
                    if (result.isConfirmed) {
                        refreshPage();
                    }
                }).then(function () {
                    refreshPage();
                });
        }
    }


    // Buscador de conceptos
    const buscarConcepto = async (event) => {
        setInputValue(event.target.value);
        const concepto = await GlosarioService.buscarConcepto(event.target.value);
        setGlosario(concepto.data);
    }



    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }
        return tipoUser;
    }


    return (
        <Layout>
            <Content>
                <div className="div-1-glosario">
                    <div className="div-2-glosario">
                        <div className="px_glosario_citas">
                            <label id="lbl-glosario">{tipoUsuario()} &nbsp;</label>
                            <label>{usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_citas">
                            <label>Glosario</label>
                        </div>
                    </div>
                    <div className="input_nuevo_glosario">
                        <button onClick={nuevoConcepto}>
                            Nuevo concepto
                        </button>
                    </div>
                    <div className="div_buscador_px_glosario">
                        <div className="input_buscar_glosario">
                            <input type="search" placeholder="Buscar concepto..." id="Buscador" value={inputValue} onChange={buscarConcepto} />
                            <img src={img_search} alt="buscar" id="img_lupa"></img>
                        </div>
                    </div>
                    <div className="div-concepto-descripcion">
                        <div className="div-headConcepto">
                            <label>Concepto</label>
                        </div>
                        <div className="div-headDescripcion">
                            <label>Descripción</label>
                        </div>
                    </div>
                    <div className="div-3-glosario">
                        <div className="div3_Concepto_glosario">
                            {DisplayGlosario}
                        </div>
                        <div className="div3_Descripcion_glosario">
                            <DisplayDescripcion />
                        </div>
                    </div>
                </div>
            </Content>
        </Layout >
    );
};

export default Glosario;