

import axios from "axios";

const INGRESO_API_BASE_URL = "/server/ingreso";
const INGRESO_DELETE_API_BASE_URL = "server/ingresoDelete";
const VINGRESO_API_BASE_URL = "/server/vingreso";

// const INGRESO_API_BASE_URL = "http://localhost:3002/ingreso";
// const INGRESO_DELETE_API_BASE_URL = "http://localhost:3002/ingresoDelete";
// const VINGRESO_API_BASE_URL = "http://localhost:3002/vingreso";


class IngresoService {

    async getIngresoUsuario(fk_ingreso) {
        const response = await axios.get(`${VINGRESO_API_BASE_URL}IndividualUsuario?fk_ingreso=${fk_ingreso}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('IS getIngresoUsuario: ', error);
            });
        const ingreso = response.data;
        return ingreso;
    }


    // vista ingreso colega
    async getIngresoColega(fk_ingreso) {
        const response = await axios.get(`${VINGRESO_API_BASE_URL}IndividualColega?fk_ingreso=${fk_ingreso}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('IS getIngresoColega: ', error);
            });
        const ingreso = response.data;
        return ingreso;
    }


    async getIngresoIndividual(id_usuario) {
        const response = await axios.get(`${VINGRESO_API_BASE_URL}Individual?id_usuario=${id_usuario}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('IS getIngresoIndividual: ', error);
            });
        const ingreso = response.data;
        return ingreso;
    }


    async createIngreso(data) {

        return await axios.post(INGRESO_API_BASE_URL, data);

    }


    async editIngreso(data) {

        return await axios.put(INGRESO_API_BASE_URL, data);

    }


    async deleteIngreso(id_ingreso) {

        return await axios.delete(`${INGRESO_DELETE_API_BASE_URL}?id_ingreso=${id_ingreso}`);

    }

}


export default new IngresoService();



