
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';

import GlosarioService from "../../services/GlosarioService";

import "../../styles/Sesiones.css";
import "../../styles/Glosario.css";
import "../../styles/GlosarioAdd.css";



const GlosarioEditar = (id) => {

    console.log("----- Glosario Editar -----")


    const [concepto, setConcepto] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [biblos, setBiblos] = useState("");
    const [idGlosario, setIdGlosario] = useState();

    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);

    const routeParams = useParams();

    const navigate = useNavigate();

    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    });

    function mostrarError(mensaje) {
        Swal.fire({
            title: '¡Error!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    const getData = async () => {
        if (routeParams !== undefined) {
            glosarioInfo(routeParams.id)
        }
    }


    useEffect(() => {
        getData();
    }, []);


    const glosarioInfo = async (id) => {
        const data = await GlosarioService.getDescripcionIndividual(id);
        console.log(data[0])

        setConcepto(data[0].concepto);
        setDescripcion(data[0].descripcion);
        setBiblos(data[0].bibliografia);
        setIdGlosario(data[0].id_glosario);
    }


    const handleConcepto = (event) => {
        setConcepto(event.target.value);
    }

    const handleDescripcion = (event) => {
        setDescripcion(event.target.value);
    }

    const handleBibliografia = (event) => {
        setBiblos(event.target.value);
    }

    const handleSalir = () => {
        navigate('/glosario');
    }


    const handleSubmit = async () => {
        if (concepto === "" && descripcion === "") {
            mostrarError("Los campos Concepto y Descripción no pueden estar vacíos.");
        } else if (concepto === "" || descripcion === "") {
            mostrarError("Los campos Concepto o Descripción no pueden estar vacíos.");
        } else if (concepto !== "" && descripcion !== "") {

            const data = {
                concepto: concepto,
                descripcion: descripcion,
                bibliografia: biblos,
                id_glosario: idGlosario
            }

            const response = await GlosarioService.editarConceptos(data);

            if (response.affectedRows === 1) {
                Toast.fire("¡El concepto se registró correctamente!", "", "success")
                    .then((result) => {
                        if (result.isConfirmed) {
                            navigate('/glosario');  //web
                        }
                    }).then(function () {
                        navigate('/glosario');  //web
                    });
            }
        }
    }


    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }

    return (
        <Layout>
            <Content>
                <div className="div1-glosarioAdd">
                    <div className="div2-glosarioAdd">
                        <label id="lbl-glosario">{tipoUsuario()} &nbsp;</label>
                        <label>{usuario.nombre}</label>
                    </div>
                    <div className="div3-glosarioAdd">
                        <label> Editar concepto </label>
                    </div>
                    <div className="div4-glosarioAdd">
                        <div className="div5-glosarioAdd">
                            <div id="div-txtConcepto-1">
                                <label> Concepto: </label>
                            </div>
                            <div id="div-txtConcepto-2">
                                <input value={concepto} onChange={handleConcepto} />
                            </div>
                        </div>
                        <div className="div6-glosarioAdd">
                            <div id="div-txtDescrip-1">
                                <label> Descripción: </label>
                            </div>
                            <div id="div-txtDescrip-2">
                                <textarea value={descripcion} onChange={handleDescripcion} rows="10" cols="50" />
                            </div>
                        </div>
                        <div className="div7-glosarioAdd">
                            <div id="div-txtBiblo-1">
                                <label> Bibliografía: </label>
                            </div>
                            <div id="div-txtBiblo-2">
                                <input value={biblos} onChange={handleBibliografia} />
                            </div>
                        </div>
                        <div className="div8-glosarioAdd">
                            <button id="btn-guardar" onClick={handleSubmit}>
                                Guardar
                            </button>
                            <button id="btn-salir" onClick={handleSalir}>
                                Salir
                            </button>
                        </div>
                    </div>
                </div>
            </Content>
        </Layout>
    )
}


export default GlosarioEditar;

