

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Content from "../../components/Content";
import Layout from "../../containers/Layout";

import Swal from 'sweetalert2';
import moment from 'moment';

import PacientesService from "../../services/PacientesService";
import ExpedientesService from "../../services/ExpedientesService";
import InformacionPacientes from "../../services/InformacionPacientes";
import SesionesService from "../../services/SesionesService";

import "../../styles/FormularioAddFamiliar.css";
import "../../styles/Recomendacion.css";
import "../../styles/RecomendacionAdd.css";
import "../../styles/FormularioEditarRecomendacion.css";
import "../../styles/RedactarSesion.css";



const ConsultarExpediente = ({ data }) => {


    console.log("---- ConsultarSesion -----")


    const [informacion, setInformacion] = useState([]);
    const [formatoFoto, setFormatoFoto] = useState('');
    const [image, setImage] = useState('');   // obtiene la img base64String del btn Elegir Archivo
    const [imageData, setImageData] = useState('');   // bandera, si la img es cargada para actualizar o viene de la DB
    const [foto, setFoto] = useState('');  // bandera, hay o no foto en la DB
    const [fotografia, setFotografia] = useState('');
    const [sinFoto, setSinFoto] = useState('');

    const [pruebas, setPruebas] = useState("");
    const [diagnostico, setDiagnostico] = useState("");
    const [historial, setHistorial] = useState("");
    const [sugerencias, setSugerencias] = useState("");
    const [experiencias, setExperiencias] = useState("");
    const [familiograma, setFamiliograma] = useState("");

    const [idExpediente, setIdExpediente] = useState('');

    const [txtArea, setTxtArea] = useState('');
    const [titulo, setTitulo] = useState("");
    const [infoExpediente, setInfoExpediente] = useState("");

    const [sexo, setSexo] = useState('');
    const [edoCivil, setEdoCivil] = useState('');

    const [verTexto, setVerTexto] = useState(false);


    const navigate = useNavigate();

    const usuarioString = sessionStorage.getItem('usuario');
    const usuario = JSON.parse(usuarioString);
    const routeParams = useParams();


    const Toast = Swal.mixin({
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        confirmButtonText: "Aceptar"
    })

    function mostrarAdvertencia(mensaje) {
        Swal.fire({
            title: '¡Advertencia del Sistema!',
            text: mensaje,
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
    }

    function mostrarError(mensaje) {
        Swal.fire({
            title: '¡Error!',
            text: mensaje,
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
    }

    function refreshPage() {
        window.location.reload(false);
    }


    const getData = async () => {

        if (routeParams !== undefined) {

            cargarInformacion(routeParams.id, routeParams.txt);

        } else {

            alert("Error al obtner parámetro ID.");

        }

    }

    useEffect(() => {

        getData();

    }, [])


    const cargarInformacion = async (id_paciente, infoExp) => {

        const data = await PacientesService.getInfoPaciente(id_paciente);

        setSexo(InformacionPacientes.sexoPaciente(data[0].sexo));
        setEdoCivil(InformacionPacientes.estadoCivil(data[0].edo_civil));

        const cont = data[0].fotografia.data;   // 40 cuando no recibe cadena convertida, revisar base64

        if (cont.length < 1) {
            setSinFoto("0");
        } else if (cont.length > 1 && cont.length < 50) {
            mostrarAdvertencia("Error al obtener fotografía");
        } else if (cont.length > 50) {
            setSinFoto("1");
        }

        setFotografia(data[0].fotografia.data)  // get base64 de DB
        setInformacion(data[0]);

        const data3 = await ExpedientesService.getExpediente(id_paciente);
        setIdExpediente(data3[0].id_expediente);
        setPruebas(data3[0].pruebas);
        setDiagnostico(data3[0].diagnostico);
        setHistorial(data3[0].hist_medico);
        setSugerencias(data3[0].sugerencias);
        setExperiencias(data3[0].exp_significativa);
        setIdExpediente(data3[0].id_expediente);
        setFamiliograma(data3[0].familiograma);

        if (infoExp === "pruebas") {
            setTitulo("Pruebas aplicadas");
            setInfoExpediente(data3[0].pruebas);
        } else if (infoExp === "sugerencias") {
            setTitulo("Sugerencias");
            setInfoExpediente(data3[0].sugerencias);
        } else if (infoExp === "historial") {
            setTitulo("Historial médico");
            setInfoExpediente(data3[0].hist_medico);
        } else if (infoExp === "exp_sig") {
            setTitulo("Experiencias significativas");
            setInfoExpediente(data3[0].exp_significativa);
        } else if (infoExp === "familiograma") {
            setTitulo("Familiograma");
            setInfoExpediente(data3[0].familiograma);
        } else if (infoExp === "diagnostico") {
            setTitulo("Diagnóstico");
            setInfoExpediente(data3[0].diagnostico);
        }

    }


    const verMasTexto = () => {
        setVerTexto(true);
    }


    const verMenosTexto = () => {
        setVerTexto(false);
    }

    const handleTxtArea = (event) => {
        console.log(event.target.value)
        setTxtArea(event.target.value);
    }


    const handleGuardar = async () => {

        let data = {
            pruebas: pruebas,
            sugerencias: sugerencias,
            hist_medico: historial,
            exp_significativa: experiencias,
            familiograma: familiograma,
            diagnostico: diagnostico,
            id_expediente: idExpediente
        }

        if (routeParams.txt === "pruebas") {
            data.pruebas = txtArea;
        } else if (routeParams.txt === "sugerencias") {
            data.sugerencias = txtArea;
        } else if (routeParams.txt === "historial") {
            data.hist_medico = txtArea;
        } else if (routeParams.txt === "exp_sig") {
            data.exp_significativa = txtArea;
        } else if (routeParams.txt === "familiograma") {
            data.familiograma = txtArea;
        } else if (routeParams.txt === "diagnostico") {
            data.diagnostico = txtArea;
        }

        try {

            const response = await ExpedientesService.editarExpediente(data);
            console.log("response estatus:", response.status)

            if (response.status === 200) {
                Toast.fire("¡La información se registró correctamente!", "", "success")
                    .then((result) => {
                        if (result.isConfirmed) {
                            refreshPage();  //web
                        }
                    }).then(function () {
                        refreshPage();  //web
                    });
            } else {
                console.log(`Error en ${titulo}, al guardar la información.`)
            }

        } catch (err) {
            mostrarError(`Error en ${titulo}, al guardar la información: ${err}`);
        }

    }

    const handleSalir = () => {
        navigate("/expedientes");
    }

    const tipoUsuario = () => {
        let tipoUser = '';
        if (usuario.rol === 'adm') {
            tipoUser = "Administrador: "
        } else if (usuario.rol === 'psi') {
            tipoUser = "Psicólogo: "
        } else {
            tipoUser = "Paciente: "
        }

        return tipoUser;
    }


    return (
        <Layout>
            <Content>
                <div className="div-1-RedSesion">
                    <div className="div-2-RedSesion">
                        <div className="px_sesion_addRec">
                            <label id="lbl-enSesion">{tipoUsuario()} &nbsp; </label>
                            <label>{usuario.nombre}</label>
                        </div>
                        <div className="div_lbl_addRecom">
                            <label>{titulo}</label>
                        </div>
                    </div>
                    <div className="div-datos-RedSesion">
                        <div id="div-image-RedSesion">
                            <div className="div-avatar-addRec">
                                {
                                    sinFoto === "1" ? (
                                        fotografia === "" ? (
                                            <div id="img_avatar_Exp">
                                                <label> Cargando imagen...</label>
                                            </div>
                                        ) : (
                                            <div id="img_avatar_Exp">
                                                <img src={fotografia} alt="base64"></img>
                                            </div>
                                        )
                                    ) : (<div></div>)
                                }
                            </div>
                        </div>
                        <table className="table-head-px-sesion">
                            <tr>
                                <td id="td-paciente-citas" colSpan={4}>
                                    <a href={`#/pacientes/${informacion.id_paciente}`}>
                                        <label>{informacion.nombre} {informacion.ap1} {informacion.ap2}</label>
                                    </a>

                                </td>
                                <td id="td-menuHamb-citas">
                                    <div id="div-menuHamb">
                                        <nav className="nav-menuHamb">
                                            <input type="checkbox" id="in-menuHamb"></input>
                                            <label id="label-menuHamb" for="in-menuHamb"> ☰ </label>
                                            <ul id="ul-menuHamb">
                                                <li id="li-menuHamb-admin">
                                                    <a href={`#/sesiones/${informacion.id_paciente}`}>Sesiones</a>
                                                </li>
                                                <li id="li-menuHamb-admin">
                                                    <a href={`#/expedientes/${informacion.id_paciente}`}>Expediente</a>
                                                </li>
                                                <li id="li-menuHamb-admin">
                                                    <a href={`#/familiares/${informacion.id_paciente}`}>Familiares</a>
                                                </li>
                                                <li id="li-menuHamb-admin">
                                                    <a href={`#/canalizaciones/${informacion.id_paciente}`}>Canalizaciones</a>
                                                </li>
                                                <li id="li-menuHamb-admin">
                                                    <a href={`#/notificaciones/${informacion.id_paciente}`}>Notificaciones</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </td>
                            </tr>
                            <tr id="encabezado_px_citas">
                                <th>Edad</th>
                                <th>Trabajo actual</th>
                                <th>Edo. civil</th>
                                <th>Ciudad</th>
                                <th>Tel</th>
                            </tr>
                            <tr id="filas_px_citas">
                                <td>
                                    <label id="lbl-edad-citas">{informacion.edad}</label>
                                </td>
                                <td>
                                    <label id="lbl-trabajo-citas">{informacion.trabajo_actual}</label>
                                </td>
                                <td>
                                    <label id="lbl-edo-civil-citas">{edoCivil}</label>
                                </td>
                                <td>
                                    <label id="lbl-ciudad-citas">{informacion.ciudad}</label>
                                </td>
                                <td>
                                    <label id="lbl-tel-citas">{informacion.tel}</label>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div className="div-3-RedSesion-consultar">
                        <div className="div-RedSesion-txtA-consultar">
                            <textarea
                                id="RedSesion_txtA"
                                placeholder="No se ha completado este campo."
                                defaultValue={infoExpediente.replace(/\[\[SALTO\]\]/g, '\n')}
                                onChange={handleTxtArea}
                                rows="4"
                                cols="50"
                            />
                        </div>
                    </div>

                    <div className="div-autoregistro-botones-RedSesion">
                        <div className="div-RedSesion-cancelar">
                            <button value="cancelar" id="btn-cancelar-addPsi" onClick={handleSalir}> Salir </button>
                        </div>
                        <div className="div-RedSesion-enviar">
                            <button value="cancelar" id="btn-cancelar-addPsi" onClick={handleGuardar}> Guardar </button>
                        </div>
                    </div>
                </div>
            </Content>
        </Layout>
    )

}


export default ConsultarExpediente;


